import { Category } from '~/types/category';

export interface ResourceImage {
  id: number;
  file_id: number;
  width: number;
  height: number;
  src: string;
  thumbnail?: string;
}
export interface ResponsiveImageSet {
  thumbnail?: ResourceImage;
  medium?: ResourceImage;
  large?: ResourceImage;
}

interface Author {
  id?: number;
  uuid?: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  display_name?: string;
  photo?: string;
}

interface Contributor {
  name: string;
  slug: string;
  excerpt: string;
  logo: string;
  logo_square: string;
}

export type FileType =
  | 'pdf'
  | 'docx'
  | 'doc'
  | 'dotx'
  | 'xls'
  | 'xlsx'
  | 'potx'
  | 'pptx'
  | 'slide'
  | 'sheet'
  | 'zip'
  | 'ai'
  | 'png';

export interface File {
  id: number;
  default: boolean;
  name: string;
  format: FileType;
  mime_type?: string;
  file_size?: number;
  downloadable?: number;
  downloadable_file_type?: 'editable' | 'not editable';
}
export interface CurriculumBreadcrumb {
  id: number;
  name: string;
  slug: string;
  link: string;
}
export interface CurriculumItem {
  id: number;
  name: string;
  slug: string;
  link: string;
  excerpt: string;
  breadcrumbs: CurriculumBreadcrumb[];
}
export interface ResourceCurriculum {
  id: number;
  name: string;
  country: string;
  code: string;
  items: CurriculumItem[];
}
export interface FileFormat {
  format: FileType;
  name: string;
}

export const isFileType = (type: FileType | FileFormat): type is FileType => {
  return typeof type === 'string';
};

export const isFileFormat = (type: FileType | FileFormat): type is FileFormat => {
  return typeof type !== 'string' && isFileType(type.format);
};

export const findFileTypeString = (type: FileType): string => {
  return fileTypeDisplayNames[type];
};

const fileTypeDisplayNames: Record<FileType, string> = {
  pdf: 'PDF',
  docx: 'Word',
  doc: 'Word',
  dotx: 'Word',
  xls: 'Excel',
  xlsx: 'Excel',
  potx: 'PowerPoint',
  pptx: 'PowerPoint',
  slide: 'Google Slides',
  sheet: 'Google Sheets',
  zip: 'ZIP',
  ai: 'Illustrator',
  png: 'PNG',
};

export interface CountryLink {
  id: number;
  link: string;
  country: string;
  name: string;
}
export interface ResourceTags {
  id: number;
  name: string;
  slug: string;
  link: string;
  type: string;
}

export interface fileFormatAlgolia {
  lvl0: string[];
}

interface Video {
  id: number;
  default: boolean;
  downloadable: number;
  file_size: number;
  format: string;
  mime_type: string;
  name: string;
}

export interface Resource {
  episode: any;
  vocab_list?: any;
  is_editable?: boolean;
  is_trending?: boolean;
  has_starter_sheet?: boolean;
  page_count?: number;
  downloads?: number;
  id: number;
  meta_title?: string;
  meta_description?: string;
  focus_keywords?: string;
  year_levels: string[];
  old_id: number;
  status: string;
  link: string;
  name: string;
  slug: string;
  type: string;
  country: string;
  file_id?: number;
  linked?: CountryLink[];
  year_levels_range: string;
  file_formats: FileType[] | FileFormat[];
  publisher: Contributor;
  curriculums: ResourceCurriculum[];
  tags: ResourceTags[];
  thumbnail: string;
  media_thumbnail: ResponsiveImageSet;
  media: ResponsiveImageSet[];
  header_image?: string;
  header_bg_image?: string;
  header_bg_color?: string;
  is_free: boolean;
  links_to_studio?: boolean;
  links_to_widget?: boolean;
  excerpt: string;
  content: string;
  shortcode_resources?: unknown[];
  author: Author;
  designer?: Author;
  word_count?: number;
  files: File[];
  videos?: Video[];
  school_fonts?: File[];
  breadcrumbs: {
    id: number;
    name: string;
    slug: string;
    count: number;
    link: string;
  }[];
  updates?: unknown[];
  bookmarks?: number;
  views?: number;
  likes?: number;
  shares?: number;
  comments?: number;
  created: string;
  modified: string;
  published?: string;
  duration?: number;
  is_generator_widget: boolean;
  local_version?: {
    content: string;
    link?: string;
    icon: string;
  };
  event_params?: Record<string, any>;
  event_params_intercom?: Record<string, any>;
  categories: Category[];
  resources?: Omit<Resource, 'resources'>[];
  resourceLength?: string;
  yearLevels?: string;
  yearLevelLabel?: string;
  customisable?: string;
  'file-format'?: fileFormatAlgolia;
  postType?: string;
  postTypeTitle?: string;
  root: {
    slug: string;
  };
  has_preview?: boolean;
  availability?: string;
  is_new?: boolean;
}

export interface AlgoliaResource {
  id: number;
  old_id: number;
  title: string;
  postType: string;
  postTypeTitle: string;
  postTypePriority: number;
  customisable: string;
  excerpt: string;
  content: string;
  publishDatetime: number;
  word_count: number;
  country: string;
  url: string;
  slug: string;
  tags: Omit<ResourceTags, 'id'>[];
  featuredImage: {
    large?: string;
    medium: string;
    small?: string;
    thumb?: string;
  };
  author: Author;
  yearLevels: string;
  yearLevelLabel: string;
  isFree: boolean;
  resourceLength: string;
  objectID: string;
  'file-format'?: {
    lvl0?: string[];
  };
  'year-level-all'?: string[];
}
export interface ResourceList {
  cached?: boolean;
  categories?: unknown[];
  original_search?: string | null;
  modified_search?: string | null;
  page: number;
  perPage: number;
  size: number;
  totalPages: number;
  list: Resource[];
}

export const isResourceList = (resource: Resource | ResourceList): resource is ResourceList => {
  return 'list' in resource;
};

export interface Lesson {
  id: number;
  name: string;
  link: string;
  duration: number;
  excerpt?: string;
}

export interface LessonPlan extends Resource {
  parent: {
    lessons: Lesson[];
    id: number;
    name: string;
    slug: string;
    link: string;
  };
  previous: Lesson;
  next: Lesson;
}

export interface ResourceContributor extends Author {
  occupation?: string;
  description?: string;
  link?: string;
}

export const isLessonPlan = (resource: Resource): resource is LessonPlan => {
  return resource.type === 'lesson-plan';
};

export interface GeneratorWidget extends Resource {
  widget_link?: string;
}

export const isGeneratorWidget = (resource: Resource): resource is GeneratorWidget => {
  return resource.is_generator_widget;
};

export interface EditableResource extends Resource {
  customise_link?: string;
}

export const isEditableResource = (resource: Resource): resource is EditableResource => {
  return !!resource.is_editable;
};
