import { computed, ComputedRef } from '@nuxtjs/composition-api';
import { useUser } from '~/composables/useUser';

interface UsePlusDownloads {
  doesUserGetPlusDownloads: ComputedRef<boolean>;
  plusDownloadsLeft: ComputedRef<number>;
}

export const usePlusDownloads = (): UsePlusDownloads => {
  const { user } = useUser();
  const doesUserGetPlusDownloads = computed((): boolean => {
    // eslint-disable-next-line camelcase
    const { quota, quota_left } = user.value?.subscription || {};
    return Number.isInteger(quota) && Number(quota) > 0 && Number.isInteger(quota_left);
  });

  const plusDownloadsLeft = computed((): number => {
    const quotaLeft = user.value?.subscription?.quota_left;
    if (quotaLeft === undefined) {
      return 0;
    }
    return quotaLeft;
  });

  return {
    doesUserGetPlusDownloads,
    plusDownloadsLeft,
  };
};
