/**
 *
 * @param {google|facebook} type
 * @param {string} redirectTo
 * @returns string
 */
export function generateOauthState(type, redirectTo) {
  const token = getRandomStateParam();
  sessionStorage.setItem('oauth_state', token);
  const params = new URLSearchParams({
    type,
    redirectTo,
    token,
  });
  return encodeURIComponent(params.toString());
}

export function getRandomStateParam() {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  let length = 16;

  while (length > 0) {
    const bytes = new Uint8Array(16);
    const random = window.crypto.getRandomValues(bytes);

    random.forEach(function (c) {
      if (length === 0) {
        return;
      }
      if (c < charset.length) {
        result += charset[c];
        length--;
      }
    });
  }
  return result;
}
