<template>
  <div
    :data-position="position"
    class="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-text-sm tw-text-gray-900"
    :class="{ dropdown: true, show: isOpen }"
  >
    <a
      v-if="url"
      ref="dropdownToggle"
      :href="url"
      :title="title"
      :class="['dropdown-toggle', type]"
      aria-haspopup="true"
      :aria-expanded="isOpen"
      :disabled="disabled"
      @click.prevent="toggleOpen"
    >
      <slot v-if="$slots.label" name="label"></slot>
    </a>
    <button
      v-else
      ref="dropdownToggle"
      :class="[
        'dropdown-toggle',
        'disabled:tw-bg-gray-200',
        'disabled:tw-cursor-not-allowed',
        type,
      ]"
      aria-haspopup="true"
      :aria-expanded="isOpen"
      :disabled="disabled"
      :aria-disabled="disabled"
      @click.prevent="toggleOpen"
    >
      <slot v-if="$slots.label" name="label"></slot>
    </button>
    <div ref="dropdownMenu" class="dropdown-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    autoClose: {
      // Set to true to close the dropdown after a selection is made eg. on the main menu
      type: Boolean,
      default: true,
    },
    disabled: {
      // Whether the button is disable or not
      type: Boolean,
      default: false,
    },
    position: {
      // Side that the dropdown is anchored to. Default: right, Options: left/center/top
      type: String,
      default: '',
    },
    type: {
      // Add classes to button eg. button-link
      type: String,
      default: 'button',
    },
    title: {
      // Title of link
      type: String,
      default: null,
    },
    url: {
      // If url is not empty, use a link instead of a button eg. for nav menu
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      windowWidth: 0,
    };
  },
  mounted() {
    if (process.browser) {
      this.windowWidth = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (!this.$refs.dropdownMenu) {
              return;
            }

            // If it goes off screen
            if (!entry.isIntersecting) {
              // If it goes off the left side of the screen
              if (entry.boundingClientRect.left < 0) {
                this.$refs.dropdownMenu.style.left = 0;
                this.$refs.dropdownMenu.style.right = 'auto';
                this.$refs.dropdownMenu.style.transform = '';
              }

              // If it goes off the right side of the screen
              if (entry.boundingClientRect.right > window.innerWidth) {
                // Allow for 3rem of padding either side
                const padding = 48;
                const percentToMove = Math.ceil(
                  ((entry.boundingClientRect.right - window.innerWidth + padding) /
                    window.innerWidth) *
                    100
                );
                this.$refs.dropdownMenu.style.transform = `translateX(-${percentToMove}%)`;
              }
            }
          });
        },
        { threshold: 1, rootMargin: '0px' }
      );
      observer.observe(this.$refs.dropdownMenu);
    }
  },
  methods: {
    toggleOpen() {
      if (!this.isOpen) {
        this.$emit('open');
      }

      const closeListener = e => {
        if (this.catchOutsideClick(e, this.$refs.dropdownToggle)) {
          window.removeEventListener('click', closeListener);
          this.isOpen = false;
        }
      };

      window.addEventListener('click', closeListener);

      this.isOpen = !this.isOpen;
    },
    catchOutsideClick(event, dropdown) {
      // When user clicks menu — do nothing
      if (dropdown === event.target) {
        return false;
      }

      // When user clicks outside of the menu — close the menu
      if (this.isOpen) {
        // Close if autoClose enabled and clicking inside the dropdown
        // Don't close if clicking inside the dropdown
        return !event.target.closest('.dropdown-content') || this.autoClose;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~/assets/scss/components/_filter.scss';
@import '~/assets/scss/components/_dropdown.scss';

@media screen and (max-height: 43.75em) {
  .dropdown-content {
    max-height: 80vh;
    overflow-y: auto;
  }
}
</style>
