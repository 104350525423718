import { Plugin } from '@nuxt/types';
import { AxiosError } from 'axios';
import { Parameters } from '~/composables/deskplates/types';
import { doesUserHaveQuota } from '~/utils/user';
import { downloadFileBlob } from '~/utils/download';
import type { CanUserDownloadResponse } from '~/types/download';

type DownloadFn = (
  fileId: number,
  customText?: string,
  pageCount?: number,
  data?: any,
  useArrayBuffer?: boolean
) => Promise<void>;
interface DownloadPlugin {
  $download: DownloadFn;
  $quickDownload: DownloadFn;
  $canUserDownloadFile: (fileId: number) => Promise<boolean>;
  $canUserDownloadFiles: (fileIds: number[]) => Promise<CanUserDownloadResponse[]>;
}

declare module '@nuxt/types' {
  interface Context extends DownloadPlugin {}
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> extends DownloadPlugin {}
}

interface DownloadOptions {
  customText?: string;
  pageCount?: number;
  data?: Partial<Parameters>;
  // The server middleware needs to know if it's a deskplate so it knows
  // to interpret the download endpoint response as a pdf instead of a link
  useArrayBuffer: boolean;
}

export const downloadPlugin: Plugin = ({ $config, $axios, store, $auth }, inject) => {
  const downloadFile = async (
    fileId: number,
    { customText, pageCount, data: downloadData, useArrayBuffer }: DownloadOptions
  ): Promise<void> => {
    try {
      const { data, headers } = await $axios.get('/fn/download', {
        baseURL: $config.baseURL,
        params: {
          id: fileId,
          custom_text: customText,
          page_count: pageCount,
          data: JSON.stringify({ ...downloadData, file: undefined }),
          useArrayBuffer,
        },
        responseType: 'arraybuffer',
      });
      // If the location is set to something other than `/`, then we should
      // redirect there. Redirecting using 307 http status code from the server causes 401's in
      // chrome/ff, and cross origin error in Safari
      if (headers.location && headers.location !== '/') {
        window.open(headers.location, '_blank');
        return;
      }
      const blob = new Blob([data], { type: headers['content-type'] });
      const filename = headers['x-filename'];
      downloadFileBlob(blob, filename);
    } catch (error) {
      const err = error as AxiosError;
      const data = err.response?.data;
      if (data) {
        // Data will be an instance of ArrayBuffer
        const decoder = new TextDecoder('utf-8');
        const decodedError = decoder.decode(data);

        throw new Error(`There was an error downloading your file: ${decodedError || data}`);
      }
      throw err;
    }
  };
  /**
   * Downloads the file and shows a post download modal if the user
   * has a "quota" set on their plan.
   *
   * TODO: Would be good to add the amplitude/intercom event calls here too so
   * the components aren't dealing with and duplicating that logic.
   *
   */
  inject(
    'download',
    async (
      fileId: number,
      customText?: string,
      pageCount?: number,
      data?: any,
      useArrayBuffer = false
    ) => {
      const quotaPreDownload = store.getters.loggedInUser?.subscription?.quota_left;
      await $auth.fetchUser();
      await downloadFile(fileId, { customText, pageCount, data, useArrayBuffer });
      await $auth.fetchUser();
      const isRedownload =
        store.getters.loggedInUser?.subscription?.quota_left === quotaPreDownload;
      if (
        doesUserHaveQuota(store.getters.loggedInUser) &&
        !isRedownload &&
        store.getters.loggedInUser.subscription?.code !== 'plus_lite'
      ) {
        store.dispatch('modals/show', 'downloadLimitAlert');
      }
    }
  );

  /**
   * "Quick download". Used on cards. Same as download but different post
   * download modal actions.
   *
   * TODO: Would be good to add the amplitude/intercom event calls here too so
   * the components aren't dealing with and duplicating that logic.
   */
  inject('quickDownload', async (fileId: number, customText?: string, pageCount?: number) => {
    await downloadFile(fileId, { customText, pageCount, useArrayBuffer: false });
    await $auth.fetchUser();
  });

  /**
   * Checks if a user can download a specific file. User is required to be
   * logged in.
   */
  inject('canUserDownloadFile', async (fileId: number): Promise<boolean> => {
    if (!store.getters.loggedInUser || !fileId) {
      return false;
    }

    try {
      const response = await $axios.get('/public/v1/resource/can-download', {
        params: { id: fileId },
      });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  });

  /**
   * Checks if a user can download a list of file IDs. User is required to be
   * logged in. Returns a list of mapped fileIds to status.
   * Example:
   * request: [1, 2]
   * response: [{ "id": 1, "status": 200 }, { "id": 2, "status": 401, "message": "Only PNG and PDF's are allowed" }]
   * Loggedin user state check has been removed to show what plan to sign up if a guest user wants to download a file
   */
  inject('canUserDownloadFiles', async (fileIds: number[]): Promise<CanUserDownloadResponse[]> => {
    if (!fileIds || fileIds.length === 0) {
      return [];
    }

    try {
      const response = await $axios.get('/public/v1/resource/can-download', {
        params: { id: fileIds },
      });
      return response.data.list || [];
    } catch (e) {
      return [];
    }
  });
};

export default downloadPlugin;
