import { Plugin } from '@nuxt/types';

declare global {
  interface Window {
    fcWidget: any;
  }
}

const MAX_RETRIES = 10;

// wait for window.fcWidget to be defined
const waitForFreshdeskWidget = (callback: () => void, numberOfTries = 0): void => {
  if (typeof window.fcWidget !== 'undefined') {
    callback();
  } else if (numberOfTries < MAX_RETRIES) {
    setTimeout(() => {
      waitForFreshdeskWidget(callback, numberOfTries + 1);
    }, 500);
  }
};

const freshdeskPlugin: Plugin = context => {
  if (process.browser) {
    const script = document.createElement('script');
    script.src = 'https://au.fw-cdn.com/20221638/111958.js';
    script.async = true;
    // @ts-ignore
    script.chat = true;
    document.body.appendChild(script);

    script.onload = (): void => {
      waitForFreshdeskWidget(() => {
        if (context.$auth.loggedIn) {
          const user = context.$auth.user;
          window.fcWidget.setExternalId(user?.uuid);
          window.fcWidget.user.setFirstName(user?.first_name);
          window.fcWidget.user.setEmail(user?.email);
        }
      });
    };

    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    }
  }
};

export default freshdeskPlugin;
