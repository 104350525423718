var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"accordion"},[_c('div',{class:[
      'tw-flex tw-cursor-pointer tw-items-center tw-gap-2',
      { 'tw-w-full tw-justify-between': _vm.variant === 'wide' || _vm.variant === 'wide-child' },
    ]},[_c('button',{class:[
        'tw-flex tw-w-full tw-items-center',
        {
          'tw-group tw-justify-between !tw-py-1.5 tw-text-left hover:tw-text-link focus:tw-text-link':
            _vm.variant === 'wide' || _vm.variant === 'wide-child',
        },
        _vm.btnClass,
        _setup.isExpanded === true ? _vm.expandedBtnClass : '',
      ],attrs:{"aria-expanded":_setup.isExpanded ? 'true' : 'false',"aria-haspopup":"true","type":"button"},on:{"click":_setup.clickEvent}},[_vm._t("label",null,{"isExpanded":_setup.isExpanded}),_vm._v(" "),_c('span',{class:[
          'tw-flex tw-items-center tw-font-normal',
          {
            'tw-pr-0': _vm.variant === 'wide' || _vm.$slots.leftSideArrow,
            'tw-pr-3': _vm.variant === 'wide-child',
            'tw-pr-4': _vm.variant === 'default' && !_vm.$slots.leftSideArrow,
            'group-hover:tw-text-link group-focus:tw-text-link':
              _vm.variant === 'wide' || _vm.variant === 'wide-child',
          },
        ]},[(_setup.isExpanded)?_c('svg',{class:{ 'tw-h-4 tw-w-4': _vm.size === 'md', 'tw-h-6 tw-w-6': _vm.size === 'lg' },attrs:{"xmlns":"http://www.w3.org/2000/svg","role":"img","aria-label":"Close accordion","fill":"none","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M18 12H6"}})]):_c('svg',{class:{ 'tw-h-4 tw-w-4': _vm.size === 'md', 'tw-h-6 tw-w-6': _vm.size === 'lg' },attrs:{"xmlns":"http://www.w3.org/2000/svg","role":"img","aria-label":"Open accordion","fill":"none","viewBox":"0 0 24 24","stroke-width":"2","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M19.5 8.25l-7.5 7.5-7.5-7.5"}})])]),_vm._v(" "),_vm._t("leftSideArrow")],2)]),_vm._v(" "),_c('transition',{attrs:{"name":"accordion"},on:{"enter":_setup.enter,"before-leave":_setup.beforeLeave,"after-enter":_setup.resetHeight,"enter-cancelled":_setup.resetHeight,"after-leave":_setup.resetHeight,"leave-cancelled":_setup.resetHeight}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.isExpanded),expression:"isExpanded"}],staticClass:"tw-transition-all tw-duration-300",class:{
        'tw-overflow-hidden': _vm.overflowHidden,
        'tw-w-full': _vm.variant === 'wide' || _vm.variant === 'wide-child',
      },attrs:{"aria-hidden":_setup.isExpanded ? 'false' : 'true'}},[_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }