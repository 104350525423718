import { Store } from 'vuex/types/index';
import { RootState, State } from '~/store';
import fileFormats from '~/data/file_formats.json';
import {
  FileFormat,
  FileType,
  isEditableResource,
  isFileType,
  isGeneratorWidget,
  isLessonPlan,
  Resource,
} from '~/types/resource';
import type { CanUserDownloadResponse } from '~/types/download';

/**
 * Adjust types from vuex/types/index.d.ts so the mutations have context
 */
type Mutation<S> = (this: Store<State>, state: S, payload?: any) => any;
interface MutationTree<S> {
  [key: string]: Mutation<S>;
}

const mutations: MutationTree<RootState> = {
  updateLocation(state: State, data: any) {
    if (data.updateLocale && this.$i18n.locale !== data.location.secondary) {
      this.$i18n.setLocale(data.location.secondary);
    }

    state.location = data.location;
  },
  updateLocationCookie(_: State, data: any) {
    // Add location cookie

    this.$cookies.set(
      this.$config.cookieLocation,
      JSON.stringify(data.location),
      this.$config.cookieSettings
    );

    // For WordPress location
    this.$cookies.set(
      this.$config.cookieCountryCode,
      data.location.code,
      this.$config.cookieSettings
    );
  },
  updateLocationAccount(state: State, data: any) {
    state.locationAccount = data;

    // Add account location cookie
    if (process.client) {
      this.$cookies.set(
        this.$config.cookieLocationAccount,
        JSON.stringify(data.location),
        this.$config.cookieSettings
      );
    }
  },
  updateAlternatePages(state: State, alternatePages = []) {
    // Redirect to relevant country page after user changes their location
    state.alternatePages = alternatePages;
  },
  dismissToast(state: State) {
    state.toast = null;
  },
  setToast(state: State, data: any) {
    state.toast = data;
  },
  setHeaderBanner(state: State, data: any) {
    state.headerBanner = data;
  },
  setAriaHiddenMain(state: State, value: any) {
    state.ariaHiddenMain = value;
  },
  toggleLoginModal(state: State, show: boolean) {
    state.showLoginModal = show;
    state.ariaHiddenMain = !show;
  },
  toggleLocationSwitchModal(state: State, show: boolean) {
    state.showLocationSwitchModal = show;
    state.ariaHiddenMain = !show;
  },
  toggleLocationSelectorModal(state: State, show: boolean) {
    state.showLocationSelectorModal = show;
    state.ariaHiddenMain = !show;
  },
  toggleUpgradeModal(state: State, show: boolean) {
    state.showUpgradeModal = show;
    state.ariaHiddenMain = !show;
  },
  toggleAddFolderModal(state: State, show: boolean) {
    state.showAddFolderModal = show;
    state.ariaHiddenMain = !show;
  },
  toggleSelectFolderModal(state: State, data: any) {
    state.showSelectFolderModal = data.show;
    // Globally set the current resource ID when opening the Select Collection modal
    state.resourceId = data.id;
    state.ariaHiddenMain = !data.show;
  },
  toggleWelcomeModal(state: State, show: boolean) {
    state.showWelcomeModal = show;
    if (!show) {
      this.$router.replace({ query: {} });
    }
    state.ariaHiddenMain = !show;
  },
  toggleResourcePreview(state: State, preview: any) {
    state.resourcePreview = preview;
    state.ariaHiddenMain = !state.ariaHiddenMain;
  },
  toggleMobileNav(state: State, show: boolean) {
    state.showMobileNav = show;
  },
  toggleMegaMenu(state: State, show: boolean) {
    state.megaMenuOpen = show;
  },
  setCurrentPlan(state: State, currentPlan: string) {
    state.currentPlan = currentPlan;
  },
  setReferralCode(state: State, referralCode: string) {
    state.referralCode = referralCode;
  },
  toggleResourceCurriculumModal(state: State, show: boolean) {
    state.showResourceCurriculumModal = show;
    state.ariaHiddenMain = !show;
  },
  closeAllModals(state: State) {
    // Close all the modals when clicking on the overlay ie behind the modal
    state.showLoginModal = false;
    state.showLocationSelectorModal = false;
    state.showLocationSwitchModal = false;
    state.showUpgradeModal = false;
    state.showAddFolderModal = false;
    state.showSelectFolderModal = false;
    state.showWelcomeModal = false;
    state.showMobileNav = false;
    state.resourcePreview = null;
    state.ariaHiddenMain = false;
    state.showResourceCurriculumModal = false;
  },
  updateSavedSearchTerms(state: State, data: any) {
    state.savedSearchTerms = data.terms;

    if (data.setCookie) {
      if (data.terms.length === 0) {
        this.$cookies.remove(this.$config.cookieSavedSearch);
      } else {
        this.$cookies.set(this.$config.cookieSavedSearch, data.terms, this.$config.cookieSettings);
      }
    }
  },
  loadSiteData(state: State, data: any) {
    state.siteData = data;
  },
  clearResource(state: State) {
    state.resource = null;
  },
  updateGeolocation(state: State, data: any) {
    state.geoLocation = data;
  },
  updateDeviceId(state: State, deviceId: string) {
    state.deviceId = deviceId;
  },
  loadResource(state: State, resource: Resource) {
    // We need to do a bunch of processing on the resource fields to prep them for the template.
    // It would be better if this was moved to the API but in essence of time, it's being done here.

    // Replace the WP shortcodes in content block with their proper HTML elements
    if (resource.content) {
      resource.content = this.$replaceShortcodes(resource.content);

      // replace http links with https
      resource.content = resource.content.replace(/http:\/\//g, 'https://');
    }

    // If content has scripts, it needs to be added to the DOM for CSR
    if (resource.content && process.client) {
      this.$addScriptsToDOM(resource.content);
    }

    // For lesson plans
    if (isLessonPlan(resource) && resource.parent?.lessons) {
      // Add previous and next lesson
      const lessonIndex = resource.parent.lessons.findIndex(
        (lesson: any) => lesson.id === resource.id
      );

      if (lessonIndex > -1) {
        resource.previous = resource.parent.lessons[lessonIndex - 1];
        resource.next = resource.parent.lessons[lessonIndex + 1];
      }
    }

    // For generator widgets
    // Replace the link with a link to the subdomain. Some links aren't updating from WP admin after their /play/ path was removed, so force remove it
    if (isGeneratorWidget(resource)) {
      resource.widget_link = resource.widget_link
        ? this.$config.wpURL + resource.widget_link.replace('/play/', '/')
        : this.$config.wpURL + resource.link;

      resource.widget_link += '?amp_device_id=' + state.deviceId;
    }

    // Check if alternative country versions are available to show a banner
    if (
      state.location &&
      state.locationAccount &&
      resource.country !== state.locationAccount.secondary &&
      resource.linked &&
      resource.linked.length > 0
    ) {
      const localVersion = resource.linked.find(r => r.country === state.locationAccount.secondary);
      if (localVersion) {
        resource.local_version = {
          content: 'View this resource in ' + state.locationAccount.name + ' →',
          link: localVersion.link,
          icon: 'globe',
        };
      }
    }

    // Add link to Studio or Display Banners widget to customise this resource
    if (isEditableResource(resource)) {
      if (
        resource.links_to_studio &&
        resource.tags.find(({ name }) => name === 'Desk Name Plates')
      ) {
        resource.customise_link = this.localePath(
          `/teaching-resource/desk-plates?fileId=${resource.files[0].id}&resourceId=${resource.id}`
        );
      } else {
        resource.customise_link = this.$config.wpURL;
        if (resource.links_to_studio) {
          resource.customise_link += '/studio/?t_id=' + resource.old_id + '&t_type=resource';
        } else if (resource.links_to_widget) {
          const countryPrefix = resource.country === 'au' ? '' : '-' + resource.country;
          resource.customise_link +=
            '/' +
            resource.country +
            '/widget/display-banners' +
            countryPrefix +
            '/?resource_id=' +
            resource.old_id;
        }
      }
    }

    // If has list of school_fonts, replace files
    if (resource.school_fonts?.length !== 0) {
      resource.files = [...(resource.school_fonts || []), ...(resource.files || [])];
    }

    //This piece of code will be redundant once API is setup to include the pdf files as the default download
    // irrespective of the default file set in the admin backend.
    //sorting resource.files object to make sure the default download is a pdf(free). adding editable or not against each file to enable or
    //disable download based on the user capability.
    const downloadFiles = [];
    let y = 0;
    for (let x = 0; x < resource.files.length; x++) {
      if (resource.files[x].format === 'pdf' || resource.files[x].format === 'png') {
        downloadFiles[y] = resource.files[x];
        downloadFiles[y].downloadable_file_type = 'not editable';
        y++;
      }
    }

    const currentSet = new Set(downloadFiles || resource.files);
    const downloadPremium = resource.files.filter(x => !currentSet.has(x));

    for (const d of downloadPremium) {
      d.downloadable_file_type = 'editable';
    }

    resource.files = (downloadFiles || resource.files).concat(downloadPremium);

    if (resource.file_formats) {
      resource.file_formats = resource.file_formats.map((format: FileFormat | FileType) => {
        return isFileType(format)
          ? {
              name: fileFormats[format],
              format,
            }
          : format;
      });
    }

    // Remove the top level breadcrumb from curriculums. Usually redundant eg. "Australian Curriculum Categories"
    if (resource.curriculums) {
      resource.curriculums = resource.curriculums.map((c: any) => {
        // @todo: these should be fixed on the API side
        if (c.name === 'Teks') {
          c.name = 'TEKS';
        }

        if (c.name === 'Common Core Standards In America') {
          c.name = 'Common Core State Standards';
        }

        c.items.map((item: any) => {
          if (item.breadcrumbs.length > 1) {
            item.breadcrumbs.shift();
          }

          return item;
        });

        return c;
      });
    }

    // Yoast variables are being passed to the frontend. These should be fixed on the API side but in the essence of time I'm replacing them here
    resource.meta_title =
      resource.meta_title && resource.meta_title !== ''
        ? resource.meta_title.replace('%%title%%', resource.name).replace('%%sep%%', ' - ')
        : resource.name;

    // For Amplitude events
    resource.event_params = this.$constructEventParamsPage(resource);

    //For Intercom
    resource.event_params_intercom = this.$constructIntercomResourceParams(resource);

    state.resource = resource;

    // Fallback in case the resource/stats don't load
    state.resourceStats = {
      likes: { count: resource.likes },
      bookmarks: { count: resource.bookmarks },
    };
  },
  loadResourceStats(state: State, data: any) {
    state.resourceStats = data;
  },
  loadUserDownloadPermissions(state: State, data: CanUserDownloadResponse[] | null) {
    state.userDownloadPermissions = data;
  },
  loadShortCodeResources(state: State, data: any) {
    // Replace the resource shortcodes in content block with their proper HTML elements
    state.resource.content = this.$replaceResourceShortcodes(
      state.resource.content,
      data,
      state.resource.country
    );
  },
  loadRecommendedResources(state: State, { resources, numToShow }) {
    state.recommendedResources = resources.slice(0, numToShow);
  },
  loadingSearchResults(state: State, isLoading = true) {
    state.loadingSearchResults = isLoading;
  },
  loadingUserFolders(state: State, isLoading = true) {
    state.loadingUserFolders = isLoading;
  },
  addComment(state: State, comments: any) {
    state.comments = comments;
    state.resource.comments = state.resource.comments + 1;
  },
  loadComments(state: State, comments: any) {
    state.comments = comments;
  },
  loadCategory(state: State, category: any) {
    // For Amplitude events
    category.event_params = this.$constructEventParamsCategory(category);

    // Remove 'uncategorized' from blog categories
    if (category.items) {
      category.items = category.items.filter((item: any) => item.slug !== 'uncategorized');
    }

    state.category = category;

    // Check if alternative country versions are available to show a banner
    if (
      state.location &&
      state.locationAccount &&
      category.country !== state.locationAccount.secondary &&
      category.linked.length > 0
    ) {
      const localVersion = category.linked.find(
        (r: any) => r.country === state.locationAccount.secondary
      );
      if (localVersion) {
        category.local_version = {
          content: 'View this category in ' + state.locationAccount.name + ' →',
          link: localVersion.link,
          icon: 'globe',
        };
      }
    }
  },
  loadFilters(state: State, filters: any) {
    state.filters = filters;
  },
  loadCurriculums(state: State, curriculums: any) {
    state.curriculums = curriculums;
  },
  loadCurriculum(state: State, curriculum: any) {
    // @todo: should be fixed on the API side

    if (curriculum.root.name === 'Common Core Standards In America') {
      curriculum.root.name = 'Common Core State Standards';
    }

    if (curriculum.parent.name === 'Common Core Standards In America') {
      curriculum.parent.name = 'Common Core State Standards';
    }

    // For Amplitude events
    curriculum.event_params = this.$constructEventParamsCategory(curriculum);
    state.curriculum = curriculum;
  },
  setRedirectToBilling(state: State, link: string) {
    state.redirectToBilling = link;
    this.$cookies.set(this.$config.cookieRedirectToBilling, link, this.$config.cookieSettings);
  },
  setRedirect(state: State, link: string) {
    // Set redirect cookies for WordPress
    state.redirectTo = link;
    this.$cookies.set(this.$config.cookieRedirectTo, link, this.$config.cookieSettings);
  },
  authoriseWidget(state: State, data: any) {
    state.isAuthorisedWidget = data.authorised;
    state.userWordLists = data.wordlists;
  },
  updateSearchResults(state: State, data: any) {
    if (data.append) {
      state.searchResults.results = state.searchResults.results.concat(data.results.results);
    } else {
      state.searchResults = data.results;
    }
  },
  loadUserBookmarks(state: State, bookmarks: any) {
    state.userBookmarks = bookmarks;
  },
  loadUserFolders(state: State, collections: any[]) {
    state.userFolders = collections;
  },
  loadUserFolder(state: State, collection: any) {
    state.userFolder = collection;

    // Update the list of collections for the sidebar
    if (state.userFolders.length > 0) {
      state.userFolders = state.userFolders.map(c => {
        if (c.id === collection.id) {
          c = collection;
        }

        return c;
      });
    }
  },
  loadResourcesFromUserFolder(state: State, collection: any) {
    state.userFolder = collection;
  },
  addUserFolder(state: State, collection: any) {
    state.userFolder = collection;

    // Update the list of collections for the sidebar
    state.userFolders.unshift(collection);
  },
  deleteUserFolder(state: State, collectionId: number | string) {
    state.userFolder = { name: '' };

    // Update the list of collections for the sidebar
    if (state.userFolders.length > 0) {
      state.userFolders = state.userFolders.filter(c => c.id !== collectionId);
    }
  },
  addResourceUserFolder(state: State, collection: any) {
    // Increment count in collection list
    state.userFolders = state.userFolders.map(c => {
      return c.id === collection.id ? collection : c;
    });
  },
  deleteResourceUserFolder(state: State, data: any) {
    // If on a collection page and not Downloads, Bookmarks, History
    if (
      state.userFolder &&
      state.userFolder.can_edit_collection &&
      state.userFolder.id === data.id
    ) {
      state.userFolder.items = state.userFolder.items.filter(
        (item: any) => item.resource && item.resource.id !== data.resource
      );
    }

    if (state.userFolders) {
      // Decrement count in collection list
      state.userFolders = state.userFolders.map(collection => {
        if (collection.id === data.id) {
          collection.item_count--;
          if (collection.items) {
            collection.items = collection.items.filter(
              (item: any) => item.resource && item.resource.id !== data.resource
            );
          }
        }

        return collection;
      });
    }
  },
  toggleLikeBookmark(state: State, data: any) {
    // Update the resource stats 'likes' or 'bookmarks' field
    const actionField =
      data.action === 'bookmark' ? state.resourceStats.bookmarks : state.resourceStats.likes;

    actionField.active = !data.active;
    actionField.count = data.active ? actionField.count - 1 : actionField.count + 1;
  },
  loadUserHomeFeed(state: State, data: any) {
    if (data.append) {
      // Add to the end of the current list of items
      state.userHomeFeed.list = state.userHomeFeed.list.concat(data.list);
      state.userHomeFeed.page = data.page;
    } else {
      state.userHomeFeed = data;
    }
  },
  loadUserNotifications(state: State, data: any) {
    if (data.append) {
      data.list = state.userNotifications.list.concat(data.list);
    }

    state.userNotifications = data;
  },
  loadTrendingTopics(state: State, data: any) {
    state.trendingTopics = data;
  },
  loadUserStudioFiles(state: State, data: any) {
    const files = {
      items: data.list.map((item: any) => {
        // Studio files link to subdomain
        item.resource.link = this.$config.wpURL + item.resource.link;
        return item;
      }),
      item_count: data.list.length,
    };

    state.userStudioFiles = files;
  },
  loadUserWordLists(state: State, wordlists: any[]) {
    state.userWordLists = wordlists;
  },
  addUserWordList(state: State, wordlist: any) {
    state.userWordLists.unshift(wordlist);
  },
  updateUserWordList(state: State, wordlist: any) {
    state.userWordLists = state.userWordLists.map(list => {
      if (list.id === wordlist.id) {
        list = wordlist;
      }

      return list;
    });
  },
  deleteUserWordList(state: State, listId: number | string) {
    state.userWordLists = state.userWordLists.filter(list => list.id !== listId);
  },
  updateExperiments(state: State, experiments: any) {
    state.experiments = experiments;
  },
  setIsErrorPage(state: State, isErrorPage = false) {
    state.isErrorPage = isErrorPage;
  },
  loadSchoolFonts(state: State, fonts: any) {
    state.schoolFonts = fonts;
  },
  loadSchoolFontLines(state: State, data: any) {
    state.schoolFontLines = data;
  },
};

export default mutations;
