import { Locale } from '~/types/locale';

export type Grade<T extends Locale = Locale> = T extends 'us'
  ?
      | 'pre-kindergarten'
      | 'kindergarten'
      | 'grade-1'
      | 'grade-2'
      | 'grade-3'
      | 'grade-4'
      | 'grade-5'
      | 'grade-6'
      | 'grade-7'
      | 'other'
  : T extends 'gb'
    ?
        | 'nursery'
        | 'reception'
        | 'foundation-stage'
        | 'key-stage-1'
        | 'lower-key-stage-2'
        | 'upper-key-stage-2'
        | 'key-stage-3'
        | 'year-1'
        | 'year-2'
        | 'year-3'
        | 'year-4'
        | 'year-5'
        | 'year-6'
        | 'year-7'
        | 'other'
    : T extends 'au'
      ?
          | 'preschool-kindergarten'
          | 'foundation-year'
          | 'year-1'
          | 'year-2'
          | 'year-3'
          | 'year-4'
          | 'year-5'
          | 'year-6'
          | 'year-7'
          | 'other'
      : 'never';

export type GradeId = Grade<'us'> | Grade<'gb'> | Grade<'au'>;

export const GradeValues: readonly GradeId[] = [
  'pre-kindergarten',
  'kindergarten',
  'grade-1',
  'grade-2',
  'grade-3',
  'grade-4',
  'grade-5',
  'grade-6',
  'grade-7',
  'foundation-stage',
  'nursery',
  'reception',
  'key-stage-1',
  'lower-key-stage-2',
  'upper-key-stage-2',
  'key-stage-3',
  'preschool-kindergarten',
  'foundation-year',
  'year-1',
  'year-2',
  'year-3',
  'year-4',
  'year-5',
  'year-6',
  'year-7',
  'other',
] as const;

export interface GradeInfo<T extends Locale> {
  label: string;
  value: Grade<T>;
}

export interface Grades {
  us: readonly GradeInfo<'us'>[];
  gb: readonly GradeInfo<'gb'>[];
  au: readonly GradeInfo<'au'>[];
}

export type GradeValue<T extends Locale> = Grades[T][number]['value'];

export const gradesForLocale = (locale: Locale): string[] => grades[locale].map(g => g.value);
export const gradeInfoForLocale = <T extends Locale>(locale: T): GradeInfo<T>[] =>
  grades[locale] as GradeInfo<T>[];
export const gradeLabelForLocale = <T extends Locale>(
  locale: T,
  grade: GradeValue<T>
): string | undefined => {
  const gradeInfo = grades[locale] as readonly GradeInfo<T>[];
  return gradeInfo.find(g => g.value === grade)?.label;
};

export const isGradeForLocale = <T extends Locale>(
  locale: T,
  grade: string
): grade is GradeValue<T> => {
  return gradesForLocale(locale).includes(grade as GradeValue<T>);
};

export const isGradeId = (grade: string): grade is GradeId => {
  return GradeValues.includes(grade as Grade);
};

export const OldUsGrades: { value: string; label: string }[] = [
  {
    value: 'pre-kindergarten',
    label: 'Pre-Kindergarten',
  },
  {
    value: 'kindergarten',
    label: 'Kindergarten',
  },
  {
    value: 'grade-1',
    label: 'Grade 1',
  },
  {
    value: 'grade-2',
    label: 'Grade 2',
  },
  {
    value: 'grade-3',
    label: 'Grade 3',
  },
  {
    value: 'grade-4',
    label: 'Grade 4',
  },
  {
    value: 'grade-5',
    label: 'Grade 5',
  },
  {
    value: 'grade-6',
    label: 'Grade 6',
  },
  {
    value: 'grade-7',
    label: 'Grade 7',
  },
];

export const grades: Grades = {
  us: [
    {
      value: 'pre-kindergarten',
      label: 'Pre-K',
    },
    {
      value: 'kindergarten',
      label: 'Kindergarten',
    },
    {
      value: 'grade-1',
      label: '1st Grade',
    },
    {
      value: 'grade-2',
      label: '2nd Grade',
    },
    {
      value: 'grade-3',
      label: '3rd Grade',
    },
    {
      value: 'grade-4',
      label: '4th Grade',
    },
    {
      value: 'grade-5',
      label: '5th Grade',
    },
    {
      value: 'grade-6',
      label: '6th Grade',
    },
    {
      value: 'grade-7',
      label: '7th Grade',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ],
  gb: [
    {
      value: 'foundation-stage',
      label: 'Foundation Stage',
    },
    {
      value: 'key-stage-1',
      label: 'Key Stage 1',
    },
    {
      value: 'lower-key-stage-2',
      label: 'Key Stage 2 - Lower',
    },
    {
      value: 'upper-key-stage-2',
      label: 'Key Stage 2 - Upper',
    },
    {
      value: 'key-stage-3',
      label: 'Key Stage 3',
    },
    {
      value: 'year-1',
      label: 'Year 1',
    },
    {
      value: 'year-2',
      label: 'Year 2',
    },
    {
      value: 'year-3',
      label: 'Year 3',
    },
    {
      value: 'year-4',
      label: 'Year 4',
    },
    {
      value: 'year-5',
      label: 'Year 5',
    },
    {
      value: 'year-6',
      label: 'Year 6',
    },
    {
      value: 'year-7',
      label: 'Year 7',
    },
    {
      value: 'nursery',
      label: 'Nursery',
    },
    {
      value: 'reception',
      label: 'Reception',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ],
  au: [
    {
      value: 'preschool-kindergarten',
      label: 'Preschool/Kindergarten',
    },
    {
      value: 'foundation-year',
      label: 'Foundation Year',
    },
    {
      value: 'year-1',
      label: 'Year 1',
    },
    {
      value: 'year-2',
      label: 'Year 2',
    },
    {
      value: 'year-3',
      label: 'Year 3',
    },
    {
      value: 'year-4',
      label: 'Year 4',
    },
    {
      value: 'year-5',
      label: 'Year 5',
    },
    {
      value: 'year-6',
      label: 'Year 6',
    },
    {
      value: 'year-7',
      label: 'Year 7',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ],
} as const;

export const GRADE_NAMES: string[] = Object.values(grades)
  .flat()
  .map(g => g.value);

export const convertOldUsGradeName = (grade: string): string => {
  const oldGrade = OldUsGrades.find(g => g.label === grade);
  return oldGrade ? grades.us.find(g => g.value === oldGrade.value)?.label ?? grade : grade;
};

export const getGradeValueFromLabel = (locale: Locale, label: string): GradeId | undefined => {
  return gradeInfoForLocale(locale).find(g => g.label?.toLowerCase() === label?.toLowerCase())
    ?.value;
};

export default grades;
