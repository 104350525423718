<template>
  <aside class="sticky">
    <div>
      <user-profile />
      <nav v-if="navigationLinks" class="sidebar-nav">
        <ul class="link-list">
          <li v-for="link in navigationLinks" :key="link.slug">
            <nuxt-link
              v-if="link.smartName !== 'Subscription'"
              :to="link.url"
              :title="link.title"
              class="tw-leading-normal sm:tw-block"
              :class="{ 'exact-active-link': $route.path.includes(link.slug) }"
            >
              <svg
                class="icon icon-small text-primary tw-hidden md:tw-block"
                role="img"
                aria-hidden="true"
              >
                <use
                  v-bind="{
                    'xlink:href': '/images/fa-icons.svg?v=11#' + link.icon,
                  }"
                ></use>
              </svg>
              <span class="tw-hidden md:tw-block">{{ link.name }}</span>
              <span class="sm:tw-block md:tw-hidden">{{ link.smartName }}</span>
            </nuxt-link>
            <a
              v-else
              :href="$config.wpURL + '/member/plan/?amp_device_id=' + deviceId"
              title="View your plan settings"
              class="tw-leading-normal sm:tw-block"
              :class="{ 'exact-active-link': $route.path.includes(link.slug) }"
            >
              <svg
                class="icon icon-small text-primary tw-hidden md:tw-block"
                role="img"
                aria-hidden="true"
              >
                <use
                  v-bind="{
                    'xlink:href': '/images/fa-icons.svg?v=11#' + link.icon,
                  }"
                ></use>
              </svg>
              Subscription
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import UserProfile from '~/components/hub/UserProfile.vue';
import navigationLinks from '~/data/navigation_links_member.json';
export default {
  name: 'MemberSideNav',
  components: {
    UserProfile,
  },
  data() {
    return {
      deviceId: '',
    };
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    showReferrals() {
      if (!this.$isFlagOn('saasquatch-integration')) {
        return false;
      }
      if (this.$isFlagOn('enable-referral-program-for-free-members')) {
        return true;
      }
      if (this.loggedInUser?.subscription?.code !== 'free') {
        return true;
      }
      return false;
    },
    showBillingPagePaypal() {
      if (
        this.$isFlagOn('billing-page-paypal') &&
        this.loggedInUser?.subscription?.code !== 'free'
      ) {
        return true;
      }
      return false;
    },
    navigationLinks() {
      let navLinks = navigationLinks[this.$i18n.locale];
      if (!this.showReferrals) {
        navLinks = navLinks.filter(link => link.slug !== 'referrals');
      }
      if (!this.showBillingPagePaypal) {
        navLinks = navLinks.filter(link => link.slug !== 'payment-method');
      }
      return navLinks;
    },
  },
  mounted() {
    this.deviceId = this.$amplitude?.getDeviceId() ?? '';
  },
};
</script>

<style lang="scss" scoped>
@media #{$small-only} {
  .link-list {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .link-list li a {
    display: block !important;
  }
}

aside {
  margin-top: $base-padding * 2;
  z-index: 9;
  background-color: white;
}

.box {
  margin-bottom: $base-padding;
}
</style>
