import { GetterTree } from 'vuex';
import { RootState, State } from './';
import { User } from '~/types/user';

const getters: GetterTree<RootState, RootState> = {
  alternatePages(state: State): State['alternatePages'] {
    return state.alternatePages;
  },
  category(state: State): State['category'] {
    return state.category;
  },
  comments(state: State): State['comments'] {
    return state.comments;
  },
  curriculum(state: State): State['curriculum'] {
    return state.curriculum;
  },
  curriculums(state: State): State['curriculums'] {
    return state.curriculums;
  },
  deviceId(state: State): State['deviceId'] {
    return state.deviceId;
  },
  filters(state: State): State['filters'] {
    return state.filters;
  },
  headerBanner(state: State): State['headerBanner'] {
    return state.headerBanner;
  },
  isAuthenticated(state: State): boolean {
    return !!state.auth?.loggedIn;
  },
  isAuthorisedWidget(state: State): State['isAuthorisedWidget'] {
    // User can access widget based on capability
    return state.isAuthorisedWidget;
  },
  location(state: State): State['location'] {
    return state.location;
  },
  locationAccount(state: State): State['locationAccount'] {
    return state.locationAccount;
  },
  loggedInUser(state: State): User | undefined {
    return state.auth?.user;
  },
  loadingSearchResults(state: State): State['loadingSearchResults'] {
    return state.loadingSearchResults;
  },
  loadingUserFolders(state: State): State['loadingUserFolders'] {
    return state.loadingUserFolders;
  },
  ariaHiddenMain(state: State): State['ariaHiddenMain'] {
    return state.ariaHiddenMain;
  },
  showLoginModal(state: State): State['showLoginModal'] {
    return state.showLoginModal;
  },
  showLocationSelectorModal(state: State): State['showLocationSelectorModal'] {
    return state.showLocationSelectorModal;
  },
  showLocationSwitchModal(state: State): State['showLocationSwitchModal'] {
    return state.showLocationSwitchModal;
  },
  showUpgradeModal(state: State): State['showUpgradeModal'] {
    return state.showUpgradeModal;
  },
  showAddFolderModal(state: State): State['showAddFolderModal'] {
    return state.showAddFolderModal;
  },
  showSelectFolderModal(state: State): State['showSelectFolderModal'] {
    return state.showSelectFolderModal;
  },
  showWelcomeModal(state: State): State['showWelcomeModal'] {
    return state.showWelcomeModal;
  },
  savedSearchTerms(state: State): State['savedSearchTerms'] {
    return state.savedSearchTerms;
  },
  showResourceCurriculumModal(state: State): State['showResourceCurriculumModal'] {
    return state.showResourceCurriculumModal;
  },
  resourcePreview(state: State): State['resourcePreview'] {
    return state.resourcePreview;
  },
  showMobileNav(state: State): State['showMobileNav'] {
    return state.showMobileNav;
  },
  resource(state: State): State['resource'] {
    return state.resource;
  },
  resourceStats(state: State): State['resourceStats'] {
    return state.resourceStats;
  },
  resourceId(state: State): State['resourceId'] {
    return state.resourceId;
  },
  resourcePages(state: State): number {
    // Get the number of resource pages created
    return state.siteData ? state.siteData.resource_page_count : 0;
  },
  redirectTo(state: State): State['redirectTo'] {
    // Link to redirect user to after login
    return state.redirectTo;
  },
  redirectToBilling(state: State): State['redirectToBilling'] {
    return state.redirectToBilling;
  },
  searchResults(state: State): State['searchResults'] {
    return state.searchResults;
  },
  toast(state: State): State['toast'] {
    // Display a banner above the header
    return state.toast;
  },
  treeCount(state: State): number {
    // Get the number of trees planted
    return state.siteData ? state.siteData.trees_planted : 0;
  },
  trendingTopics(state: State): State['trendingTopics'] {
    return state.trendingTopics;
  },
  userBookmarks(state: State): State['userBookmarks'] {
    return state.userBookmarks;
  },
  userFolders(state: State): State['userFolders'] {
    // User's list of folders
    return state.userFolders;
  },
  userFolder(state: State): State['userFolder'] {
    // User's list of resources in collection
    return state.userFolder;
  },
  userHomeFeed(state: State): State['userHomeFeed'] {
    // List items in the hub
    return state.userHomeFeed;
  },
  userNotifications(state: State): State['userNotifications'] {
    // Display in the header when logged in
    return state.userNotifications;
  },
  userStudioFiles(state: State): State['userStudioFiles'] {
    return state.userStudioFiles;
  },
  userWordLists(state: State): State['userWordLists'] {
    // Used for widgets
    return state.userWordLists;
  },
  recommendedResources(state: State): State['recommendedResources'] {
    return state.recommendedResources;
  },
  experiments(state: State): State['experiments'] {
    return state.experiments;
  },
  geoLocation(state: State): State['geoLocation'] {
    return state.geoLocation;
  },
  schoolFonts(state: State): State['schoolFonts'] {
    return state.schoolFonts;
  },
  schoolFontLines(state: State): State['schoolFontLines'] {
    return state.schoolFontLines;
  },
  currentPlan(state: State): State['currentPlan'] {
    return state.currentPlan;
  },
  currentReferralCode(state: State): State['referralCode'] {
    return state.referralCode;
  },
  megaMenuOpen(state: State): State['megaMenuOpen'] {
    return state.megaMenuOpen;
  },
  userDownloadPermissions(state: State): State['userDownloadPermissions'] {
    return state.userDownloadPermissions;
  },
};
export default getters;
