<template>
  <div class="tw-relative">
    <div class="tw-absolute tw-inset-0 tw-flex tw-items-center" aria-hidden="true">
      <div class="tw-w-full tw-border-t tw-border-gray-300"></div>
    </div>
    <div class="tw-relative tw-flex tw-justify-center">
      <span class="tw-bg-white tw-px-2 tw-text-sm tw-text-gray-500"><slot /></span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TDivider',
};
</script>
