import { Plugin } from '@nuxt/types';
import { AxiosError } from 'axios';

interface AuthError extends AxiosError {
  method: string;
}

const authPlugin: Plugin = ({ $auth, $sentry, store }): void => {
  if (!$auth.loggedIn) {
    // Access the previous URL before the redirect
    // const previousUrl = $auth from || '/'
    if ($auth.ctx.ssrContext?.url.includes('member-settings/payment-method')) {
      store.dispatch('setRedirectToBilling', '/member-settings/payment-method');
      store.dispatch('setRedirect', '/member-settings/payment-method');
    }
  }
  if (process.browser) {
    if ($auth.loggedIn) {
      store.dispatch('loginWP');
    }
    $auth.onError((error, name) => {
      const typeError = error as AxiosError | AuthError;
      const typeName = name as AuthError;

      // The @nuxtjs/auth library throws two errors. One for the axios request and one for a more specific failure e.g. Login, Sign Up, etc.
      // We only want to capture one of the errors so ignoring the axios error so we have that bit more context.
      if (typeError && typeName && typeName.method !== 'request') {
        // Dont want to send sentry events for wrong email/password
        if (!typeError.message?.includes('400') && $sentry) {
          console.error(typeName, typeError);
          $sentry.captureException(
            new Error(
              `User got error when trying to login ${typeError} - ${JSON.stringify(typeName)}`
            )
          );
        }
      }
    });
  }
};

export default authPlugin;
