import { WithContext, Organization, WebSite } from 'schema-dts';
export const website: WithContext<WebSite> = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  '@id': 'https://www.teachstarter.com/#website',
  name: 'Teach Starter',
  url: 'https://www.teachstarter.com/',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://www.teachstarter.com/search/{search_term_string}/',
    // @ts-ignore - the type wants query, but query-input seems to be the widely used and preferred property
    'query-input': 'required name=search_term_string',
  },
};

export const organization: WithContext<Organization> = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  '@id': 'https://www.teachstarter.com/#organization',
  name: 'Teach Starter',
  description:
    'An online educational resources company that offers a subscription service to access teaching materials for educators',
  url: 'https://www.teachstarter.com/',
  logo: 'https://www.teachstarter.com/images/ts-logo.png',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Level 2F, 55 Railway Terrace',
    addressLocality: 'Milton',
    addressRegion: 'QLD',
    addressCountry: 'Australia',
    postalCode: '4064',
  },
  sameAs: [
    'https://www.wikidata.org/wiki/Q115750039',
    'https://www.facebook.com/teachstarter',
    'https://www.instagram.com/teachstarter',
    'https://www.linkedin.com/company/teach-starter',
    'https://www.youtube.com/teachstarter',
    'https://www.pinterest.com/teachstarter',
    'https://twitter.com/teachstarter',
  ],
};
