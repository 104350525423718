import { ActionTree } from 'vuex';
import axios from 'axios';
import { LocalScheme } from '@nuxtjs/auth-next';
import partition from 'lodash.partition';
import { RootState } from '.';
import { Category } from '~/types/category';
import { isString } from '~/types/utils';
import {
  FilterInfo,
  PublicV1FiltersResponse,
  PublicV1ResourceRequest,
  PublicV1ResourceResponse,
} from '~/types/api';
import { yearLevelToTitle } from '~/utils/category';
import { convertOldUsGradeName, gradesForLocale } from '~/data/grades';
import { Resource, ResourceList, isResourceList } from '~/types/resource';
import { Config } from '~/types/config';

const getErrorPageMessage = (err: unknown, type?: string): string => {
  if (axios.isAxiosError(err)) {
    return `Failed to get "${type || err.config.url}" with error: ${
      err.response ? err.response.data.error : err.message
    }`;
  }
  if (err instanceof Error) {
    return err.message;
  }
  return String(err);
};

declare module 'vuex/types' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $config: Config;
  }
}

const Actions: ActionTree<RootState, RootState> = {
  nuxtServerInit({ commit, dispatch }): void {
    // This action is called on every page load

    // Check for the user's location on every route and update state
    const location = this.$cookies.get(this.$config.cookieLocation);

    // Update local state with location from cookie
    if (location && location.code) {
      commit('updateLocation', { location });

      // Also load topics when country changes
      dispatch('loadTrendingTopics', { country: location.secondary, perPage: 13 });
    }

    // The location set from your WP account, if you logged in. Used to show region switcher banner on resources/topics
    const locationAccount = this.$cookies.get(this.$config.cookieLocationAccount);

    if (locationAccount && locationAccount.code) {
      commit('updateLocationAccount', locationAccount);
    }

    // Set a redirectTo cookie to redirect back to this page after login or account creation
    const redirectTo = this.$cookies.get(this.$config.cookieRedirectTo);
    if (redirectTo) {
      commit('setRedirect', redirectTo);
    }
    const redirectToBilling = this.$cookies.get(this.$config.cookieRedirectToBilling);
    if (redirectToBilling) {
      commit('setRedirectToBilling', redirectToBilling);
    }
  },
  updateLocation({ commit }, location): void {
    // Called from LocationSelector
    commit('updateLocation', { location });
    commit('updateLocationCookie', { location });
  },
  updateLocationAccount({ commit }, location): void {
    // API user uses a 'country' field instead of 'name' so remap this
    if (location.country) {
      location.name = location.country;
      delete location.country;
    }

    // Called from LoginForm
    commit('updateLocationAccount', location);
  },
  getSavedSearchTerms({ commit }): void {
    if (this.$cookies) {
      const savedSearchTerms = this.$cookies.get(this.$config.cookieSavedSearch);
      if (savedSearchTerms) {
        commit('updateSavedSearchTerms', { terms: savedSearchTerms, setCookie: false });
      }
    }
  },
  updateSavedSearchTerms({ commit }, terms): void {
    // Called from SearchBar
    commit('updateSavedSearchTerms', { terms, setCookie: true });
  },
  setToast(
    { commit },
    { content = '', type = 'success', image = null, icon = 'check', dismiss = true } = {}
  ) {
    // Show a notice bar in header of the page eg. after login
    // Set default params
    commit('dismissToast');
    commit('setToast', { content, type, image, icon, dismiss });
  },
  setHeaderBanner({ commit }, params): void {
    commit('setHeaderBanner', params);
  },
  dismissToast({ commit }): void {
    // Close the notice bar
    commit('dismissToast');
  },
  toggleLoginModal({ commit }, show): void {
    // Show/hide the login modal on button click
    commit('toggleLoginModal', show);
  },
  toggleLocationSwitchModal({ commit }, show): void {
    // Show/hide the switch location modal on button click
    commit('toggleLocationSwitchModal', show);
  },
  toggleLocationSelectorModal({ commit }, show): void {
    // Show/hide the location selector modal on button click
    commit('toggleLocationSelectorModal', show);
  },
  toggleUpgradeModal({ commit }, show): void {
    // Show/hide the upgrade modal on button click
    commit('toggleUpgradeModal', show);
  },
  toggleAddFolderModal({ commit }, show): void {
    // Show/hide the Add Folder modal on button click
    commit('toggleAddFolderModal', show);
  },
  toggleSelectFolderModal({ commit, state, dispatch }, params): void {
    if (state.userFolders.length === 0) {
      dispatch('loadingUserFolders');
      dispatch('loadUserFolders');
    }
    // Show/hide the Select Folder modal on button click
    commit('toggleSelectFolderModal', params);
  },
  toggleWelcomeModal({ commit }, show): void {
    // Show/hide the Welcome modal on button click
    commit('toggleWelcomeModal', show);
  },
  toggleResourcePreview({ commit }, preview): void {
    // Show/hide the resource preview modal if preview link is set
    commit('toggleResourcePreview', preview);
  },
  toggleResourceCurriculumModal({ commit }, show): void {
    // Show/hide the resource curriculum modal on button click
    commit('toggleResourceCurriculumModal', show);
  },
  toggleMobileNav({ commit }, show): void {
    // Show/hide the mobile nav
    commit('toggleMobileNav', show);
  },
  toggleMegaMenu({ commit }, show): void {
    // Show/hide the mega menu
    commit('toggleMegaMenu', show);
  },
  setCurrentPlan({ commit }, currentPlan): void {
    // Set the user's plan
    commit('setCurrentPlan', currentPlan);
  },
  setReferralCode({ commit }, referralCode): void {
    // Set the user's referral code
    commit('setReferralCode', referralCode);
  },
  closeAllModals({ commit }): void {
    commit('closeAllModals');
  },
  setRedirectToBilling({ commit }, link): void {
    commit('setRedirectToBilling', link);
  },
  setRedirect({ commit }, link): void {
    // Set a link to redirect back to after login
    commit('setRedirect', link);
  },
  updateGeolocation({ commit }, geoLocation): void {
    commit('updateGeolocation', geoLocation);
  },
  updateDeviceId({ commit }, deviceId): void {
    commit('updateDeviceId', deviceId);
  },
  async loadSiteData({ commit }): Promise<void> {
    try {
      const response = await this.$axios.$get('/public/v1/settings');
      commit('loadSiteData', response);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async getGeoLocation({ commit }): Promise<Location | null> {
    // Use the local endpoint at https://CURRENT_URL/fn/geoip
    // Lookup from Maxmind GeoLite2 database in the server_middleware folder
    try {
      const location = await this.$axios.$get('/public/v1/geoip');

      if (location?.code) {
        commit('updateLocation', { location, updateLocale: true });
        commit('updateLocationCookie', { location });
        return location;
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.log('GeoIP not found');
      }
    }
    return null;
  },
  async loadResource(
    { commit, dispatch, state },
    params: PublicV1ResourceRequest
  ): Promise<PublicV1ResourceResponse> {
    try {
      const response = await this.$axios.$get<Resource | ResourceList>('/public/v1/resource', {
        params,
      });

      if (!isResourceList(response)) {
        // HACK: It seems we can get resources without links. Filter them out and then warn about the
        // bad ones. e.g. /us/teaching-resource-pack/greece-classroom-theme-pack-us/
        const [good, bad] = partition(response.resources, r => r.link);
        const sanitised = bad.length > 0 ? { ...response, resources: good } : response;

        if (params.country === 'us' && response.year_levels) {
          // TODO: Fix this on the API side
          // Map the year-levels
          sanitised.year_levels = sanitised.year_levels.map(convertOldUsGradeName);
          if (sanitised.resources) {
            sanitised.resources.forEach(resource => {
              resource.year_levels = resource.year_levels.map(convertOldUsGradeName);
            });
          }
        }

        if (sanitised.shortcode_resources && sanitised.shortcode_resources?.length > 0) {
          dispatch('loadShortCodeResources', {
            country: sanitised.country,
            ids: sanitised.shortcode_resources,
          });
        }

        commit('loadResource', sanitised);
        commit('setRedirect', response.link);
        commit('updateAlternatePages', response.linked);

        if (state.auth?.user) {
          dispatch('loadResourceStats', { id: response.id });

          // use state instead of sanitised so we get all the mutations that happened in mutations.ts
          await dispatch('loadUserDownloadPermissions', state.resource?.files);
        }

        if (bad.length > 0) {
          console.warn('Bad resources found', bad.map(r => r.id).join(', '));
        }
      }

      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err, 'resource'));
    }
  },
  async loadResourceStats({ commit }, params: { id: number }): Promise<void> {
    // Get the likes and bookmarks count
    try {
      const response = await this.$axios.$get('/public/v1/resource/stats', {
        params,
      });

      commit('loadResourceStats', { ...response, id: params.id });
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadUserDownloadPermissions({ commit }, files: Resource['files']): Promise<void> {
    const fileIds = files.map(file => file.id);
    const response = await this.$canUserDownloadFiles(fileIds);
    commit('loadUserDownloadPermissions', response);
  },
  async loadResourcePrevAndNext(_, params): Promise<unknown> {
    try {
      return await this.$axios.$get('/public/v1/resource/prev-and-next', {
        params,
      });
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadResources(_, params: PublicV1ResourceRequest): Promise<PublicV1ResourceResponse> {
    try {
      return await this.$axios.$get<PublicV1ResourceResponse>('/public/v1/resource', {
        params,
      });
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadRecommendedResources({ commit }, { id, numToShow, type }): Promise<void> {
    commit('loadRecommendedResources', { resources: [], numToShow });
    try {
      const response = await this.$axios.$get('/public/v1/resource/related', {
        params: {
          id,
          version: 1,
          type,
        },
      });
      commit('loadRecommendedResources', { resources: response.list, numToShow });
    } catch (err) {
      throw new Error(getErrorPageMessage(err, 'recommeded resources'));
    }
  },
  async loadShortCodeResources({ commit }, params): Promise<void> {
    try {
      const response = await this.$axios.$get('/public/v1/resource', {
        params,
      });

      commit('loadShortCodeResources', response.list);
    } catch (err) {
      console.error(err);
    }
  },
  async loadCategory({ commit }, params): Promise<Category> {
    try {
      const response = await this.$axios.$get<Category>('/public/v1/category', {
        params,
      });

      // TODO: ideally should be done on API side, Also replace names
      // Reformat US links to use /grade-level/ instead of /year-level/
      // and change names from Grade 1 to 1st Grade etc
      if (params.country === 'us') {
        if (params.category === 'category-us') {
          response.items = response.items
            ? response.items.map(item => {
                item.link = item.link.replace('year-level', 'grade-level');
                return item;
              })
            : undefined;
        } else if (params.category === 'year-level-us') {
          response.items = response.items
            ? response.items.map((item: any) => {
                item.slug = item.slug.replace('year-level', 'grade-level');
                item.link = item.link.replace('year-level', 'grade-level');
                item.name = yearLevelToTitle('us', item.slug);
                return item;
              })
            : undefined;
        } else if (gradesForLocale('us').includes(params.category)) {
          response.name = yearLevelToTitle('us', params.category);
          response.link = response.link.replace('year-level', 'grade-level');
          response.items = response.items
            ? response.items.map(item => {
                item.link = item.link.replace('year-level', 'grade-level');
                item.name = yearLevelToTitle('us', item.slug);
                return item;
              })
            : undefined;
        }
      }

      if (response.linked) {
        response.linked = response.linked.map((item: any) => {
          if (item.link && item.country === 'us') {
            item.link = item.link.replace('year-level', 'grade-level');
          }
          return item;
        });
      }

      commit('loadCategory', response);
      commit('updateAlternatePages', response.linked);
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err, 'category'));
    }
  },
  async loadFilters(_, params): Promise<PublicV1FiltersResponse> {
    try {
      const response = await this.$axios.$get<PublicV1FiltersResponse>('/public/v1/filter', {
        params,
      });
      // HACK - remap grades for us locale
      // TODO replace with correct names
      if (params.country === 'us' && response.year_levels) {
        return {
          ...response,
          year_levels: response.year_levels.map((yearLevel: FilterInfo) => ({
            ...yearLevel,
            name: yearLevelToTitle('us', yearLevel.slug),
          })),
        };
      }
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err, 'filters'));
    }
  },
  async loadComments({ commit }, params): Promise<unknown> {
    // Load a list of comments for this resource or blog
    try {
      const response = await this.$axios.$get('/public/v1/resource/comment', {
        params,
      });

      commit('loadComments', response.list);
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async addComment({ commit }, params): Promise<number | null> {
    // User action - post a new comment on a resource or blog
    try {
      const response = await this.$axios.$post('/public/v1/resource/comment', params);

      commit('addComment', response.list);
      return response.id ? response.id : null;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadRelatedResources(_, params): Promise<unknown> {
    try {
      const response = await this.$axios.$get('/public/v1/resource/related', {
        params,
      });
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  loadingSearchResults({ commit }, isLoading): void {
    // Show loading state when updating list of search results
    commit('loadingSearchResults', isLoading);
  },
  updateSearchResults({ commit }, results): void {
    commit('updateSearchResults', { results });
  },
  async loadCurriculums({ commit }, params): Promise<unknown[]> {
    try {
      const response = await this.$axios.$get('/public/v1/curriculum/filters', {
        params: params.params,
      });

      commit('loadCurriculums', response.list);
      return response.list;
    } catch (err) {
      throw new Error(getErrorPageMessage(err, 'curriculums'));
    }
  },
  async loadCurriculum({ commit }, params): Promise<unknown> {
    try {
      const response = await this.$axios.$get('/public/v1/curriculum', {
        params,
      });

      commit('loadCurriculum', response);
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err, 'curriculum'));
    }
  },
  async authoriseWidget({ commit }, params): Promise<void> {
    // Check if the user is authorised to use this widget, also return their wordlists
    try {
      const response = await this.$axios.$get('/public/v1/engage/authorise', {
        params,
      });

      commit('authoriseWidget', response);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadUserFolder({ commit }, params): Promise<unknown> {
    try {
      const response = await this.$axios.$get('/public/v1/collection', {
        params,
      });

      commit('loadUserFolder', response);

      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadResourcesFromUserFolder({ commit }, params): Promise<unknown> {
    try {
      const response = await this.$axios.$get('/public/v1/collection/resources', {
        params,
      });

      commit('loadResourcesFromUserFolder', response);

      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  loadingUserFolders({ commit }): void {
    commit('loadingUserFolders');
  },
  async loadUserFolders({ commit }): Promise<void> {
    try {
      const response = await this.$axios.$get('/public/v1/collection', {
        params: { payload: 'none', sortBy: 'name', perPage: 100 },
      });

      response.list = response.list.filter(
        (item: any) => item.can_edit_collection === 1 && item.can_edit_resources === 1
      );

      commit('loadUserFolders', response.list);
      commit('loadingUserFolders', false);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async addUserFolder({ commit }, params): Promise<unknown> {
    try {
      const response = await this.$axios.$post('/public/v1/collection', params);

      commit('addUserFolder', response);

      // Return the slug to navigate to that page
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async updateUserPreferences(_, params): Promise<unknown> {
    try {
      const response = await this.$axios.$put('/public/v1/user/prefs', params);
      await this.$auth.fetchUser();
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async updateUserFolder({ commit }, params): Promise<unknown> {
    try {
      const response = await this.$axios.$put('/public/v1/collection', params);

      commit('loadUserFolder', response);
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async deleteUserFolder({ commit }, params): Promise<void> {
    try {
      await this.$axios.delete('/public/v1/collection', { params });
      commit('deleteUserFolder', params.id);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async addResourceUserFolder({ commit }, params): Promise<void> {
    try {
      const response = await this.$axios.$post('/public/v1/collection/resource', params);

      commit('addResourceUserFolder', response);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async deleteResourceUserFolder({ commit }, params): Promise<void> {
    try {
      await this.$axios.delete('/public/v1/collection/resource', {
        params,
      });
      commit('deleteResourceUserFolder', params);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async toggleLikeBookmark({ commit }, params): Promise<void> {
    try {
      await this.$axios.$post('/public/v1/user/action', params);
      commit('toggleLikeBookmark', params);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadUserHomeFeed({ commit }, params): Promise<void> {
    const append = params.append;
    delete params.append;

    try {
      // @todo update this endpoint to /public/v1/user/homefeed when available
      const response = await this.$axios.$get('/public/v1/resource', {
        params,
      });

      response.append = append;

      commit('loadUserHomeFeed', response);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadTrendingTopics({ commit }, params): Promise<void> {
    try {
      const response = await this.$axios.$get('/public/v1/category/trending', {
        params,
      });

      commit('loadTrendingTopics', response);
    } catch (err) {
      console.warn(err);
    }
  },
  async loadUserNotifications({ commit }, params): Promise<void> {
    try {
      const response = await this.$axios.$get('/public/v1/user/notifications', {
        params,
      });

      response.append = params.append;

      commit('loadUserNotifications', response);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadUserStudioFiles({ commit }, params): Promise<void> {
    try {
      const response = await this.$axios.$get('/public/v1/collection/studio-files', {
        params,
      });

      commit('loadUserStudioFiles', response);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadSchoolFonts({ commit }): Promise<void> {
    try {
      const response = await this.$axios.$get('/public/v1/studio/fonts?type=school');
      commit('loadSchoolFonts', response);
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadSchoolFontLines({ commit }): Promise<void> {
    try {
      const response = await this.$axios.$get('/public/v1/studio/font-lines');
      const fontLines = Object.keys(response).map((key: any) => ({ id: key, ...response[key] }));
      commit('loadSchoolFontLines', fontLines);
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loadUserWordLists({ commit }): Promise<void> {
    try {
      const response = await this.$axios.$get('/public/v1/wordlist');
      commit('loadUserWordLists', response.list);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async addUserWordList({ commit }, params): Promise<void> {
    try {
      const response = await this.$axios.$post('/public/v1/wordlist', params);
      commit('addUserWordList', response);
      return response;
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async updateUserWordList({ commit }, params): Promise<void> {
    try {
      const response = await this.$axios.$put('/public/v1/wordlist', params);
      commit('updateUserWordList', response);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async deleteUserWordList({ commit }, params): Promise<void> {
    try {
      await this.$axios.delete('/public/v1/wordlist', { params });
      commit('deleteUserWordList', params.id);
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  async loginWP(): Promise<void> {
    // Send the auth token to the WP subdomain to log the use in over there too
    try {
      // Known issue in the library. Putting this just to avoid ts errors
      const token = (this.$auth.strategy as LocalScheme).token.get();

      await this.$axios.post(
        this.$config.wpURL + '/wp-content/themes/teachstarter_2015/auth/',
        { token: isString(token) ? token.replace('Bearer ', '') : '' },
        { withCredentials: true }
      );
    } catch (err) {
      // don't log in development, just adds noise as the localhost won't work.
      if (process.env.NODE_ENV === 'development') {
        return;
      }
      if (axios.isAxiosError(err)) {
        this.$sentry.captureException(new Error(`WP login failed: ${err.message}`));
      }
      console.error(err);
    }
  },
  async logout(): Promise<void> {
    try {
      await this.$auth.logout();

      this.$removeEmailFromProfitwellScript();

      const cookies = this.$cookies.getAll();
      // Remove all the WordPress cookies
      for (const [key] of Object.entries(cookies)) {
        if (key.startsWith('wordpress_')) {
          this.$cookies.remove(key);
        }
      }

      /* clear user ID after logout: https://developers.amplitude.com/docs/javascript#log-out-and-anonymous-users */
      this.$logoutAmplitude();
      this.$resetAlgoliaUserToken();

      await this.$amplitudeExperimentRefetchVariants();

      // Also logout of WordPress
      await this.$axios.post(
        this.$config.wpURL + '/wp-content/themes/teachstarter_2015/auth/',
        { token: '' },
        { withCredentials: true }
      );
    } catch (err) {
      throw new Error(getErrorPageMessage(err));
    }
  },
  updateExperiments({ commit }, experiments): void {
    commit('updateExperiments', experiments);
  },
  resetExperiments({ commit }): void {
    commit('updateExperiments', {});
  },
  async freeSignUp({ dispatch }, params): Promise<void> {
    const { data } = await this.$axios.post('/public/v1/user/register', {
      password: params.password,
      first_name: params.firstName,
      last_name: params.lastName,
      email: params.email,
      recaptcha: params.recaptcha,
      country: params.country,
    });
    this.$auth.setUser(data);
    await this.$auth.setUserToken(data.token, data.refresh);
    if (data.location) {
      dispatch('updateLocation', data.location);
    }
    try {
      await dispatch('loginWP');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error('Could not log in to v1');
      }
    }
    return data;
  },
  async freeSignUpComplete(_, params): Promise<void> {
    await this.$axios.post('/public/v1/user/register-complete', {
      grades: params.grades,
      country: params.country,
      occupation: params.occupation,
      newsletter: params.newsletter,
    });
    // Refetch user info
    await this.$auth.fetchUser();
  },
  async socialSignUp({ dispatch }, { code, type, key, url, country }): Promise<void> {
    const { data } = await this.$axios.post('/public/v1/user/register-social', {
      code,
      key,
      type,
      url,
      country,
    });
    this.$auth.setUser(data);
    await this.$auth.setUserToken(data.token, data.refresh);
    try {
      await dispatch('loginWP');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error('Could not log in to v1');
      }
    }
    return data;
  },
};

export default Actions;
