<template>
  <div>
    <two-row-header />
    <main id="main" role="main" class="row page-member" :aria-hidden="ariaHiddenMain">
      <member-side-nav class="columns small-12 medium-4" />
      <nuxt />
    </main>
    <main-footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TwoRowHeader from '~/components/header/TwoRowHeader.vue';
import MainFooter from '~/components/MainFooter.vue';
import MemberSideNav from '~/components/member/MemberSideNav.vue';
import { ExperimentTypes } from '~/types/experiments';

export default {
  name: 'MemberLayout',
  components: {
    MainFooter,
    MemberSideNav,
    TwoRowHeader,
  },
  middleware: 'auth',
  data() {
    return {
      ExperimentTypes,
    };
  },
  nuxtI18n: false,
  computed: {
    ...mapGetters(['ariaHiddenMain']),
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/style.scss';
</style>
