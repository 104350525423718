import { convertToApaTitleCase, removeLocale, replaceDashesWithSpace } from './formatStrings';
import { gradeLabelForLocale, grades, isGradeForLocale } from '~/data/grades';
import { isLocale } from '~/types/locale';
import { Category } from '~/types/category';

// TODO this list is not exhaustive, just the ones used for filtered landing pages
export const BaseResourceTypes = {
  worksheets: 'Worksheets',
  posters: 'Posters',
  games: 'Games',
  'word-walls': 'Word Walls',
  templates: 'Templates',
  'classroom-organisation': 'Labels, Signs & Decorations',
  'teaching-presentations': 'Teaching Presentations',
  'task-cards': 'Task Cards',
  flashcards: 'Flashcards',
  'match-up-activities': 'Match-Up Activities',
  'sorting-activities': 'Sorting Activities',
  magazine: 'Magazine',
  'craft-activities': 'Craft Activities',
  'board-games': 'Board Games',
  'progress-trackers': 'Progress Trackers',
  'classroom-displays': 'Classroom Displays',
  'card-games': 'Card Games',
  'active-games': 'Active Games',
  checklists: 'Checklists',
  bingo: 'Bingo',
  projects: 'Projects',
  'desk-plates': 'Desk Plates',
  'hands-on-materials': 'Hands-On Materials',
  'page-borders': 'Page Borders',
  dominoes: 'Dominoes',
  custom: 'Custom Studio Resources',
  puzzles: 'Puzzles',
  bookmarks: 'Bookmarks',
  'fact-sheets': 'Fact Sheets',
  'letter-sets': 'Banner Display Letter Sets',
  'certificates-and-awards': 'Certificates and Awards',
  charts: 'Charts',
} as const;

// These types are used on Filtered landing pages
export const FilteredResourceTypeSlugs = [
  'worksheets',
  'posters',
  'games',
  'word-walls',
  'templates',
  'classroom-organisation',
  'teaching-presentations',
  'flashcards',
  'projects',
] as const;

export type FilteredResourceTypeSlug = (typeof FilteredResourceTypeSlugs)[number];
export type FilteredResourceTypeName = (typeof BaseResourceTypes)[FilteredResourceTypeSlug];

export const isFilteredResourceTypeSlug = (slug: string): slug is FilteredResourceTypeSlug => {
  return FilteredResourceTypeSlugs.includes(slug as FilteredResourceTypeSlug);
};

export const FilteredResourceTypes: Record<FilteredResourceTypeSlug, FilteredResourceTypeName> =
  Object.fromEntries(
    FilteredResourceTypeSlugs.map(slug => [
      slug as FilteredResourceTypeSlug,
      BaseResourceTypes[slug],
    ])
  ) as Record<FilteredResourceTypeSlug, FilteredResourceTypeName>;

export const ResourceTypes = {
  ...BaseResourceTypes,
  ...Object.fromEntries(
    Object.entries(BaseResourceTypes).map(([key, value]) => [key + '-au', value])
  ),

  ...Object.fromEntries(
    Object.entries(BaseResourceTypes).map(([key, value]) => [key + '-us', value])
  ),
  ...Object.fromEntries(
    Object.entries(BaseResourceTypes).map(([key, value]) => [key + '-gb', value])
  ),
};

export type ResourceTypeSlug = keyof typeof ResourceTypes;
const isResourceTypeSlug = (slug: string): slug is ResourceTypeSlug => {
  return slug in ResourceTypes;
};
export type ResourceTypeName = (typeof ResourceTypes)[ResourceTypeSlug];

export const yearLevelToTitle = (locale: string | null, yearLevelSlug: string): string => {
  if (isLocale(locale) && isGradeForLocale(locale, yearLevelSlug)) {
    const result = gradeLabelForLocale(locale, yearLevelSlug);
    if (result) {
      return result;
    }
  }
  // Don't have locale, look at all the keys
  const result = Object.values(grades)
    .flat()
    .find(g => g.value === yearLevelSlug)?.label;
  if (result) {
    return result;
  }

  // Fallback if no match found
  return convertToApaTitleCase(replaceDashesWithSpace(yearLevelSlug));
};

export const resourceTypeToTitle = (resourceTypeSlug: string): string => {
  if (isResourceTypeSlug(resourceTypeSlug)) {
    return ResourceTypes[resourceTypeSlug];
  }
  // Fallback if not supported
  return convertToApaTitleCase(replaceDashesWithSpace(removeLocale(resourceTypeSlug)));
};

export const categoryTitle = (
  category: string,
  locale: string | null,
  resourceType?: string,
  yearLevel?: string,
  long?: boolean
): string => {
  // NB: category is not a slug, it's the name of the category and expected to be in title case

  // Note some resource types are already included in category titles (e.g. Kids Games) we treat these specially
  if (resourceType && category.endsWith(resourceTypeToTitle(resourceType))) {
    // Leave the resource type off the title
    return category + (long ? ' for Teachers' : '');
  }
  const title =
    category +
    (resourceType
      ? ' ' +
        (category !== resourceType ? resourceTypeToTitle(resourceType) : '') +
        (long ? ' for Teachers' : '')
      : yearLevel
        ? (long ? ' Teaching Resources' : '') + ' for ' + yearLevelToTitle(locale, yearLevel)
        : long
          ? ' Teaching Resources'
          : '');
  return title;
};

export const shortCategoryTitle = (
  category: string,
  locale: string | null,
  resourceType?: string,
  yearLevel?: string
): string => categoryTitle(category, locale, resourceType, yearLevel, false);

export const longCategoryTitle = (
  category: string,
  locale: string | null,
  resourceType?: string,
  yearLevel?: string
): string => categoryTitle(category, locale, resourceType, yearLevel, true);

export type BreadCrumb = Pick<Category['breadcrumbs'][number], 'name' | 'link'>;

export const breadcrumbs = (
  breadcrumbs: BreadCrumb[] | undefined,
  parent?: BreadCrumb
): BreadCrumb[] | undefined => {
  if (!breadcrumbs) {
    return undefined;
  }
  const result = [...breadcrumbs];

  // HACK: Some categories have the wrong root page so replace it with parent if present
  if (parent) {
    result[0] = parent;
  }
  return result;
};
