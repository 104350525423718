<template>
  <div>
    <div
      class="bg-blue tw-m-6 tw-mx-auto tw-my-16 tw-max-w-2xl tw-space-y-6 !tw-overflow-y-auto tw-pb-2"
    >
      <ts-tes-logo class="tw-mx-auto tw-w-32" />
    </div>
    <nuxt />
  </div>
</template>

<script>
import { getLocaleString } from '~/utils/localeString';
import TsTesLogo from '~/components/svg/TsTesLogo.vue';

export default {
  name: 'MinimalLayout',
  components: {
    TsTesLogo,
  },
  head() {
    return {
      htmlAttrs: {
        lang: getLocaleString(this.$i18n?.locale),
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/_reset.scss';
body {
  max-width: 70rem;
  margin: 1.5rem;
}
</style>
