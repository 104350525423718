/* Override the router scroll behaviour
 * https://nuxtjs.org/docs/2.x/configuration-glossary/configuration-router#scrollbehavior
 */
export default function (to, from, savedPosition) {
  // don't scroll to top if the route has not changed
  if (to.path === from.path) {
    return null;
  }

  // scroll to URL anchor
  if (to.hash) {
    return { selector: to.hash };
  }

  // return to top of page after route change
  return savedPosition || { x: 0, y: 0 };
}
