import { Middleware, NuxtAppOptions } from '@nuxt/types';

// Subset of /data/countries - don't need to import full file
const defaultCountries = [
  { id: 5526659, name: 'Australia', code: 'au', secondary: 'au', currency: 'AUD' },
  { id: 5526884, name: 'United Kingdom', code: 'gb', secondary: 'gb', currency: 'GBP' },
  { id: 5526885, name: 'United States', code: 'us', secondary: 'us', currency: 'USD' },
];

// Set location cookie when locale changes
function checkLocale(app: NuxtAppOptions, newLocale: string): void {
  if (newLocale !== 'default' && newLocale !== app.store?.getters.location.secondary) {
    const country = defaultCountries.find(c => c.code === newLocale);
    app.store?.dispatch('updateLocation', country);
  }
}

const RedirectMiddleware: Middleware = context => {
  // Reset alternate pages on route change for location selector
  context.app.store?.commit('updateAlternatePages');
  context.app.store?.commit('setIsErrorPage', false);

  // Clear comments on route change because they are being cached. Need a better way to cache-bust comments
  context.app.store?.commit('loadComments', []);
  context.app.store?.dispatch('toggleMobileNav', false);

  if (process.server) {
    // Server-side
    checkLocale(context.app, context.app.i18n.locale);
  } else {
    // Client-side
    context.app.i18n.onBeforeLanguageSwitch = (_: string, newLocale: string): void => {
      checkLocale(context.app, newLocale);
    };
  }

  // Always add trailing slash if missing and not a file with a dot in it (eg. .pdf)
  if (!context.route.path.endsWith('/') && !context.route.path.includes('.')) {
    if (context.route.path === context.route.fullPath) {
      context.redirect(301, context.route.fullPath + '/');
    } else {
      // Split route into path and query
      const parts = context.route.fullPath.split('?');
      context.redirect(301, parts[0] + '/?' + parts[1]);
    }
  } else if (context.route.fullPath.match(/\/\/+$/)) {
    // Remove multiple trailing slashes if present
    context.redirect(301, context.route.fullPath.replace(/\/\/+$/, '/'));
  }
};

export default RedirectMiddleware;
