import dayjs from 'dayjs';
import * as amplitudeBrowser from '@amplitude/marketing-analytics-browser';
import * as amplitudeNode from '@amplitude/analytics-node';
import { v4 } from 'uuid';
import { Plugin } from '@nuxt/types';
import { NodeConfig, EnrichmentPlugin, PluginType } from '@amplitude/analytics-types';
import { isFileType, Resource } from '~/types/resource';
import { shortCategoryTitle } from '~/utils/category';
import { convertToTitleCase, removeLocale } from '~/utils/formatStrings';
import { AmplitudeInstance, isClientAmplitude } from '~/utils/amplitude';
import { Category } from '~/types/category';
import { LoginType } from '~/types/api';

let amplitude: AmplitudeInstance | null = null;

interface PageParams {
  Page_Type_Name: string;
  Page_Id?: number;
  Page_Name: string;
  Page_Url: string;
  Page_Country: string;
  Page_Author_Id?: string;
  Page_Author_Name?: string;
  Page_Publisher_Id?: string;
  Page_Publisher_Name?: string;
  Page_Designer_Name?: string;
}

interface ResourceParams {
  Resource_Type_Name?: string;
  Resource_Sub_Type_Name?: string[];
  Resource_Years?: string[];
  Resource_Availability?: string;
  Resource_Country?: string;
  Resource_Language?: string;
}

interface BlogParams {
  Blog_Sub_Type_Name?: string;
}

interface UserParams {
  User_Status?: string;
  User_Type?: string;
  User_Subscription_Current_Status?: string;
  User_Subscription_Current_Plan_Code?: string;
  User_Subscription_Current_Plan_Name?: string;
}

interface UserIdentifyParams {
  User_Login_Status: 'Logged In' | undefined;
  User_First_Seen_Date: string;
  User_Last_Seen_Date: string;
  User_Life_Time_Months_Subscribed: number;
  User_Life_Time_Download_Count: string;
  User_Occupation_Type: string;
  User_Occupation_School_Name: string;
  User_Occupation_Grades: string;
}

interface DownloadParams {
  Instant_Download: boolean;
  File_Customised: boolean;
  File_Format: string;
  File_Id: string;
}

interface BasicUserDetails {
  uuid?: string;
  deviceId?: string;
}

type EventParamsPage = PageParams & ResourceParams & BlogParams & UserParams;
type DownloadEvent = EventParamsPage & DownloadParams;

interface AmplitudePlugin {
  $amplitude: AmplitudeInstance;
  $constructEventParamsPage: (item: Resource) => EventParamsPage;
  $constructHomeFeedDownloadEvent: (item: Resource) => DownloadEvent;
  $constructEventParamsCategory: (
    item: Category,
    resourceType?: string,
    yearLevel?: string
  ) => PageParams;
  $amplitudeToggleTracking: (enabled: boolean) => void;
  $amplitudeIdentify: () => void;
  $amplitudeBasicUserDetails: () => BasicUserDetails | undefined;
  $amplitudeUpdateIdentify: () => void;
  $amplitudeEventPageViewed: (
    name: string,
    link: string,
    type?: string,
    event?: string,
    overrideParams?: Record<string, any>
  ) => void;
  $logoutAmplitude: () => void;
  $loginAmplitude: (type: LoginType, path: string) => void;
}

declare module '@nuxt/types' {
  interface Context extends AmplitudePlugin {}
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> extends AmplitudePlugin {}
}

/**
 * Ampltide node doesn't automatically add user_id and device_id to events
 * so adding a plugin to do this
 */
class AddUserAndDeviceIdAmplitudePlugin implements EnrichmentPlugin {
  public name = 'add-user-and-device-id';
  public type = PluginType.ENRICHMENT as const;

  private userId?: string;
  private deviceId?: string;
  private config?: NodeConfig;

  constructor(deviceId: string, userId?: string) {
    this.userId = userId;
    this.deviceId = deviceId;
  }

  // eslint-disable-next-line require-await
  async setup(config: amplitudeBrowser.Types.Config): Promise<void> {
    config.logLevel = 4;
    this.config = config;
  }

  // eslint-disable-next-line require-await
  async execute(event: amplitudeBrowser.Types.Event): Promise<amplitudeBrowser.Types.Event> {
    event.user_id = this.userId;
    event.device_id = this.deviceId;
    return event;
  }
}

// Construct properties for Amplitude events
const amplitudePlugin: Plugin = (
  { app, store, $config: { baseURL, amplitudeKey, amplitudeContentTypes } },
  inject
) => {
  const getDeviceId = (): string => {
    const id = app.$cookies.get('deviceId') || store.getters.deviceId;
    if (!id) {
      const newId = v4();
      store.dispatch('updateDeviceId', newId);
      app.$cookies.set('deviceId', newId);
      return newId;
    }

    return id;
  };

  const identify = (userPropertyOverrides?: Object): void => {
    if (!amplitude) {
      return;
    }

    const user = store.getters.loggedInUser;
    const userId = user?.uuid;
    if (isClientAmplitude(amplitude) && userId) {
      amplitude.setUserId(userId);
    }

    const userProperties = user
      ? {
          User_Status: user.subscription && user.subscription?.code !== 'free' ? 'paid' : 'free',
          User_Intercom_Id: user.intercom.id,
          User_Subscription_Current_Plan_Name: user.subscription.name,
          User_Occupation_Type: user.occupation,
          User_Occupation_School_Name: user.school ? user.school.name : '',
          User_Occupation_Grades: user.grades,
          User_Life_Time_Download_Count: user.download_count,
          User_Location_Country: user.location.name,
          User_Location_State: user.location.state,
          ...userPropertyOverrides,
        }
      : userPropertyOverrides || {};

    const identifyObj = process.browser
      ? new amplitudeBrowser.Identify()
      : new amplitudeNode.Identify();
    Object.entries(userProperties).forEach(([key, value]) => {
      identifyObj.set(key, value);
    });

    amplitude.identify(identifyObj, {
      user_id: userId,
      device_id: getDeviceId(),
    });
  };

  // remove old amplitude cookies
  const cookies = app.$cookies.getAll();
  for (const [key] of Object.entries(cookies)) {
    if (key && key.toLowerCase().startsWith('amp_')) {
      app.$cookies.remove(key);
    }
  }

  // Initialise Amplitude
  if (process.browser) {
    const deviceId = getDeviceId();
    amplitudeBrowser.init(amplitudeKey, store.getters.loggedInUser?.uuid, {
      attribution: {
        trackNewCampaigns: true,
      },
      disableCookies: true,
      deviceId,
    });
    amplitude = amplitudeBrowser;

    inject('amplitude', amplitude);
  } else {
    const deviceId = getDeviceId();

    const user = app.$auth.user;
    const plugin = new AddUserAndDeviceIdAmplitudePlugin(deviceId, user?.uuid);
    amplitudeNode.init(amplitudeKey);
    amplitudeNode.add(plugin);
    amplitude = amplitudeNode;

    inject('amplitude', amplitudeNode);
  }
  identify();

  // For all resource pages
  inject('constructEventParamsPage', (item: Resource): EventParamsPage => {
    const params: EventParamsPage = {
      Page_Type_Name: convertToTitleCase(item.type.replace('-', ' ')),
      Page_Id: item.old_id || item.id,
      Page_Name: item.name,
      Page_Url: baseURL + item.link,
      Page_Country: item.country,
    };

    if (item.author) {
      params.Page_Author_Id =
        item.publisher?.name === 'Teach Starter Publishing' ? item.author.uuid : '';
      params.Page_Author_Name =
        item.publisher?.name === 'Teach Starter Publishing' ? item.author.display_name : '';
    }

    if (item.publisher) {
      params.Page_Publisher_Name = item.publisher?.name;
    }

    if (item.designer) {
      params.Page_Designer_Name = item.designer?.display_name;
    }
    // Blogs, Podcasts and Webinars are considered 'Content' types and don't send extra Resource properties with their events
    if (!amplitudeContentTypes.includes(item.type)) {
      params.Resource_Type_Name = item.name;
      params.Resource_Sub_Type_Name = item.tags ? item.tags.map(tag => tag.name) : [];
      params.Resource_Years = item.year_levels;
      params.Resource_Availability = item.is_free ? 'free' : 'premium';
      params.Resource_Country = item.country;
      params.Resource_Language = 'en-' + item.country.toUpperCase();
    }

    if (item.type === 'blog' && item.focus_keywords) {
      params.Blog_Sub_Type_Name = item.focus_keywords;
    }
    //to send additional user details for school plan members. User object subscription data does not have required recurly field details hence 'if' checks
    const user = store.getters.loggedInUser;
    if (user) {
      if (
        user.role === 'school_plan_member' ||
        user.role === 'school_plan_admin_only' ||
        user.role === 'school_plan'
      ) {
        let roleName = '';
        let planCode = '';
        if (user.role === 'school_plan') {
          roleName = 'Admin';
        } else if (user.role === 'school_plan_member') {
          planCode = 'school_plan_member';
          roleName = 'Member';
        } else if (user.role === 'school_plan_admin_only') {
          planCode = 'school_plan_admin_only';
          roleName = 'Admin Only';
        }
        params.User_Status = 'true';
        params.User_Type = 'Subscriber';
        params.User_Subscription_Current_Status = 'active';
        params.User_Subscription_Current_Plan_Code = planCode || user.subscription?.code; // Note: this is not the recurly plan code
        params.User_Subscription_Current_Plan_Name = 'School Plan (' + roleName + ')';
      }
    }

    return params;
  });

  inject('constructHomeFeedDownloadEvent', (item: Resource): DownloadEvent => {
    const params: DownloadEvent = {
      Page_Type_Name: convertToTitleCase(item.type.replace('-', ' ')),
      Page_Id: item.old_id || item.id,
      Page_Name: item.name,
      Page_Url: baseURL + item.link,
      Page_Country: item.country,
      Instant_Download: true,
      File_Customised: false,
      File_Format: isFileType(item.file_formats[0])
        ? item.file_formats[0]
        : item.file_formats[0]?.format,
      File_Id: item.file_id?.toString() || '',
    };

    if (item.author) {
      params.Page_Author_Id =
        item.publisher?.name === 'Teach Starter Publishing' ? item.author.uuid : '';
      params.Page_Author_Name =
        item.publisher?.name === 'Teach Starter Publishing' ? item.author.display_name : '';
    }

    if (item.publisher) {
      params.Page_Publisher_Name = item.publisher?.name;
    }

    if (item.designer) {
      params.Page_Designer_Name = item.designer?.display_name;
    }

    if (!amplitudeContentTypes.includes(item.type)) {
      params.Resource_Type_Name = item.name;
      params.Resource_Years = item.year_levels;
      params.Resource_Availability = item.is_free ? 'free' : 'premium';
      params.Resource_Country = item.country;
      params.Resource_Language = 'en-' + item.country.toUpperCase();
    }

    return params;
  });

  // For category and curriculum pages
  inject(
    'constructEventParamsCategory',
    (item: Category, resourceType?: string, yearLevel?: string): PageParams => {
      return {
        Page_Id: item.old_id || item.id,
        Page_Name: shortCategoryTitle(item.name, null, resourceType, yearLevel),
        Page_Url:
          baseURL +
          item.link +
          (resourceType
            ? `${removeLocale(resourceType)}/`
            : yearLevel
              ? `${removeLocale(yearLevel)}/`
              : ''),
        Page_Type_Name:
          item.breadcrumbs && item.breadcrumbs[0] ? item.breadcrumbs[0].name : item.parent.name,
        Page_Country: app.i18n.locale,
      };
    }
  );

  // Called on most pages that aren't resources
  inject(
    'amplitudeEventPageViewed',
    (
      name: string,
      link: string,
      type = 'Page',
      event = 'Navigation',
      overrideParams?: Record<string, any>
    ): void => {
      if (!amplitude) {
        return;
      }
      const eventParams: PageParams = {
        Page_Type_Name: type,
        Page_Name: name,
        Page_Url: baseURL + link,
        Page_Country: app.i18n.locale,
      };

      amplitude.track(event + ' | Page Viewed', { ...eventParams, ...overrideParams });
    }
  );

  // Opt out of tracking - not currently used
  // https://developers.amplitude.com/docs/javascript#opt-out-of-tracking
  inject('amplitudeToggleTracking', (enabled = false): void => {
    if (!amplitude) {
      return;
    }
    amplitude.setOptOut(!enabled);
  });

  // Called after user logs in
  inject('amplitudeIdentify', (): ReturnType<AmplitudePlugin['$amplitudeIdentify']> => identify());

  // Called on every resource to update the current user's details
  inject('amplitudeUpdateIdentify', (): void => {
    const now = new Date();
    const user = store.getters.loggedInUser;
    identify(amplitudeUpdateIdentifyProperties(now, user));
  });

  inject('logoutAmplitude', (): void => {
    if (!amplitude) {
      return;
    }

    if (isClientAmplitude(amplitude)) {
      amplitude.reset();
      app.$cookies.set('deviceId', amplitude.getDeviceId() || null);
    }
  });

  inject('loginAmplitude', (type: LoginType, path = '/'): void => {
    if (!amplitude) {
      return;
    }
    // Trigger amplitude event
    amplitude.track('Account | User Logged In', {
      Login_Strategy: type,
      Page_Url: `${baseURL}${path}`,
    });

    const userProperties = {
      User_Login_Status: 'Logged In',
      User_Login_Type: type,
    };
    identify(userProperties);
  });
};

// Exported for testing
export const amplitudeUpdateIdentifyProperties = (now: Date, user: any): UserIdentifyParams => {
  // NB: loggedInUser should be non-null here, but I can see a slim chance of a store update from
  // when the loggedInUser was fetched on the caller side and this being called, hence the check
  const quotaLeft = user?.subscription?.quota_left;
  const isFree = user?.subscription && user?.subscription?.code === 'free';
  const userDownloadQuotaLeft =
    isFree && Number.isInteger(quotaLeft) ? { User_Download_Quota_Left: quotaLeft } : {};

  return {
    User_Login_Status: 'Logged In',
    User_First_Seen_Date: dayjs(user?.created).format('YYYY-MM-DDTh:mmZ'),
    User_Last_Seen_Date: now.toISOString(),
    User_Life_Time_Months_Subscribed: dayjs(now).diff(dayjs(user?.created), 'month'),
    User_Life_Time_Download_Count: user?.download_count ?? 0,
    User_Occupation_Type: user?.occupation,
    User_Occupation_School_Name: user?.school ? user?.school.name : '',
    User_Occupation_Grades: user?.grades,
    ...userDownloadQuotaLeft,
  };
};

export default amplitudePlugin;
