import { Plugin } from '@nuxt/types';
import Subscription from '~/api/subscription';

declare module '@nuxt/types' {
  interface Context {
    $subscriptionApi: ReturnType<typeof Subscription>;
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $subscriptionApi: ReturnType<typeof Subscription>;
  }
}

const apiPlugin: Plugin = ({ $axios }, inject) => {
  inject('subscriptionApi', Subscription($axios));
};

export default apiPlugin;
