import https from 'https';
import { Plugin } from '@nuxt/types';
import axios, { AxiosError } from 'axios';
import { formatAxiosError } from '../utils/formatAxiosError';

const isRequestHeaderSizeError = (error: AxiosError): boolean =>
  error?.response?.status === 400 &&
  typeof error.response?.data === 'string' &&
  error.response?.data.includes('Size of a request header field exceeds server limit');

const axiosPlugin: Plugin = ({ $axios, $sentry, $auth, store, app }): void => {
  // https://github.com/nuxt/nuxt/issues/6559
  if (process.env.NODE_ENV === 'development') {
    $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });
  }

  $axios.interceptors.response.use(
    response => response,
    error => {
      if (axios.isAxiosError(error)) {
        if ([401, 404, 400].includes(error?.response?.status || -1)) {
          if (isRequestHeaderSizeError(error)) {
            console.warn(
              'Size of a request header field exceeds server limit',
              JSON.stringify(error.config.headers)
            );
          }
        } else {
          console.error(formatAxiosError(error));
          if (process.env.NODE_ENV === 'production' && $sentry) {
            $sentry.captureException(new Error(formatAxiosError(error)));
          }
        }
      } else {
        console.error(error);
        if (process.env.NODE_ENV === 'production' && $sentry) {
          $sentry.captureException(error);
        }
      }
      // 'ExpiredAuthSessionError' check handles expired refresh token and 401 handles unauthorized responses from the API.
      // It also needs to check for the error message as there are cases where a 401 is returned and we don't want to log the
      // user out. For example, `can-download`
      const needsToReLogin =
        error?.response?.status === 401 &&
        error?.response?.data?.error === 'You must be logged in.';
      if (error.name === 'ExpiredAuthSessionError' || needsToReLogin) {
        console.warn('Session expired, redirecting to login page');
        if ($auth.strategy.reset) {
          $auth.strategy.reset();
        }
        const path = app.localePath('/login');
        app.router?.push({ path, query: { error: 'session_expired' } });
        store.dispatch('closeAllModals');
      }

      return Promise.reject(error);
    }
  );
};

export default axiosPlugin;
