import aa from 'search-insights';
import { Plugin } from '@nuxt/types';
import { v4 } from 'uuid';

interface AlgoliaEventsPlugin {
  $sendAlgoliaConversion: (objectId: string, queryId?: string, eventName?: string) => void;
  $setAlgoliaUserToken: (userToken: string) => void;
  $resetAlgoliaUserToken: () => void;
}

declare module '@nuxt/types' {
  interface Context extends AlgoliaEventsPlugin {}
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> extends AlgoliaEventsPlugin {}
}

const algoliaEventsPlugin: Plugin = ({ $config, store, $auth }, inject) => {
  const deviceId = store.getters.deviceId;
  aa('init', {
    appId: $config.algolia.applicationId,
    apiKey: $config.algolia.apiKey,
  });

  aa(
    'setUserToken',
    $auth.user && $auth.user.id ? String($auth.user.id) : `anonymous-${deviceId || v4()}`
  );

  const sendConversionEvent = (
    objectId: string,
    queryId?: string,
    eventName = 'Resource Downloaded'
  ): void => {
    if (queryId) {
      aa('convertedObjectIDsAfterSearch', {
        eventName,
        queryID: queryId,
        index: $config.algolia.resourceIndex,
        objectIDs: [objectId],
        userToken: String($auth.user.id),
      });
    } else {
      aa('convertedObjectIDs', {
        eventName,
        index: $config.algolia.resourceIndex,
        objectIDs: [objectId],
        userToken: String($auth.user.id),
      });
    }
  };

  inject('sendAlgoliaConversion', sendConversionEvent);
  inject('setAlgoliaUserToken', (userToken: string) => aa('setUserToken', userToken));
  inject('resetAlgoliaUserToken', () => aa('setUserToken', `anonymous-${deviceId || v4()}`));
};

export default algoliaEventsPlugin;
