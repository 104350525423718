export function convertToTitleCase(text?: string): string {
  return (
    text?.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()) ??
    ''
  );
}

/** Convert to title case following APA rules where minor words that are in the middle of the title are lowercase */
export function convertToApaTitleCase(text?: string): string {
  if (!text) {
    return '';
  }
  const minorWords = [
    'a',
    'an',
    'and',
    'as',
    'at',
    'but',
    'by',
    'for',
    'if',
    'in',
    'nor',
    'of',
    'on',
    'or',
    'so',
    'the',
    'to',
    'up',
    'yet',
    'vs',
    'v',
    'via',
  ];
  const words = text.split(' ');
  const convertedWords = words.map((word, index) => {
    if (index === 0 || index === words.length - 1) {
      return word.charAt(0).toUpperCase() + word.substring(1);
    }
    return minorWords.includes(word.toLowerCase())
      ? word.toLowerCase()
      : word.charAt(0).toUpperCase() + word.substring(1);
  });
  return convertedWords.join(' ');
}

export function replaceDashesWithSpace(text?: string): string {
  return text?.replace(/-/g, ' ') ?? '';
}

export function removeLocale(text: string): string {
  return text?.replace(/-gb|-us/gi, '') ?? '';
}

export function convertToSentenceCase(str?: string): string {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function pluralise(str: string, count: number): string {
  return count !== 1 ? str + 's' : str;
}

export function numberWithCommas(number: number): string {
  if (!number) {
    return '';
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function timeInMins(duration: number, rounded = false): string {
  // Convert seconds to minutes and seconds
  if (!duration) {
    return '';
  }

  const minutes = Math.floor(duration / 60);
  const seconds = duration - minutes * 60;

  // Optional, round to the closest minute
  if (rounded) {
    return Math.round(duration / 60) + ' min';
  }

  return seconds === 0 ? minutes + ' min' : minutes + ' min, ' + seconds + ' sec';
}

export function truncateString(text: string, characterLimit: number): string {
  if (text.length <= characterLimit) {
    return text;
  }

  return (
    text
      .substring(0, characterLimit)
      .substring(0, text.substring(0, characterLimit).lastIndexOf(' ')) + '...'
  );
}
