import { useContext, useRoute, useStore } from '@nuxtjs/composition-api';
import { State } from '~/store';

interface UseTwoRowHeader {
  openNavEvent: (navigationName: string) => void;
  linkClickedEvent: (navigationName: string, linkName: string | null) => void;
  navigationUserClickedEvent: (itemType: string, itemName: string, itemPath?: string) => void;
  eventSkipLinkClicked: () => void;
}

export const useTwoRowHeader = (): UseTwoRowHeader => {
  const { $amplitude, $config, i18n } = useContext();
  const route = useRoute();
  const store = useStore<State>();
  const openNavEvent = (navigationName: string): void => {
    const pageName = process.client && document.title ? document.title.split('|')[0] : undefined;
    $amplitude.track('Navigation | Dropdown Opened', {
      Page_Url: `${$config.baseURL}${route.value.path}`,
      Page_Country: i18n.locale,
      Page_Name: pageName,
      Navigation_Name: navigationName,
    });
  };
  const linkClickedEvent = (navigationName: string, linkName: string | null): void => {
    const pageName = process.client && document.title ? document.title.split('|')[0] : undefined;
    $amplitude.track('Navigation | Dropdown Item Clicked', {
      Page_Url: `${$config.baseURL}${route.value.path}`,
      Page_Country: i18n.locale,
      Page_Name: pageName,
      Navigation_Name: navigationName,
      Link_Name: linkName,
    });
    store.dispatch('toggleMobileNav', false);
  };
  // new navigation event for new-navigation experiment
  const navigationUserClickedEvent = (
    itemType: string,
    itemName: string,
    itemPath?: string
  ): void => {
    $amplitude.track('Navigation | User Clicked', {
      Item_Type: itemType,
      Item_Name: itemName,
      Item_Url: itemPath?.length ? `${$config.baseURL}${itemPath}` : undefined,
    });
  };

  const eventSkipLinkClicked = (): void => {
    $amplitude.track('Navigation | Skip navigation link clicked', {
      Page_Url: `${$config.baseURL}${route.value.path}`,
    });
  };

  return {
    openNavEvent,
    linkClickedEvent,
    navigationUserClickedEvent,
    eventSkipLinkClicked,
  };
};
