import { AxiosError } from 'axios';

export const formatAxiosError = (error: AxiosError): string => {
  const { response, config } = error;
  if (response) {
    const { status, data } = response;
    return `[${status}] ${config.method} ${config.url} with params ${JSON.stringify(
      config.params
    )} - ${JSON.stringify(data)}`;
  }
  return error.message;
};
