/**
 * Experiement key definitions
 *
 * The current experiments for the different environment can be found at:
 * PRODUCTION: https://experiment.amplitude.com/teach-starter/experiments/337179
 * DEVELOPMENT:  https://experiment.amplitude.com/teach-starter/experiments/332461
 *
 * This list should exist as an immutable constant for the keys used in amplitude experiment
 * to avoid errors with typos and to give a more clear view of what experiments we have.
 *
 * After creating an experiment in development, the key should be added here.
 */

export const ExperimentTypes = {
  emailVerification: 'email-verification',
  CacheSearch: 'cache-search',
  Recaptcha: 'recaptcha',
  AlgoliaSearchBox: 'algolia-autocomplete',
  SettingsInPrexit: 'settings',
  NewSubscribe: 'new-subscribe',
  SaasquatchIntegration: 'saasquatch-integration',
  BlogRecommendations: 'blog-recommendations',
  PasswordReset: 'password-reset',
  EventLandingPages: 'event-landing-pages',
  FreeDownloadPremium: 'free-download-premium',
  SignupFlowHideHeaderFooter: 'signup-flow-hide-header-footer',
  SignupRedirect: 'signup-redirect',
  UpdateCardTags: 'update-card-tags',
  ResourcePlanCta: 'resource-plan-cta',
  HideResourceContributors: 'hide-resource-contributors',
  ShowMoreCommentsBtn: 'show-more-comments-btn',
  NewNavigation: 'new-navigation',
  NewFilterAttribute: 'new-filter-attribute',
  EnableReferralProgramForFreeMembers: 'enable-referral-program-for-free-members',
  ShowCreditsOnCheckout: 'show-credits-on-checkout',
  Paypal: 'paypal',
  BtsAu2024: 'bts-au-2024',
  BillingPagePaypal: 'billing-page-paypal',
  SchoolPlanDiscount: 'school-plan-discount',
  UsNewPlan: 'us-new-plan',
} as const;

export type ExperimentType = keyof typeof ExperimentTypes;
export type ExperimentKey = (typeof ExperimentTypes)[keyof typeof ExperimentTypes];
export function isExperimentVariant(key: string): key is ExperimentKey {
  return Object.values(ExperimentTypes).includes(key as ExperimentKey);
}

// TODO: fill this out as we go
const experimentVariants = {
  [ExperimentTypes.emailVerification]: [],
  [ExperimentTypes.CacheSearch]: [],
  [ExperimentTypes.Recaptcha]: ['control', 'on'],
  [ExperimentTypes.AlgoliaSearchBox]: ['control', 'on'],
  [ExperimentTypes.SettingsInPrexit]: ['off', 'on'],
  [ExperimentTypes.NewSubscribe]: ['off', 'on'],
  [ExperimentTypes.SaasquatchIntegration]: ['off', 'on'],
  [ExperimentTypes.BlogRecommendations]: ['control', 'on'],
  [ExperimentTypes.PasswordReset]: ['off', 'on'],
  [ExperimentTypes.EventLandingPages]: ['control', 'on'],
  [ExperimentTypes.FreeDownloadPremium]: ['control', 'on'],
  [ExperimentTypes.SignupFlowHideHeaderFooter]: ['off', 'on'],
  [ExperimentTypes.SignupRedirect]: ['control', 'on'],
  [ExperimentTypes.UpdateCardTags]: ['control', 'on'],
  [ExperimentTypes.ResourcePlanCta]: ['control', 'on'],
  [ExperimentTypes.HideResourceContributors]: ['control', 'on'],
  [ExperimentTypes.ShowMoreCommentsBtn]: ['control', 'on'],
  [ExperimentTypes.NewNavigation]: ['control', 'on'],
  [ExperimentTypes.NewFilterAttribute]: ['control', 'on'],
  [ExperimentTypes.EnableReferralProgramForFreeMembers]: ['control', 'on'],
  [ExperimentTypes.ShowCreditsOnCheckout]: ['control', 'on'],
  [ExperimentTypes.Paypal]: ['control', 'on'],
  [ExperimentTypes.BtsAu2024]: ['off', 'on'],
  [ExperimentTypes.BillingPagePaypal]: ['control', 'on'],
  [ExperimentTypes.SchoolPlanDiscount]: ['control', 'on'],
  [ExperimentTypes.UsNewPlan]: ['control', 'show-accessible', 'hide-monthly'],
} as const;

/**
 * Example usage:
 *
 * // FAILS
 * const variant: VariantValue<typeof ExperimentTypes.ShowFreeInNav> = 'dsf';
 *
 * // PASSES
 * const variant: VariantValue<typeof ExperimentTypes.ShowFreeInNav> = 'link-to-collection';
 */
export type VariantValue<T extends ExperimentKey> = (typeof experimentVariants)[T][number];
