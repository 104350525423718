export const isString = (data: any): data is string => {
  return typeof data === 'string';
};

export const isError = (data: any): data is Error => {
  return data instanceof Error;
};

export {};
declare global {
  interface Window {
    squatch: any; // 👈️ turn off type checking
  }
}
