<template>
  <div>
    <section class="section row align-middle">
      <div class="columns small-12 medium-7 large-7">
        <template v-if="error.statusCode === 404">
          <h1>Oops, we couldn't find that page.</h1>
          <p>
            It might have moved, been deleted, or may not exist. Check that your link is correct and
            you're in the
            <button
              title="Update the region"
              class="button-link"
              style="color: #4876bb"
              @click="toggleLocationSelectorModal(true)"
            >
              correct region
            </button>
            ({{ $i18n.locales.find(l => l.code == $i18n.locale).name }}).
          </p>

          <h3>Looking for a resource?</h3>
          <p>Use the search bar above to find resources.</p>
          <hr />
          <small class="text-mute">{{ error.statusCode }}: {{ error.message }}</small>
        </template>
        <template v-else>
          <h1>Something went wrong</h1>

          <p>{{ error.statusCode }}: {{ error.message }}</p>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
/* Error layout
 * Handle 404, 500 etc errors
 *
 * @parent:
 * @requests:
 * @events:
 * @props:
 * @components:
 * @methods:
 */

import { mapGetters } from 'vuex';

export default {
  name: 'ErrorLayout',
  layout: 'error',
  props: {
    error: { type: Object, default: null },
  },
  data() {
    return {
      showLocationSelector: false,
      selectedCountry: 'Australia',
    };
  },
  async fetch() {
    // // if the error is a 404, then check for a redirect URL with redirect service
    if (this.error.statusCode === 404) {
      await this.$handleRedirect(this.error.path || this.$route.path).catch(() => ({}));
    }
  },
  head() {
    return {
      title: 'Something went wrong',
      meta: [
        { hid: 'title', name: 'title', content: 'Something went wrong' },
        {
          hid: 'description',
          name: 'description',
          content:
            'Teach Starter provides you with unique, bright and engaging teaching resources to help your students or children succeed.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['location']),
  },
  mounted() {
    document.title = this.error.statusCode + ' | Teach Starter';
    this.$store.commit('setIsErrorPage', true);
  },
  methods: {
    toggleLocationSelectorModal(show) {
      this.$store.dispatch('toggleLocationSelectorModal', show);
    },
  },
  nuxtI18n: false,
};
</script>
