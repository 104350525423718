<template>
  <div>
    <two-row-header />
    <main class="tw-mx-auto tw-flex tw-min-h-screen tw-max-w-8xl">
      <small-nav-left class="tw-block md:tw-hidden" />
      <large-nav-left class="tw-hidden md:tw-block" />
      <nuxt
        class="tw-ml-16 tw-mt-6 !tw-px-6 md:tw-ml-0 md:tw-mt-8 lg:tw-mt-10 xl:tw-mt-14 2xl:tw-mt-16"
      />
    </main>
    <main-footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TwoRowHeader from '~/components/header/TwoRowHeader.vue';
import MainFooter from '~/components/MainFooter.vue';
import SmallNavLeft from '~/components/hub/SmallNavLeft.vue';
import LargeNavLeft from '~/components/hub/LargeNavLeft.vue';
import { getLocaleString } from '~/utils/localeString';
import { ExperimentTypes } from '~/types/experiments';

export default {
  name: 'HubLayout',
  components: {
    MainFooter,
    SmallNavLeft,
    LargeNavLeft,
    TwoRowHeader,
  },
  middleware: 'auth',
  data() {
    return {
      ExperimentTypes,
    };
  },
  head() {
    return {
      htmlAttrs: {
        lang: getLocaleString(this.$i18n?.locale),
      },
    };
  },
  computed: {
    ...mapGetters(['ariaHiddenMain']),
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
@import '@/assets/scss/sections/_hub-page.scss';

:deep(.exact-active-link) {
  color: $link-blue-500;
}
</style>
