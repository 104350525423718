<template>
  <nuxt />
</template>

<script>
/* Basic layout
 *
 * @parent:
 * @requests:
 * @events:
 * @props:
 * @components:
 * @methods:
 */

import { getLocaleString } from '~/utils/localeString';
import { website } from '~/utils/schema-org';

export default {
  name: 'BasicLayout',
  jsonld() {
    return website;
  },
  head() {
    return {
      htmlAttrs: {
        lang: getLocaleString(this.$i18n?.locale),
      },
    };
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/style.scss';
</style>
