/**
 * This module is specifically for modals so that we can have a consistent logic
 * and make the main store more readable.
 *
 * NOTE: There are currently still some modals
 * in the root store that need to be brought over
 */
export const MODALS = {
  /**
   * This modal is a prompt to confirm they want to download
   * before actually downloading.
   */
  DOWNLOAD_LIMIT_PROMPT: 'downloadLimitPrompt',
  /**
   * This modal is an alert to say they have used one of their
   * downloads.
   */
  DOWNLOAD_LIMIT_ALERT: 'downloadLimitAlert',

  /**
   * This modal is to upsell the user
   */
  DOWNLOAD_LIMIT_UPSELL: 'downloadLimitUpsell',
  UPGRADE_TO_DOWNLOAD: 'upgradeToDownload',

  UNIT_PLAN_UPGRADE_MODAL: 'unitPlanUpgrade',
  CUSTOMIZABLE_RESOURCE_MODAL: 'customizableResource',
  PREMIUM_RESOURCE_MODAL: 'premiumResource',
  EXHAUSTED_DOWNLOADS_MODAL: 'exhaustedDownloads',
  DOWNLOAD_UPSELL_MODAL: 'downloadUpsell',
  SIGNUP_COMBINED_MODAL: 'signUpCombined',
  SIGNUP_COMPLETE_ONLY_MODAL: 'signUpCompleteOnly',
  VERIFY_EMAIL_MODAL: 'verifyEmail',
  RESOURCE_CURRICULUM_MODAL: 'resourceCurriculum',
};

export const state = () => ({
  [MODALS.DOWNLOAD_LIMIT_PROMPT]: false,
  [MODALS.DOWNLOAD_LIMIT_ALERT]: false,
  [MODALS.DOWNLOAD_LIMIT_UPSELL]: false,
  [MODALS.UPGRADE_TO_DOWNLOAD]: false,
  [MODALS.UNIT_PLAN_UPGRADE_MODAL]: false,
  [MODALS.CUSTOMIZABLE_RESOURCE_MODAL]: false,
  [MODALS.PREMIUM_RESOURCE_MODAL]: false,
  [MODALS.EXHAUSTED_DOWNLOADS_MODAL]: false,
  [MODALS.DOWNLOAD_UPSELL_MODAL]: false,
  [MODALS.SIGNUP_COMBINED_MODAL]: false,
  [MODALS.SIGNUP_COMPLETE_ONLY_MODAL]: false,
  [MODALS.VERIFY_EMAIL_MODAL]: false,
  [MODALS.RESOURCE_CURRICULUM_MODAL]: false,
});

export const actions = {
  show({ commit }, modal) {
    commit('setAriaHiddenMain', false, { root: true });
    commit('show', modal);
  },
  close({ commit }, modal) {
    commit('setAriaHiddenMain', true, { root: true });
    commit('close', modal);
  },
  toggle({ commit, state }, modal) {
    commit('setAriaHiddenMain', state[modal], { root: true });
    commit('toggle', modal);
  },
  closeAll({ commit }) {
    commit('closeAll');
    commit('closeAllModals', false, { root: true });
  },
};

export const getters = {
  [MODALS.DOWNLOAD_LIMIT_PROMPT](state) {
    return state[MODALS.DOWNLOAD_LIMIT_PROMPT];
  },
  [MODALS.DOWNLOAD_LIMIT_ALERT](state) {
    return state[MODALS.DOWNLOAD_LIMIT_ALERT];
  },
  [MODALS.DOWNLOAD_LIMIT_UPSELL](state) {
    return state[MODALS.DOWNLOAD_LIMIT_UPSELL];
  },
  [MODALS.UPGRADE_TO_DOWNLOAD](state) {
    return state[MODALS.UPGRADE_TO_DOWNLOAD];
  },
  [MODALS.UNIT_PLAN_UPGRADE_MODAL](state) {
    return state[MODALS.UNIT_PLAN_UPGRADE_MODAL];
  },
  [MODALS.CUSTOMIZABLE_RESOURCE_MODAL](state) {
    return state[MODALS.CUSTOMIZABLE_RESOURCE_MODAL];
  },
  [MODALS.PREMIUM_RESOURCE_MODAL](state) {
    return state[MODALS.PREMIUM_RESOURCE_MODAL];
  },
  [MODALS.EXHAUSTED_DOWNLOADS_MODAL](state) {
    return state[MODALS.EXHAUSTED_DOWNLOADS_MODAL];
  },
  [MODALS.DOWNLOAD_UPSELL_MODAL](state) {
    return state[MODALS.DOWNLOAD_UPSELL_MODAL];
  },
  [MODALS.SIGNUP_COMBINED_MODAL](state) {
    return state[MODALS.SIGNUP_COMBINED_MODAL];
  },
  [MODALS.SIGNUP_COMPLETE_ONLY_MODAL](state) {
    return state[MODALS.SIGNUP_COMPLETE_ONLY_MODAL];
  },
  [MODALS.VERIFY_EMAIL_MODAL](state) {
    return state[MODALS.VERIFY_EMAIL_MODAL];
  },
  [MODALS.RESOURCE_CURRICULUM_MODAL](state) {
    return state[MODALS.RESOURCE_CURRICULUM_MODAL];
  },
  isAnyModalOpen(state) {
    for (const modal of Object.values(MODALS)) {
      if (state[modal]) {
        return true;
      }
    }
    return false;
  },
};

export const mutations = {
  show(state, modal) {
    state[modal] = true;
  },
  close(state, modal) {
    state[modal] = false;
  },
  toggle(state, modal) {
    state[modal] = !state[modal];
  },
  closeAll(state) {
    for (const modal of Object.values(MODALS)) {
      state[modal] = false;
    }
  },
};
