<template>
  <div>
    <login-modal v-if="showLoginModal && $route.path !== '/login/'" />
    <location-selector-modal v-if="showLocationSelectorModal" />
    <location-switch-modal v-if="showLocationSwitchModal" />
    <resource-preview-modal v-if="resourcePreview" />
    <upgrade-modal v-if="showUpgradeModal" />
    <select-folder-modal v-if="isAuthenticated" />
    <download-limit-alert-modal v-if="$store.state.modals.downloadLimitAlert" />

    <unit-plan-modal v-if="showUnitPlanUpgradeModal" />
    <customizable-resource-modal v-if="showCustomizableResourceModal" />
    <premium-resource-modal v-if="showPremiumResourceModal" />
    <exhausted-downloads-modal v-if="showExhaustedDownloadsModal" />
    <upsell-modal v-if="showDownloadUpsellModal" />
    <sign-up-combined-modal v-if="showSignUpCombinedModal" />
    <sign-up-complete-only-modal v-if="showSignUpCompleteOnlyModal" />
    <welcome-modal v-if="showWelcomeModal" />
    <verify-email-modal v-if="showVerifyEmailModal" />
    <resource-curriculum-modal v-if="showResourceCurriculumModal" />
    <div
      :class="{
        overlay: true,
        show: modalOpen,
      }"
      @click="closeAllModals"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoginModal from '~/components/modals/LoginModal.vue';

export default {
  name: 'Modals',
  components: {
    LoginModal,
    ResourcePreviewModal: () => import('~/components/resource/ResourcePreviewModal.vue'),
    UpgradeModal: () => import('~/components/modals/UpgradeModal.vue'),
    LocationSelectorModal: () => import('~/components/modals/LocationSelectorModal.vue'),
    SelectFolderModal: () => import('~/components/resource/SelectFolderModal.vue'),
    LocationSwitchModal: () => import('~/components/modals/LocationSwitchModal.vue'),
    // DownloadLimitPromptModal: () => import('~/components/modals/DownloadLimitPromptModal.vue'),
    DownloadLimitAlertModal: () => import('~/components/modals/DownloadLimitAlertModal.vue'),
    UnitPlanModal: () => import('~/components/modals/downloadUpgradeModals/UnitPlanModal.vue'),
    CustomizableResourceModal: () =>
      import('~/components/modals/downloadUpgradeModals/CustomizableResourceModal.vue'),
    PremiumResourceModal: () =>
      import('~/components/modals/downloadUpgradeModals/PremiumResourceModal.vue'),
    ExhaustedDownloadsModal: () =>
      import('~/components/modals/downloadUpgradeModals/ExhaustedDownloadsModal.vue'),
    UpsellModal: () => import('~/components/modals/downloadUpgradeModals/UpsellModal.vue'),
    SignUpCombinedModal: () => import('~/components/modals/SignUpCombinedModal.vue'),
    SignUpCompleteOnlyModal: () => import('~/components/modals/SignUpCompleteOnlyModal.vue'),
    WelcomeModal: () => import('~/components/hub/WelcomeModal.vue'),
    VerifyEmailModal: () => import('~/components/modals/VerifyEmailModal.vue'),
    ResourceCurriculumModal: () => import('~/components/modals/ResourceCurriculumModal.vue'),
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'resourcePreview',
      'showLoginModal',
      'showLocationSelectorModal',
      'showLocationSwitchModal',
      'showUpgradeModal',
      'showAddFolderModal',
      'showSelectFolderModal',
      'showWelcomeModal',
      'loggedInUser',
      'showVerifyEmailModal',
      'showResourceCurriculumModal',
    ]),
    ...mapGetters({
      showDownloadLimitAlertModal: 'modals/downloadLimitAlert',
      showDownloadModal: 'modals/upgradeToDownload',
      showUnitPlanUpgradeModal: 'modals/unitPlanUpgrade',
      showCustomizableResourceModal: 'modals/customizableResource',
      showPremiumResourceModal: 'modals/premiumResource',
      showExhaustedDownloadsModal: 'modals/exhaustedDownloads',
      showDownloadUpsellModal: 'modals/downloadUpsell',
      showSignUpCombinedModal: 'modals/signUpCombined',
      showSignUpCompleteOnlyModal: 'modals/signUpCompleteOnly',
      showVerifyEmailModal: 'modals/verifyEmail',
      showResourceCurriculumModal: 'modals/resourceCurriculum',
      // downloadModalData: 'modals/downloadModalData',
      /**
       * Would be good to add this modal back here, but we need a method of
       * passing through props to the modal so it knows what file to download.
       */
      // showDownloadLimitPromptModal: 'modals/downloadLimitPrompt',
    }),
    modalOpen() {
      return (
        this.resourcePreview ||
        this.showLoginModal ||
        this.showLocationSelectorModal ||
        this.showLocationSwitchModal ||
        this.showUpgradeModal ||
        this.showAddFolderModal ||
        this.showSelectFolderModal ||
        this.showWelcomeModal ||
        this.showDownloadLimitModal ||
        // this.showDownloadLimitPromptModal ||
        this.showDownloadLimitAlertModal ||
        this.showDownloadModal ||
        this.showUnitPlanUpgradeModal ||
        this.showCustomizableResourceModal ||
        this.showPremiumResourceModal ||
        this.showExhaustedDownloadsModal ||
        this.showDownloadUpsellModal ||
        this.showSignUpCombinedModal ||
        this.showSignUpCompleteOnlyModal ||
        this.showVerifyEmailModal ||
        this.showResourceCurriculumModal
      );
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.closeAllModals();
      }
      if (
        this.$isFlagOn('email-verification') &&
        this.loggedInUser &&
        this.loggedInUser.email_verified === 'false' &&
        to.path !== '/verify-email/'
      ) {
        this.$store.dispatch('modals/show', 'verifyEmail');
      }
      if (to.query && to.query.sign_up_complete) {
        this.$store.dispatch('modals/show', 'signUpCompleteOnly');
        this.$router.replace({ query: {} });
      }
      if (to.query && to.query.sign_up_complete) {
        this.$store.dispatch('modals/show', 'signUpCompleteOnly');
        this.$router.replace({ query: {} });
      }
    },
    modalOpen() {
      if (process.browser) {
        if (this.modalOpen) {
          document.body.classList.add('modal-open');
        } else {
          document.body.classList.remove('modal-open');
        }
      }
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.sign_up_complete) {
      this.$store.dispatch('modals/show', 'signUpCompleteOnly');
      this.$router.replace({ query: {} });
    }
  },
  methods: {
    closeAllModals() {
      this.$store.dispatch('modals/closeAll');
      this.$store.dispatch('closeAllModals');
      if (process.browser) {
        document.body.classList.remove('modal-open');
      }
    },
  },
};
</script>
