export interface RibbonMenuItem {
  title: string;
  url: string;
  icon: string;
  hideForPlus?: boolean;
}
interface RibbonMenu {
  au: RibbonMenuItem[];
  us: RibbonMenuItem[];
  gb: RibbonMenuItem[];
}

export const ribbonMenuLinks: RibbonMenu = {
  au: [
    {
      title: 'FREE',
      url: '/search/?filter-price%5B0%5D=Free',
      icon: '/images/fa-icons.svg#books',
      hideForPlus: true,
    },
    {
      title: 'Worksheets',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Worksheet',
      icon: '/images/fa-icons.svg#spreadsheet',
    },
    {
      title: 'Teaching Slides',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Teaching%20Slides',
      icon: '/images/fa-icons.svg#presentationslide',
    },
    {
      title: 'Unit & Lesson Plans',
      url: '/au/unit-plans',
      icon: '/images/fa-icons.svg#lesson',
    },
    {
      title: 'Report Writing',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Report%20Card%20Writing',
      icon: '/images/fa-icons.svg#report',
    },
    {
      title: 'Classroom Videos',
      url: '/search/?filter-format%5B0%5D=Video',
      icon: '/images/fa-icons.svg#videocamera',
    },
    {
      title: 'Décor',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Classroom%20Decor',
      icon: '/images/fa-icons.svg#decoration',
    },
    {
      title: 'Games',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Game',
      icon: '/images/fa-icons.svg#puzzle',
    },
    {
      title: 'Crafts',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Crafts',
      icon: '/images/fa-icons.svg#craft',
    },
    {
      title: 'Maths',
      url: '/search/?filter-learning-area.lvl0%5B0%5D=Maths',
      icon: '/images/fa-icons.svg#math',
    },
    {
      title: 'English',
      url: '/search/?filter-learning-area.lvl0%5B0%5D=English',
      icon: '/images/fa-icons.svg#english',
    },
    {
      title: 'Science',
      url: '/search/?filter-learning-area.lvl0%5B0%5D=Science',
      icon: '/images/fa-icons.svg#science',
    },
    {
      title: 'Curriculum Aligned',
      url: '/search/?filter-attribute%5B0%5D=Curriculum-Aligned',
      icon: '/images/fa-icons.svg#curriculumribbon',
    },
    {
      title: 'Differentiated',
      url: '/search/?filter-attribute%5B0%5D=Differentiated',
      icon: '/images/fa-icons.svg#differentiatedribbon',
    },
  ],
  us: [
    {
      title: 'FREE',
      url: '/search/?filter-price%5B0%5D=Free',
      icon: '/images/fa-icons.svg#books',
      hideForPlus: true,
    },
    {
      title: 'Worksheets',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Worksheet',
      icon: '/images/fa-icons.svg#spreadsheet',
    },
    {
      title: 'Teaching Slides',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Teaching%20Slides',
      icon: '/images/fa-icons.svg#presentationslide',
    },
    {
      title: 'Unit & Lesson Plans',
      url: '/us/unit-plans',
      icon: '/images/fa-icons.svg#lesson',
    },
    {
      title: 'Formative Assessments',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Formative%20Assessments',
      icon: '/images/fa-icons.svg#report',
    },
    {
      title: 'Classroom Videos',
      url: '/search/?filter-format%5B0%5D=Video',
      icon: '/images/fa-icons.svg#videocamera',
    },
    {
      title: 'Games',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Game',
      icon: '/images/fa-icons.svg#puzzle',
    },
    {
      title: 'Crafts',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Crafts',
      icon: '/images/fa-icons.svg#craft',
    },
    {
      title: 'Math',
      url: '/search/?filter-learning-area.lvl0%5B0%5D=Math',
      icon: '/images/fa-icons.svg#math',
    },
    {
      title: 'English',
      url: '/search/?filter-learning-area.lvl0%5B0%5D=English%20Language%20Arts',
      icon: '/images/fa-icons.svg#english',
    },
    {
      title: 'Science',
      url: '/search/?filter-learning-area.lvl0%5B0%5D=Science',
      icon: '/images/fa-icons.svg#science',
    },
    {
      title: 'Curriculum Aligned',
      url: '/search/?filter-attribute%5B0%5D=Curriculum-Aligned',
      icon: '/images/fa-icons.svg#curriculumribbon',
    },
    {
      title: 'Differentiated',
      url: '/search/?filter-attribute%5B0%5D=Differentiated',
      icon: '/images/fa-icons.svg#differentiatedribbon',
    },
  ],
  gb: [
    {
      title: 'FREE',
      url: '/search/?filter-price%5B0%5D=Free',
      icon: '/images/fa-icons.svg#books',
      hideForPlus: true,
    },
    {
      title: 'Worksheets',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Worksheet',
      icon: '/images/fa-icons.svg#spreadsheet',
    },
    {
      title: 'Classroom Videos',
      url: '/search/?filter-format%5B0%5D=Video',
      icon: '/images/fa-icons.svg#videocamera',
    },
    {
      title: 'Unit & Lesson Plans',
      url: '/gb/unit-plans',
      icon: '/images/fa-icons.svg#lesson',
    },
    {
      title: 'Games',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Game',
      icon: '/images/fa-icons.svg#puzzle',
    },
    {
      title: 'Crafts',
      url: '/search/?filter-resource-type.lvl0%5B0%5D=Crafts',
      icon: '/images/fa-icons.svg#craft',
    },
    {
      title: 'Maths',
      url: '/search/?filter-learning-area.lvl0%5B0%5D=Maths',
      icon: '/images/fa-icons.svg#math',
    },
    {
      title: 'English',
      url: '/search/?filter-learning-area.lvl0%5B0%5D=English',
      icon: '/images/fa-icons.svg#english',
    },
    {
      title: 'Science',
      url: '/search/?filter-learning-area.lvl0%5B0%5D=Science',
      icon: '/images/fa-icons.svg#science',
    },
    {
      title: 'Student Certificates',
      url: '/search/?filter-attribute%5B0%5D=Student%20Certificates',
      icon: '/images/fa-icons.svg#spreadsheet',
    },
    {
      title: 'Curriculum Aligned',
      url: '/search/?filter-attribute%5B0%5D=Curriculum-Aligned',
      icon: '/images/fa-icons.svg#curriculumribbon',
    },
  ],
};

export default ribbonMenuLinks;
