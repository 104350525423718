import { Plugin } from '@nuxt/types';

interface GAPlugin {
  $setGa4UserProperties: () => void;
}

declare module '@nuxt/types' {
  interface Context extends GAPlugin {}
}

const GAPlugin: Plugin = ({ $auth }, inject) => {
  const setGa4UserProperties = (): void => {
    if (process.server || typeof window.gtag === 'undefined') {
      return;
    }

    window.gtag('set', 'user_properties', {
      id: $auth.user?.id,
      userUuid: $auth.user?.uuid,
      userCountry: $auth.user?.location.country,
      userCurrency: $auth.user?.location.currency,
      planName: $auth.user?.subscription?.name,
    });
  };

  if (process.client) {
    setGa4UserProperties();
  }

  inject('setGa4UserProperties', setGa4UserProperties);
};

export default GAPlugin;
