<template>
  <div>
    <div v-if="$fetchState.pending" class="callout">Loading widget...</div>
    <div v-else-if="!isAuthenticated" class="not-logged-in">
      <div>
        <ts-logo-sticker />
        <h1>{{ widget.title }}</h1>

        <p>
          You must be logged in to use this widget.
          <nuxt-link to="/login/" title="Log in to your account">Login here</nuxt-link>
        </p>
      </div>
    </div>

    <div v-else-if="!isAuthorisedWidget" class="not-logged-in">
      <div>
        <ts-logo-sticker />
        <h1>{{ widget.title }}</h1>

        <p>
          Can't access this widget with your Teach Starter account.
          <nuxt-link :to="'/widget/' + widget.slug + '/'" title="Go back">Go back</nuxt-link>
        </p>
      </div>
    </div>

    <div v-else-if="$fetchState.error" class="callout callout-error">Error loading widget.</div>

    <nuxt v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TsLogoSticker from '~/components/svg/TsLogoSticker.vue';
// Keep a local record of all the widgets because we don't have an endpoint to get just the engage widgets
import widgetsEngage from '~/data/widgets_engage.json';
import { ExperimentTypes } from '~/types/experiments';
import { getLocaleString } from '~/utils/localeString';

export default {
  name: 'WidgetLayout',
  components: {
    TsLogoSticker,
  },
  data() {
    return {
      slug: '',
      name: '',
      widget: {},
      ExperimentTypes,
    };
  },
  async fetch() {
    if (!this.isAuthenticated) {
      return;
    }

    // Catch links without trailing slash
    const pathArr = this.$route.path.split('/');
    this.slug = pathArr.filter(item => item).pop(-1);

    const widgetsCountry = widgetsEngage[this.$i18n.locale];
    this.widget = widgetsCountry.find(widget => widget.slug === this.slug);

    // Fallback
    if (this.widget) {
      this.title = this.widget.title;
      this.description = 'Use the ' + this.widget.title + ' widget';

      // Hack to enable access to widgets without contacting the server when testing. This
      // only works if word lists are not required.
      if (this.widget.availability === 'test' && process.env.NODE_ENV !== 'production') {
        await this.$store.commit('authoriseWidget', { authorised: true });
      } else {
        try {
          await this.$store.dispatch('authoriseWidget', {
            id: this.widget.id,
          });
        } catch (err) {
          throw new Error(err);
        }
      }
    } else {
      this.$context.error({ statusCode: 404, message: 'Widget not found.' });
    }
  },
  head() {
    return {
      title: this.title,
      meta: [{ hid: 'title', name: 'title', content: this.description }],
      htmlAttrs: {
        lang: getLocaleString(this.$i18n?.locale),
      },
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'loggedInUser',
      'isAuthorisedWidget',
      'lastSearchedTerm',
      'location',
    ]),
  },
  mounted() {
    document.documentElement.classList.add('engage');

    if (this.isAuthenticated) {
      const eventParams = {
        Page_Type_Name: 'Widget',
        Page_Id: this.widget.id,
        Page_Name: this.widget.title,
        Page_Url: this.$config.baseURL + this.$route.path,
        Page_Country: this.$i18n.locale,
        Page_Publisher_Id: 4078978,
        Page_Publisher_Name: 'Teach Starter Publishing',
        Resource_Type_Name: 'Widget',
        Resource_Sub_Type_Name: 'Interactive Widget',
        Resource_Availability: this.widget.availability,
        Resource_Country: this.$i18n.locale,
        Resource_Language: 'en-' + this.$i18n.locale.toUpperCase(),
      };

      this.$amplitude.track('Resource | Resource Played', eventParams);
    } else {
      this.$store.dispatch('setRedirect', this.$router.currentRoute.path);
    }

    // Hide Intercom
    window.setTimeout(() => {
      if (window.Intercom) {
        window.Intercom('update', {
          hide_default_launcher: false,
        });
      }
    }, 300);
    window.Intercom('update', {
      hide_default_launcher: false,
    });
  },
  methods: {
    loggedIn() {
      // Reload the page to safely init the widget
      location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~/assets/scss/style.scss';
@import '~/assets/scss/sections/_engage.scss';
// TODO: This forces global styles on form elements. be good to remove this and use components
@import '~/assets/scss/components/_form.scss';

h1 {
  margin: 0;
}
</style>
