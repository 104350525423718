import { User } from '~/types/user';

/**
 * Should return false if quota is 0 or not defined.
 * Should return true is quota is greater than 0 AND the quota_left is >= 0
 */
export const doesUserHaveQuota = (user: User): boolean => {
  // eslint-disable-next-line camelcase
  const { quota, quota_left } = user?.subscription || {};
  return Number.isInteger(quota) && Number(quota) > 0 && Number.isInteger(quota_left);
};

export const hasUserReachedDownloadLimit = (user: User): boolean => {
  const quotaLeft = user?.subscription?.quota_left;
  if (quotaLeft === undefined) {
    return false;
  }
  return doesUserHaveQuota(user) && quotaLeft <= 0;
};

export const isUserOnLastDownload = (user: User): boolean => {
  const quota = user?.subscription?.quota_left;
  return doesUserHaveQuota(user) && quota === 1;
};

export const doesUserHaveAllInfoForSignup = (user: User): boolean => {
  if (!user) {
    return true;
  }
  return !!(user?.grades?.length && user?.occupation && user?.location?.country);
};

export const getTimeSavedAndDownloadCount = (
  user: User
): { totalHours: number; downloads: number } => {
  const FOUR_HOURS_IN_SECONDS_PER_DOWNLOAD = 4 * 60 * 60;
  const downloads = user?.download_count || 0;

  const secondsSaved = downloads * FOUR_HOURS_IN_SECONDS_PER_DOWNLOAD;
  const totalHours = Math.floor((secondsSaved % 31536000) / 86400) * 24;

  return { totalHours, downloads };
};

export const getTimeSavedString = (user: User): string => {
  const FOUR_HOURS_IN_SECONDS_PER_DOWNLOAD = 4 * 60 * 60;
  const downloads = user?.download_count || 0;

  const secondsSaved = downloads * FOUR_HOURS_IN_SECONDS_PER_DOWNLOAD;

  const years = Math.floor(secondsSaved / 31536000);
  const days = Math.floor((secondsSaved % 31536000) / 86400);
  const hours = Math.floor(((secondsSaved % 31536000) % 86400) / 3600);

  let output = '';
  if (years > 0) {
    output += years + (years === 1 ? ' year' : ' years');
  }
  if (days > 0) {
    if (output.length > 0) {
      output += ', ';
    }
    output += days + (days === 1 ? ' day' : ' days');
  }
  if (hours > 0) {
    if (output.length > 0) {
      output += ' and ';
    }
    output += hours + ' hours';
  }

  return output;
};
