import { Auth } from '@nuxtjs/auth-next';
import { User } from '~/types/user';
import { WordList } from '~/types/wordlist';
import { SchoolFont } from '~/composables/deskplates/types';
import { Locale as ValidSecondaryLocation } from '~/types/locale';
import type { CanUserDownloadResponse } from '~/types/download';

interface Location {
  name: string;
  code: string;
  secondary: ValidSecondaryLocation;
  currency: string;
}
interface SearchResults {
  results: any[];
  size: number;
  query: string;
}

interface Experiments {
  [k: string]: {
    value: string;
    payload?: unknown;
  };
}

interface Comment {
  id: number;
  parent: number;
  resource: number;
  user: {
    uuid: string;
    display_name: string;
    photo: string;
    moderator: string;
  };
  approved: 0 | 1;
  comment: string;
  comments: Comment[];
  created: string;
  modified: string;
}

export interface State {
  alternatePages: any[];
  category?: any;
  curriculum?: any;
  curriculums: any[];
  deviceId: string;
  filters?: any;
  headerBanner?: any;
  isAuthorisedWidget: boolean;
  loadingSearchResults: boolean;
  loadingUserFolders: boolean;
  location: Location;
  geoLocation: any;
  locationAccount?: any;
  notifications: any[];
  resourceId?: any;
  resource?: any;
  resourceStats: any;
  redirectTo: string;
  redirectToBilling: string;
  savedSearchTerms: any[];
  siteData: any;
  searchResults: SearchResults;
  ariaHiddenMain: boolean;
  showLoginModal: boolean;
  showLocationSelectorModal: boolean;
  showLocationSwitchModal: boolean;
  showUpgradeModal: boolean;
  showAddFolderModal: boolean;
  showSelectFolderModal: boolean;
  showWelcomeModal: boolean;
  showResourceCurriculumModal: boolean;
  resourcePreview?: any;
  showMobileNav: boolean;
  trendingTopics: any[];
  trendingResources: any[];
  toast?: any;
  userBookmarks: any[];
  userFolders: any[];
  userFolder?: any;
  userHomeFeed: any;
  userNotifications: any;
  userStudioFiles: any;
  userWordLists: WordList[];
  recommendedResources: any[];
  experiments: Experiments;
  isErrorPage: boolean;
  comments: Comment[];
  schoolFonts: SchoolFont[];
  schoolFontLines: any[];
  currentPlan: string | null;
  referralCode: string | null;
  auth?: Auth & { user: User };
  megaMenuOpen: boolean;
  userDownloadPermissions: CanUserDownloadResponse[] | null;
}

export const state = (): State => ({
  alternatePages: [],
  category: null,
  curriculum: null,
  curriculums: [],
  deviceId: '',
  filters: null,
  headerBanner: null, // Example: { content: 'Some message', 'icon': 'thumbs-up', 'link': '/', dismiss: true }
  isAuthorisedWidget: false, // Authorised to use this widget
  loadingSearchResults: false,
  loadingUserFolders: false,
  location: { name: 'United States', code: 'us', secondary: 'us', currency: 'usd' },
  geoLocation: {},
  locationAccount: null, // The user's account location that is set when they sign up
  notifications: [],
  resourceId: null,
  resource: null,
  resourceStats: {},
  redirectTo: '/hub/', // Link to redirect user to after login - set as cookie
  redirectToBilling: '',
  savedSearchTerms: [],
  siteData: {},
  searchResults: { results: [], size: 0, query: '' },
  ariaHiddenMain: false,
  showLoginModal: false,
  showLocationSelectorModal: false,
  showLocationSwitchModal: false,
  showUpgradeModal: false,
  showAddFolderModal: false,
  showSelectFolderModal: false,
  showWelcomeModal: false,
  showResourceCurriculumModal: false,
  resourcePreview: null,
  showMobileNav: false,
  trendingTopics: [],
  trendingResources: [],
  toast: null, // Example: { type: 'success', content: 'Some message', 'icon': 'thumbs-up', 'image': null, dismiss: true, timeout: 3 }
  userBookmarks: [],
  userFolders: [],
  userFolder: null,
  userHomeFeed: {},
  userNotifications: {},
  userStudioFiles: {},
  userWordLists: [],
  recommendedResources: [],
  experiments: {},
  isErrorPage: false,
  comments: [],
  schoolFonts: [],
  schoolFontLines: [],
  currentPlan: null,
  referralCode: null,
  megaMenuOpen: false,
  userDownloadPermissions: null,
});

export type RootState = ReturnType<typeof state>;
