import { Plugin } from '@nuxt/types';
import dayjs from 'dayjs';
import { isFileFormat, Resource } from '~/types/resource';
interface IntercomResourceEventParams {
  action_type_name: string;
  resource_id: number;
  resource_name: string;
  resource_type_name: string;
  logged_in: string;
  date: string;
  publisher_id?: string;
  publisher_name?: string;
  user_id?: string;
  user_plan_id?: string;
  user_plan_name?: string;
  sequential_id?: string;
}

interface IntercomHomeFeedDownloadEvent {
  action_type_name: string;
  resource_id: number;
  resource_name: string;
  resource_type_name: string;
  logged_in: string;
  date: string;
  fileExtension: string;
  downloadType: string;
  file_id: number | undefined;
  publisher_id?: string;
  publisher_name?: string;
  user_id?: string;
  user_plan_id?: string;
  user_plan_name?: string;
  sequential_id?: string;
}

interface IntercomPlugin {
  $intercom: Intercom_.IntercomCommandSignature;
  $constructIntercomResourceParams: (resource: Resource) => IntercomResourceEventParams;
  $constructIntercomHomeFeedDownloadEvent: (resource: Resource) => IntercomHomeFeedDownloadEvent;
  $intercomIdentify: () => void;
}

declare module '@nuxt/types' {
  interface Context extends IntercomPlugin {}
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> extends IntercomPlugin {}
}

const intercomPlugin: Plugin = ({ store, $intercom, $config: { intercomId } }, inject) => {
  /*
    Intercom JS snippet to fix Intercom not found in window on page refresh.
    https://developers.intercom.com/installing-intercom/docs/basic-javascript#section-single-page-app
   */

  const APP_ID = intercomId;
  if (process.browser) {
    window.intercomSettings = {
      app_id: APP_ID,
    };
    (function (): void {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        // @ts-ignore This comes straight from Intercom
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i = function (): void {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments);
        };
        // @ts-ignore This comes straight from Intercom
        i.q = [];
        i.c = function (args: any): void {
          i.q.push(args);
        };
        // @ts-ignore This comes straight from Intercom
        w.Intercom = i;
        const l = function (): void {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + APP_ID;
          const x = d.getElementsByTagName('script')[0];
          // @ts-ignore This comes straight from Intercom
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
          // @ts-ignore This comes straight from Intercom
        } else if (w.attachEvent) {
          // @ts-ignore This comes straight from Intercom
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }

  inject('constructIntercomResourceParams', (item: Resource): IntercomResourceEventParams => {
    const params: IntercomResourceEventParams = {
      action_type_name: 'Viewed',
      resource_id: item.old_id || item.id,
      resource_name: item.name,
      resource_type_name: item.type,
      logged_in: store.getters.isAuthenticated,
      date: dayjs(new Date()).format('YYYY-MM-DDTh:mmZ'),
    };

    if (item.publisher) {
      params.publisher_id = item.publisher.slug;
      params.publisher_name = item.publisher.name;
    }

    if (store.getters.isAuthenticated) {
      const user = store.getters.loggedInUser;
      params.user_id = user.uuid;
      params.user_plan_id = user.subscription.name;
      params.user_plan_name = user.subscription.name;
      params.sequential_id = user.id;
    }
    return params;
  });

  inject(
    'constructIntercomHomeFeedDownloadEvent',
    (item: Resource): IntercomHomeFeedDownloadEvent => {
      const params: IntercomHomeFeedDownloadEvent = {
        action_type_name: 'Viewed',
        resource_id: item.old_id || item.id,
        resource_name: item.name,
        resource_type_name: item.type,
        logged_in: store.getters.isAuthenticated,
        date: dayjs(new Date()).format('YYYY-MM-DDTh:mmZ'),
        fileExtension: isFileFormat(item.file_formats[0])
          ? item.file_formats[0].format
          : item.file_formats[0],
        downloadType: 'Standard Download',
        file_id: item.file_id,
      };

      if (item.publisher) {
        params.publisher_id = item.publisher.slug;
        params.publisher_name = item.publisher.name;
      }

      if (store.getters.isAuthenticated) {
        const user = store.getters.loggedInUser;
        params.user_id = user.uuid;
        params.user_plan_id = user.subscription.name;
        params.user_plan_name = user.subscription.name;
        params.sequential_id = user.id;
      }
      return params;
    }
  );

  // Identify logged in users

  const identifyIntercomUser = (): void => {
    const user = store.getters.loggedInUser;
    const userProperties = {
      user_id: user.uuid,
      email: user.email,
      name: user.first_name.concat(' ', user.last_name),
      user_hash: user.intercom.hash,
      signed_up_at: dayjs(user.created).format('YYYY-MM-DDTh:mmZ'),
      uuid: user.uuid,
      firstName: user.first_name,
      lastName: user.last_name,
      username: user.user_name,
      displayName: user.display_name,
      plan: user.subscription.name,
      schoolTeacherCount: user.licenses,
      country: user.location.code,
      countryState: user.location.state,
      downloadCount: user.download_count,
      occupation: user.occupation,
      grades: user.grades?.toString() || '',
      emailNewsletters: user.email_settings.newsletter === 0 ? 'false' : 'true',
      emailActions: user.email_settings.actions === 0 ? 'false' : 'true',
      emailResources: user.email_settingsnew_resources === 0 ? 'false' : 'true',
      emailNotifications: user.email_settings.notifications === 0 ? 'false' : 'true',
      emailOnboarding: user.email_settings.onboarding === 0 ? 'false' : 'true',
      sequential_id: user.id,
      role: user.role,
      recurlyAccountStatus: user.subscription.status,
    };
    if (typeof $intercom !== 'undefined') {
      $intercom.update(userProperties);
    }
  };

  if (process.browser) {
    // Identify logged in users
    if (store.getters.isAuthenticated) {
      identifyIntercomUser();
    }
  }

  inject('intercomIdentify', () => {
    identifyIntercomUser();
  });
};

export default intercomPlugin;
