import {
  Experiment as browserExperimentSDK,
  ExperimentClient as BrowserExperimentClient,
} from '@amplitude/experiment-js-client';
import {
  Experiment as nodeExperimentSDK,
  RemoteEvaluationClient as NodeExperimentClient,
} from '@amplitude/experiment-node-server';
import { Plugin } from '@nuxt/types';
import { ExperimentKey, VariantValue } from '~/types/experiments';
import { isClientAmplitude } from '~/utils/amplitude';

interface AmplitudeExperimentPlugin {
  $amplitudeExperimentRefetchVariants: () => void;
  $getExperiment: <T extends ExperimentKey>(
    key: T
  ) => { value: VariantValue<T>; payload: any } | null;
  $getExperimentVariant: <T extends ExperimentKey>(key: T) => VariantValue<T> | undefined;
  $isControlExperiment: (key: ExperimentKey) => boolean;
  $isExperimentOfVariantType: (key: ExperimentKey, value: string) => boolean;
  $experimentPayload: (key: ExperimentKey) => any;
  $isFlagOn: (key: ExperimentKey) => boolean;
}
declare module '@nuxt/types' {
  interface Context extends AmplitudeExperimentPlugin {}
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> extends AmplitudeExperimentPlugin {}
}

let client: null | BrowserExperimentClient | NodeExperimentClient = null;

const amplitudeExperimentPlugin: Plugin = async (
  { $auth, store, $amplitude, $cookies, $config: { amplitudeExperimentKey } },
  inject
) => {
  const updateVariants = async (): Promise<void> => {
    const experimentUser = {
      user_id: $auth.user?.uuid || '',
      device_id: isClientAmplitude($amplitude)
        ? $amplitude?.getDeviceId() || $cookies.get('deviceId') || store.getters.deviceId
        : $cookies.get('deviceId') || store.getters.deviceId,
    };

    try {
      if (client instanceof BrowserExperimentClient) {
        const experimentClient = await client.fetch(experimentUser);
        const { ...clientVariants } = experimentClient.all();

        // This will be empty in browsers and plugins that block tracking. If that happens rely on SSR data
        if (Object.keys(clientVariants).length === 0) {
          return;
        }
        store.dispatch('updateExperiments', clientVariants);
      } else if (client) {
        const { ...variants } = await client.fetch(experimentUser);
        store.dispatch('updateExperiments', variants);
      }
    } catch (e) {
      console.error('Could not update experiments', e);
    }
  };

  inject('amplitudeExperimentRefetchVariants', async (): Promise<void> => {
    await updateVariants();
  });

  inject(
    'getExperiment',
    <T extends ExperimentKey>(key: T): { value: VariantValue<T>; payload: any } | null => {
      return store.getters.experiments[key] ?? null;
    }
  );

  inject('getExperimentVariant', <T extends ExperimentKey>(key: T): VariantValue<T> | undefined => {
    const variant = store.getters.experiments[key];
    return variant?.value ?? undefined;
  });

  inject('isControlExperiment', (key: ExperimentKey): boolean => {
    if (!store.getters.experiments[key]) {
      return true;
    }
    return store.getters.experiments[key]?.value === 'control';
  });

  inject('isExperimentOfVariantType', (key: ExperimentKey, value: string): boolean => {
    const variant = store.getters.experiments[key]?.value;
    if (variant) {
      // Check variant exists in array
      if (Array.isArray(value)) {
        return value.includes((v: string) => v === variant);
      }
      return value === variant;
    }
    return false;
  });

  inject('experimentPayload', (key: ExperimentKey): any => {
    return store.getters.experiments[key]?.payload;
  });

  inject('isFlagOn', (key: ExperimentKey): boolean => {
    return store.getters.experiments[key]?.value === 'on';
  });

  /**
   * Experiment specific helpers
   */
  client = process.browser
    ? browserExperimentSDK.initializeWithAmplitudeAnalytics(amplitudeExperimentKey)
    : nodeExperimentSDK.initializeRemote(amplitudeExperimentKey);

  await updateVariants();
};

export default amplitudeExperimentPlugin;
