import * as amplitudeBrowser from '@amplitude/marketing-analytics-browser';
import * as amplitudeNode from '@amplitude/analytics-node';

export type AmplitudeInstance =
  | amplitudeBrowser.Types.BrowserClient
  | amplitudeNode.Types.NodeClient;
export const isClientAmplitude = (
  instance: AmplitudeInstance
): instance is amplitudeBrowser.Types.BrowserClient => {
  return (instance as amplitudeBrowser.Types.BrowserClient).getDeviceId !== undefined;
};
