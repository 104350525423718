<template>
  <form @submit.prevent="navigateToSearch($refs.search.value)">
    <ais-instant-search-ssr ref="dropdownToggle" class="search-container">
      <ais-configure :hits-per-page.camel="5" :filters="filters" distinct="true" />
      <ais-index :index-name="querySuggestionsIndex" :index-id="querySuggestionsIndex">
        <ais-configure :hits-per-page.camel="5" filters="" distinct="true" />
      </ais-index>
      <ais-autocomplete
        :class="{
          autocomplete: true,
          focused: showDropdown,
        }"
        class="autocomplete"
      >
        <template #default="{ currentRefinement, indices, refine }">
          <input
            ref="search"
            class="search-input"
            type="search"
            :value="currentRefinement"
            placeholder="Search for topics, keywords..."
            @input.prevent="
              {
                refine($event.currentTarget.value);
                openDropdown();
              }
            "
            @focus="openDropdown"
          />
          <button
            aria-label="search"
            class="search-button"
            @click="navigateToSearch(currentRefinement)"
          >
            <svg role="img" height="20" width="20" aria-label="search">
              <title>Search</title>
              <use xlink:href="/images/fa-icons.svg#search-algolia"></use>
            </svg>
          </button>
          <template v-if="currentRefinement && showDropdown">
            <ul class="no-list">
              <template v-if="getHitsFromIndex(querySuggestionsIndex, indices)">
                <li
                  v-for="suggestion in getHitsFromIndex(querySuggestionsIndex, indices)"
                  :key="suggestion.id"
                >
                  <nuxt-link
                    v-if="suggestion.query !== currentRefinement"
                    :to="localePath('/search/') + `?query=${suggestion.query}`"
                    :title="`${suggestion.query}`"
                    @click.native="trackSearch(suggestion.query, 'Querysuggest')"
                  >
                    Search for
                    <ais-highlight attribute="query" :hit="suggestion" />
                  </nuxt-link>
                </li>
              </template>
              <template v-if="getHitsFromIndex(resourcesIndex, indices)">
                <li v-for="hit in getHitsFromIndex(resourcesIndex, indices)" :key="hit.id">
                  <nuxt-link
                    :to="localePath(hit.url)"
                    :title="`View ${hit.title}`"
                    @click.native="trackSearch(currentRefinement, 'Autosuggest')"
                  >
                    <figure>
                      <img
                        :src="'https://fileserver.teachstarter.com' + hit.featuredImage.thumb"
                        height="105"
                        width="200"
                        :alt="`Go to ${hit.title} ${hit.postType.replace('-', ' ')}`"
                        @error="defaultThumbnailOnError($event, '/images/default_thumbnail.png')"
                      />
                    </figure>

                    <ais-highlight attribute="title" :hit="hit" />
                  </nuxt-link>
                </li>
              </template>
            </ul>
            <nuxt-link
              :to="localePath('/search/') + `?query=${currentRefinement}`"
              title="Search resources"
              @click.native="trackSearch(currentRefinement, 'Autosuggest')"
            >
              View more results &rarr;
            </nuxt-link>
          </template>
        </template>
      </ais-autocomplete>
    </ais-instant-search-ssr>
  </form>
</template>
<script>
import { mapGetters } from 'vuex';
import _renderToString from 'vue-server-renderer/basic';
import algoliasearch from 'algoliasearch/lite';
import InstantSearch, {
  AisInstantSearchSsr,
  AisConfigure,
  AisAutocomplete,
  AisHighlight,
  AisIndex,
  createServerRootMixin,
} from 'vue-instantsearch';
import Vue from 'vue';
import { defaultThumbnailOnError } from '~/utils/image';

if (process.browser) {
  Vue.use(InstantSearch);
}

const querySuggestionsIndex = 'production_resources_query_suggestions';
const resourcesIndex = process.env.NUXT_ENV_ALGOLIA_RESOURCE_INDEX || 'staging_resources';

let debounce = null;

const searchFunction = helper => {
  if (!helper.state.query) {
    return false; // no search if empty query
  }
  // this may cause users to perceive the search to be lagging,
  // but will reduce the number of search operations and reduce the cost.
  if (debounce) {
    clearTimeout(debounce);
  }
  debounce = setTimeout(() => {
    if (helper.state.query && (helper.state.query.length < 3 || helper.state.query.length > 20)) {
      return false; // no search if less than 3 or more than 20 characters
    } else {
      // on empty search query (will send local response, see computed searchClient())
      helper.search();
    }
  }, 250);
};

export default {
  name: 'AlgoliaSearchBar',
  components: {
    AisInstantSearchSsr,
    AisConfigure,
    AisAutocomplete,
    AisHighlight,
    AisIndex,
  },
  provide() {
    return {
      // Below line and creating mixin in data are a fix for memory leak issue
      // - solution from this GH issue https://github.com/algolia/vue-instantsearch/issues/685#issuecomment-661809065
      $_ais_ssrInstantSearchInstance: this.instantsearch,
    };
  },
  data() {
    const searchClient = algoliasearch(
      process.env.NUXT_ENV_ALGOLIA_APPLICATION_ID || 'MD3VZQ78RT',
      process.env.NUXT_ENV_ALGOLIA_API_KEY || '1fca486e387e5134d48652408b96123a'
    );
    const mixin = createServerRootMixin({
      searchClient,
      indexName: resourcesIndex,
      indexId: resourcesIndex,
      searchFunction,
    });
    return {
      filters: 'country:' + this.$i18n.locale,
      searchClient,
      querySuggestionsIndex,
      resourcesIndex,
      showDropdown: false,
      debounce: null,
      defaultThumbnailOnError,
      ...mixin.data(),
    };
  },
  computed: {
    ...mapGetters(['trendingTopics', 'savedSearchTerms']),
  },
  watch: {
    $route() {
      this.showDropdown = false;
    },
  },
  // this setup comes from the aloglia autocomplete docs for SSR in nuxt https://www.algolia.com/doc/guides/building-search-ui/going-further/server-side-rendering/vue/?client=Vue+2#with-nuxt-2
  serverPrefetch() {
    const renderToString = app => {
      return new Promise((resolve, reject) => {
        _renderToString(app, (err, res) => {
          if (err) {
            reject(err);
          }
          resolve(res);
        });
      });
    };
    return this.instantsearch
      .findResultsState({
        component: this,
        renderToString,
      })
      .then(algoliaState => {
        this.$ssrContext.nuxt.algoliaState = algoliaState;
      });
  },
  beforeMount() {
    const results =
      (this.$nuxt.context && this.$nuxt.context.nuxtState.algoliaState) ||
      window.__NUXT__.algoliaState;

    this.instantsearch.hydrate(results);

    // Remove the SSR state so it can't be applied again by mistake
    delete this.$nuxt.context.nuxtState.algoliaState;
    delete window.__NUXT__.algoliaState;
  },
  methods: {
    getHitsFromIndex(name, indices) {
      return indices.filter(({ indexName }) => indexName === name)?.[0].hits;
    },
    navigateToSearch(searchValue) {
      this.$router.push(this.localePath('/search/') + searchValue);
      this.trackSearch(searchValue, 'Typed');
    },
    openDropdown() {
      this.showDropdown = true;

      const closeListener = e => {
        if (this.catchOutsideClick(e, this.$refs.dropdownToggle)) {
          window.removeEventListener('click', closeListener);
          this.showDropdown = false;
        }
      };

      window.addEventListener('click', closeListener);
    },
    catchOutsideClick(event, dropdown) {
      // When user clicks menu — do nothing
      if (dropdown === event.target) {
        return false;
      }
      if (this.showDropdown) {
        return !event.target.closest('.search-container');
      }
    },
    trackSearch(query, type) {
      const eventParams = {
        Search_Type_Name: type,
        Search_Input: query,
        Page_Url: this.$route.path,
        Page_Country: this.$i18n.locale,
      };
      this.$amplitude.track('Navigation | Search Submitted', eventParams);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~/assets/scss/components/_search.scss';
// consolidate with search styles once feature is released
.search-container {
  height: 3.125rem;
}
.autocomplete {
  top: 0.375rem;
  border: 1px solid $grey-200;
  border-radius: 0.25rem;
  box-shadow: none;
  &.focused {
    box-shadow: $box-shadow;
  }
}
.search-input {
  border: none;
}
svg {
  &:hover,
  &:focus {
    color: $sherbert-500;
  }
}

@media #{$medium-up} {
  .search-container,
  .autocomplete {
    min-width: 9rem;
    &.focused {
      min-width: 28rem;
    }
  }
}
</style>
