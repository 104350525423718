<template functional>
  <svg
    aria-labelledby="uniqueTitleID"
    fill="currentColor"
    height="44"
    viewBox="0 0 161 44"
    width="161"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="uniqueTitleID">Teach Starter, part of Tes</title>
    <path
      d="m16.939 36.3608c0 1.1716-.3632 2.0672-1.0901 2.6879-.7268.6206-1.7598.9307-3.1001.9307h-.9828v3.8716h-2.2909v-10.8853h3.4511c1.3107 0 2.3067.284 2.9892.8525.6826.5685 1.0236 1.4157 1.0236 2.5426zm-5.173 1.7274h.7538c.7047 0 1.2314-.1404 1.5814-.4207.35-.2802.5247-.6887.5247-1.2247 0-.5409-.1467-.9408-.4397-1.1988-.293-.2579-.7527-.3871-1.3782-.3871h-1.042z"
      fill="currentColor"
    />
    <path
      d="m23.2122 43.851-.436-1.1317h-.0591c-.3801.4813-.7697.8153-1.1719 1.0014-.4011.1862-.9248.2792-1.5698.2792-.7939 0-1.4178-.2281-1.8739-.6849-.455-.4569-.683-1.1068-.683-1.9508 0-.8833.3061-1.5348.9195-1.9544s1.5381-.6515 2.7754-.6962l1.4337-.0446v-.3649c0-.844-.4286-1.2657-1.2859-1.2657-.6608 0-1.4368.201-2.3278.6031l-.7464-1.5338c.9502-.5015 2.0048-.752 3.1629-.752 1.1085 0 1.9583.2431 2.5495.7297s.8868 1.2258.8868 2.2187v5.5469zm-.6651-3.8567-.872.0297c-.6556.0197-1.1434.1388-1.4632.3574-.321.2186-.4804.551-.4804.9977 0 .6403.3642.9605 1.0937.9605.5215 0 .9396-.1516 1.2521-.4542.3135-.3026.4698-.7046.4698-1.2062z"
      fill="currentColor"
    />
    <path
      d="m31.3174 35.3706c.3051 0 .5584.0223.7611.067l-.1699 2.1294c-.1827-.0494-.4044-.0744-.6651-.0744-.72 0-1.2795.1861-1.6818.5584-.4011.3723-.6017.8934-.6017 1.5635v4.2365h-2.2539v-8.324h1.707l.3326 1.3997h.1108c.2555-.4664.6018-.8424 1.0378-1.128.436-.2855.91-.4281 1.4231-.4281z"
      fill="currentColor"
    />
    <path
      d="m37.0355 42.1908c.3948 0 .8677-.0867 1.4188-.2606v1.6901c-.5616.2531-1.251.3797-2.0692.3797-.9015 0-1.5582-.2297-1.9688-.6887-.4118-.459-.6176-1.1477-.6176-2.0661v-4.0131h-1.079v-.9605l1.2415-.7595.6504-1.7571h1.441v1.772h2.3131v1.705h-2.3131v4.0132c0 .3228.0897.561.2703.7147.1794.1537.417.2309.7126.2309z"
      fill="currentColor"
    />
    <path
      d="m51.1411 39.6741c0 1.3551-.3547 2.415-1.0642 3.1792-.7094.7643-1.6976 1.1466-2.9633 1.1466-.7939 0-1.4928-.1749-2.0988-.5249-.606-.3499-1.0715-.8525-1.3967-1.5077s-.4877-1.4194-.4877-2.2932c0-1.3599.3515-2.4171 1.0567-3.1718.7042-.7546 1.6944-1.1317 2.9708-1.1317.7928 0 1.4928.1739 2.0987.5212.606.3473 1.0716.8461 1.3967 1.4965.3252.6504.4878 1.412.4878 2.2858zm-5.7124 0c0 .8238.134 1.4471.4022 1.8688.2692.4218.7062.6329 1.3122.6329.6007 0 1.0336-.2096 1.2964-.6292.264-.4196.3959-1.0434.3959-1.8725 0-.8238-.133-1.4418-.399-1.8539-.2661-.4122-.7021-.618-1.3081-.618-.6017 0-1.0346.2047-1.3006.6142s-.399 1.0286-.399 1.8577z"
      fill="currentColor"
    />
    <path
      d="m57.358 37.232h-1.9509v6.6191h-2.254v-6.6191h-1.2415v-1.0945l1.2415-.6105v-.6105c0-.9483.2323-1.6407.6947-2.0773.4634-.4367 1.2046-.6552 2.2244-.6552.7791 0 1.4706.1164 2.0766.3499l-.5765 1.6678c-.4529-.1441-.872-.2159-1.2563-.2159-.3198 0-.551.0957-.6946.2866-.1425.1909-.2143.4356-.2143.7334v.5212h1.9509z"
      fill="currentColor"
    />
    <g fill="currentColor">
      <path
        d="m74.0418 36.8393c0-.8942.4621-1.3765 1.1253-1.3765.6685 0 1.1117.4481 1.1117 1.2163 0 .5079-.1958 1.04-.7388 1.3404l-1.4982.782zm4.6832 3.5877c-.6657.4217-1.5761.846-2.7543.846-.3654 0-.7905-.0715-1.0074-.1951l2.035-1.0466c1.229-.6748 1.9921-1.5894 1.9915-3.2472 0-.1003.0012-.3856 0-.4919-.0246-1.8002-1.5144-3.2996-3.7825-3.2996-2.5719 0-4.1395 1.559-4.1395 4.0897v2.7129c0 1.1401.4192 2.0585.9688 2.6731.8058.9002 2.059 1.416 3.5522 1.416 1.4615 0 2.5041-.275 3.2308-.7275.1017-.0633.1845-.1536.1805-.3392v-2.2548c0-.1508-.1323-.2264-.2751-.1358zm5.5352-3.112c-.9044-.2835-1.5069-.4048-1.5069-1.073 0-.5264.3816-.83 1.1048-.83.7828 0 1.4964.227 2.1521.5679.1108.0574.2297.0103.2421-.1101l.2188-2.1481c.0087-.1035-.0342-.1938-.1366-.2355-.7373-.2995-1.6056-.5036-2.6572-.5036-2.4913 0-3.7974 1.2755-3.7974 3.2795 0 1.8023 1.0649 2.632 3.1345 3.1985.8437.2634 1.1453.466 1.1453 1.053 0 .5669-.3616.9112-1.1251.9112-1.0074 0-2.0767-.465-2.9649-.9978-.1522-.0915-.2729-.015-.2729.1358v2.2548c0 .1495.0438.2486.2007.337.7158.4045 1.8153.7237 3.1775.7237 2.331 0 3.8379-1.2216 3.8379-3.4252 0-1.9033-1.0447-2.6116-2.7527-3.1381zm-13.799 3.53c-.0252-.1135-.1478-.1699-.2499-.116-.7027.3719-1.1244.4744-1.5343.3665-.4117-.1082-.6498-.5177-.6498-1.2576l-.0025-4.26h1.8262c.0872 0 .1572-.0712.1566-.1583v-2.157c0-.0912-.0704-.1564-.1566-.1564h-1.8262v-3.2187c0-.0919-.0697-.1624-.1562-.1624h-2.6812c-.0859 0-.1565.069-.1565.1608l-.0012 3.2203h-1.3856c-.0862 0-.1562.0705-.1562.1574v2.1566c0 .0872.07.1577.1562.1577h1.3856v5.0388c0 2.5013 1.4328 3.2572 2.9823 3.2572 1.2978 0 2.3787-.354 3.2588-.8967.0831-.0511.1046-.1891.0479-.2687-.4064-.5494-.6952-1.126-.8574-1.8635z"
      />
      <path
        d="m19.6717 3.41181c.0625.01064.1265.00746.1878-.00931.0611-.01678.1179-.04676.1664-.08786.0486-.04111.0876-.09236.1146-.15018.0268-.05784.0409-.12086.0414-.18473v-2.293176c.0011-.097873-.0302-.19335-.0891-.27116-.059-.077811-.1421-.133478-.2362-.158104-.8345-.2025201-1.693-.2861677-2.5508-.24851514-.6281.00929064-1.2538.08405094-1.8668.22310514h-.0504c-1.2915.287802-2.5089.84479-3.5738 1.63515-.075.05617-.1659.0865-.2593.0865s-.1843-.03034-.2593-.0865c-1.35386-1.036433-3.48976-1.736813-5.44628-1.736813-3.59068 0-5.84992 1.892133-5.84992 4.219203 0 2.5558 2.22 4.19096 5.67053 4.19096 1.11511-.00479 2.21894-.22529 3.25151-.64955.06654-.02926.1393-.04125.21163-.03489.07233.00637.14192.03089.20241.07134.0605.04045.10998.09552.1439.1602.03392.06467.05122.13689.0503.21003v12.70839c0 .1169.04608.2289.12808.3115.082.0827.19322.129.30919.129h2.47505c.116 0 .2272-.0463.3092-.129.082-.0826.1281-.1946.1281-.3115v-15.59179c-.0005-.07225.0166-.14353.0498-.20756s.0816-.11882.1408-.15958c1.3567-.93478 2.7329-1.53349 3.8991-1.69447.8991-.1052 1.8084-.08525 2.7021.05931zm-11.06079 1.1155c-.87602.54451-1.88307.83748-2.91234.84725-1.42113 0-2.15833-.46033-2.15833-1.04775s.78765-1.04774 2.20878-1.04774c.79607 0 2.07985.22594 2.8675.80488.03741.02369.06814.05667.08925.09577.02112.03909.03192.08299.03136.12751-.00056.04451-.01246.08812-.03457.12667-.02211.03854-.05366.07069-.09165.09341z"
      />
      <path
        d="m22.4242 6.24159c-3.9242 0-7.3607 3.17429-7.4252 7.69571-.0644 4.5213 3.1479 7.7775 7.3917 7.8452 2.5226.0369 5.2584-1.0477 6.7888-3.3889.028-.0424.0471-.09.0564-.1401.0094-.0499.0087-.1014-.002-.1511s-.0313-.0969-.0603-.1385c-.0291-.0416-.0661-.077-.1089-.104l-1.9229-1.2031c-.0821-.0501-.1799-.0672-.274-.0478-.0941.0193-.1774.0738-.2333.1524-.8719 1.2114-2.7583 1.8158-4.1682 1.7961-1.8836-.0311-3.6272-1.2568-4.0644-3.0585-.0101-.0456-.0098-.093.0006-.1384.0105-.0455.0311-.0881.0602-.1243.0292-.0364.066-.0658.1078-.0857.042-.02.0877-.0304.1341-.0301h10.5898c.099.0001.194-.039.2648-.1086.0707-.0697.1115-.1645.1136-.2642v-.7483c.073-4.60048-3.2906-7.69852-7.2486-7.75781zm3.4758 6.04071-6.9684.0367c-.0506-.001-.1003-.0146-.1447-.0395-.0443-.0248-.082-.0603-.1097-.1031-.0276-.0429-.0444-.092-.049-.1429-.0044-.0509.0035-.1022.0232-.1494.5942-1.3838 2.3461-2.45693 3.9242-2.43151s3.2291 1.00541 3.6215 2.41741c.015.0473.0187.0974.0109.1464-.0079.0489-.027.0953-.0559.1355-.0289.0401-.0668.0728-.1106.0955-.0439.0226-.0923.0346-.1415.0349z"
      />
      <path
        d="m144.227 6.24148c-3.91 0-7.347 3.17429-7.411 7.69562-.065 4.5214 3.145 7.7776 7.389 7.8453 2.522.0368 5.261-1.0477 6.791-3.3889.029-.0424.047-.0901.058-.1401.008-.0499.008-.1014-.003-.1511-.01-.0498-.032-.0969-.06-.1385-.03-.0416-.066-.077-.109-.104l-1.926-1.2031c-.081-.05-.179-.0669-.273-.0475-.094.0193-.177.0736-.231.152-.872 1.2115-2.759 1.8158-4.169 1.7961-1.883-.031-3.627-1.2567-4.064-3.0584-.01-.0456-.01-.093 0-.1384.011-.0455.032-.0881.061-.1243.029-.0364.066-.0658.107-.0858.042-.02.088-.0303.135-.03h10.59c.099 0 .193-.039.263-.1088s.111-.1646.113-.264v-.7484c.075-4.60041-3.289-7.69843-7.261-7.75772zm3.492 6.04072-6.97.0367c-.051-.001-.101-.0146-.145-.0395-.045-.0248-.082-.0603-.11-.1031-.027-.0429-.044-.092-.049-.1429-.004-.051.004-.1022.023-.1494.595-1.3838 2.347-2.45694 3.924-2.43152 1.578.02542 3.227 1.00532 3.609 2.41742.014.0459.018.0948.013.1427-.008.048-.025.0937-.052.1336-.027.04-.064.0731-.106.0966-.041.0236-.088.0371-.137.0394z"
      />
      <path
        d="m58.4853 16.4705-2.4415-.6129c-.091-.0236-.1874-.0116-.27.0334-.0827.045-.1454.1198-.1757.2094-.1996.6309-.5721 1.1918-1.0744 1.6185-.5025.4268-1.1142.7017-1.765.7934-1.9621.2372-4.6251-.7823-4.6251-4.496 0-3.7136 2.6573-4.5722 4.6588-4.35756.577.04159 1.1317.24147 1.604.57796.4724.3365.8444.7969 1.0757 1.3311.0374.079.1009.1424.1797.1795.0787.0371.1677.0455.2519.0239l2.4667-.6383c.0519-.0138.1003-.0385.1422-.0725.0418-.034.0761-.0766.1003-.1249.0245-.0482.0385-.1011.041-.1552.0026-.0541-.0061-.1081-.0257-.1586-1.0512-2.64331-3.26-4.06384-5.9452-4.23611-4.5381-.28241-7.8485 2.72807-7.8485 7.62501-.014 5.202 3.0974 7.7636 7.742 7.7636 2.803 0 5.222-1.5929 6.1667-4.8208.0147-.0488.0195-.1.0145-.1506-.005-.0508-.02-.1-.044-.1449-.0239-.0449-.0564-.0845-.0956-.1167-.0393-.0322-.0844-.0562-.1328-.0707z"
      />
      <path
        d="m68.0014 6.39126c-1.4604-.01584-2.8805.48372-4.0139 1.41205-.0544.04413-.1198.07212-.1892.08084-.0692.0087-.1395-.00223-.203-.03157-.0634-.02933-.1175-.0759-.1562-.13449s-.0603-.12688-.0626-.19719v-6.42483c0-.100364-.0395-.196615-.1101-.267585-.0704-.07097-.1659-.110834-.2656-.110834h-2.5227c-.0995 0-.1951.039875-.2656.110834-.0704.07097-.11.167221-.11.267585v19.93523c.0008.1001.0406.1961.1108.2669.0703.0708.1654.1109.2648.1116h2.5227c.0494 0 .0983-.0098.1438-.0288.0456-.0191.087-.0469.1218-.0821.0349-.0351.0626-.0768.0814-.1227.019-.0459.0287-.0951.0287-.1449v-8.139c-.0004-.0413.0063-.0824.0196-.1214.6502-2.0164 2.2425-3.0924 3.9606-3.0924 1.5781 0 2.7218.9602 2.7218 3.1065v8.2463c0 .0498.0098.099.0286.1449.0189.0459.0466.0876.0814.1227.035.0352.0764.063.1219.0821.0456.019.0944.0288.1437.0288h2.5088c.0492 0 .0981-.0098.1438-.0288.0455-.0191.0869-.0469.1218-.0821.0348-.0351.0625-.0768.0813-.1227.0189-.0459.0286-.0951.0286-.1449v-8.9382c-.0056-3.65438-2.2368-5.70184-5.337-5.70184z"
      />
      <path
        d="m44.027 18.724c-.1408.0177-.2836.0033-.4179-.0423-.1344-.0456-.2568-.1212-.358-.2211-.1014-.0998-.1791-.2215-.2272-.3559-.0481-.1343-.0655-.2779-.051-.4199v-5.9843c0-3.42281-2.1471-5.36578-5.6592-5.36578-2.9797 0-5.1716 1.36403-6.077 3.77018-.0183.0472-.027.0976-.0256.1483s.013.1005.0339.1466.0508.0875.0879.1217c.0371.0343.0807.0607.1281.0776l2.3293.8473c.0891.0326.1872.0301.2746-.0071.0872-.0372.1575-.1063.1963-.1935.5186-1.1296 1.5557-1.75937 3.0272-1.75937 1.3091 0 2.2061.51967 2.2061 1.31317 0 1.1297-1.7968 1.5363-5.1969 2.4683-2.2425.6382-3.7532 2.0898-3.7532 4.2361 0 2.5162 2.119 4.2135 5.1631 4.2135 1.5215.0118 3.0132-.4251 4.2915-1.2566.0813-.0534.18-.0732.2755-.0554.0954.018.1805.0721.2375.1514.2988.3727.6787.6712 1.1103.8725.4315.2012.9033.2998 1.3788.2881.3838-.0001.7657-.0534 1.1351-.1581.08-.0221.1507-.0699.2014-.1362.0505-.0662.0782-.1473.0789-.2309v-2.0841c.0009-.0515-.0087-.1024-.0281-.1499-.0194-.0476-.0483-.0907-.0847-.1266-.0365-.036-.0798-.064-.1274-.0824-.0476-.0186-.0985-.0273-.1493-.0253zm-4.2915-1.6239c-.0006.0531-.0118.1055-.033.154-.0212.0486-.0519.0922-.0904.1284-.9938.8822-2.269 1.3753-3.5935 1.3896v.0168c-1.3089 0-2.1779-.5223-2.1779-1.4797 0-1.6635 2.5647-1.4122 5.4239-2.7281.0514-.0236.1078-.0338.1641-.0297.0564.004.1108.0221.1583.0528.0477.0304.087.0725.1144.1222.0273.0498.042.1056.0425.1624z"
      />
      <path
        d="m115.238 18.724c-.617.0452-1.051-.3559-1.051-1.0392v-5.9843c0-3.42281-2.15-5.36578-5.662-5.36578-2.977 0-5.169 1.36403-6.077 3.77018-.018.0472-.027.0976-.025.1481.002.0506.013.1003.034.1463s.051.0874.088.1217c.037.0342.08.0608.127.0781l2.332.8472c.089.0318.186.0289.273-.0082.087-.0372.156-.1058.195-.1923.519-1.1296 1.556-1.75939 3.028-1.75939 1.309 0 2.206.51969 2.206 1.31319 0 1.1296-1.797 1.5363-5.197 2.4683-2.243.6382-3.754 2.0898-3.754 4.2361 0 2.5162 2.119 4.2135 5.166 4.2135 1.521.0114 3.012-.4254 4.289-1.2566.082-.0535.181-.0735.277-.0555.096.0178.181.072.239.1514.297.3735.677.6726 1.108.874s.903.2995 1.378.2867c.384-.0001.766-.0534 1.135-.1581.08-.0221.151-.0699.202-.1362.05-.0662.078-.1473.079-.2309v-2.0841c0-.0515-.009-.1024-.029-.1499-.019-.0476-.048-.0906-.084-.1266-.037-.036-.08-.064-.128-.0824-.047-.0186-.098-.0273-.149-.0253zm-4.291-1.6239c-.001.0531-.012.1055-.033.154-.022.0486-.052.0922-.091.1284-.994.8822-2.269 1.3754-3.593 1.3896v.0168c-1.307 0-2.178-.5223-2.178-1.4797 0-1.6635 2.565-1.4122 5.424-2.7281.051-.0235.107-.0338.164-.0297.056.004.111.0221.158.0528.048.0305.087.0725.114.1222.028.0498.042.1056.043.1625z"
      />
      <path
        d="m124.824 7.06895c-1.681-.29654-3.083-.04237-4.179 1.05621-.034.03555-.077.06014-.125.07061-.047.01046-.097.00631-.142-.01194-.045-.01822-.084-.04972-.112-.09037-.027-.04065-.041-.08864-.041-.13774v-.83312c0-.31911-.169-.48857-.376-.48857h-2.523c-.099 0-.195.03988-.265.11085-.071.07097-.11.16722-.11.26757v14.04425c0 .1004.039.1966.11.2676.07.0709.166.1109.265.1109h2.523c.1 0 .195-.04.266-.1109.07-.071.11-.1672.11-.2676v-6.422c0-3.2562.927-4.2362 4.535-4.2926.098-.0022.192-.043.261-.1136.069-.0707.108-.1657.109-.26487v-2.54168c-.004-.08501-.036-.16623-.092-.23036-.055-.06413-.131-.10738-.214-.12264z"
      />
      <path
        d="m160.695 7.06895c-1.682-.29654-3.083-.04237-4.18 1.05621-.033.03555-.077.06014-.124.07061-.047.01046-.097.00631-.143-.01194-.044-.01822-.084-.04972-.111-.09037s-.042-.08864-.042-.13774v-.83312c0-.31911-.168-.48857-.375-.48857h-2.523c-.1 0-.196.03988-.266.11085s-.11.16722-.11.26757v14.04425c0 .1004.04.1966.11.2676.07.0709.166.1109.266.1109h2.523c.1 0 .194-.04.265-.1109.07-.071.11-.1672.11-.2676v-6.422c0-3.2562.929-4.2362 4.535-4.2926.099-.0022.193-.043.262-.1136.068-.0707.108-.1657.108-.26487v-2.54168c-.003-.08501-.036-.16623-.091-.23036-.056-.06413-.132-.10738-.214-.12264z"
      />
      <path
        d="m83.7123 9.01192c-3.5572-1.30191-4.7372-2.25928-4.6728-3.57813.0673-1.41205 1.5754-2.17174 3.8599-2.06159 2.3405.11579 3.6635 1.07034 4.2045 2.93706.0233.09155.0794.17115.1576.22333.0783.05218.1729.07324.2656.05909l2.6826-.45186c.0524-.00927.1022-.02964.146-.05976.044-.03011.0811-.06929.109-.11494.0278-.04565.0457-.09673.0525-.14986.0067-.05314.0023-.10712-.0133-.15836-.8073-3.45387-3.2207-5.32907-7.2794-5.529587-4.4848-.2146313-7.3831 1.745297-7.5486 5.083367-.1457 2.98224 1.9622 5.17372 6.6937 6.83432 3.4842 1.2172 4.9249 2.3637 4.8521 3.8379-.0785 1.6409-1.9622 2.7564-4.5662 2.6265-2.604-.13-4.0896-1.3726-4.5689-3.7391-.0203-.0981-.0783-.184-.1612-.2391-.0831-.055-.1844-.0747-.2818-.0547l-2.5227.4293c-.0933.0242-.1741.083-.2261.1647-.0521.0817-.0714.1803-.0542.2758.6279 3.9369 3.3636 6.1792 7.7925 6.4022 4.6193.226 7.7532-2.005 7.9269-5.6199.1458-3.0387-1.8976-5.2782-6.8477-7.11668z"
      />
      <path
        d="m135.74 7.10284h-3.271c-.1 0-.196-.03987-.266-.11085-.071-.07097-.11-.16722-.11-.26757v-3.22229c.001-.05041-.008-.10053-.026-.14743-.019-.0469-.046-.08961-.081-.12566-.035-.03604-.077-.06467-.123-.08421-.046-.01955-.096-.02961-.146-.0296h-2.553c-.099.00073-.194.04095-.264.11183-.07.07089-.109.16672-.109.26661v3.2251c-.001.10014-.041.19597-.111.26677-.07.07081-.165.11093-.265.11166h-1.682c-.099 0-.195.03987-.265.11084-.071.07097-.11.16722-.11.2676v2.48519c0 .10037.039.19667.11.26757.07.071.166.1109.265.1109h1.682c.1.0007.195.0408.265.1116s.11.1667.111.2668v6.5773c0 3.194 1.326 4.4678 4.546 4.4678.561 0 1.752-.1046 2.447-.178.093-.0098.178-.0535.24-.1228.062-.0694.097-.1594.097-.2528v-2.4908c0-.0509-.01-.1013-.03-.1482s-.049-.0892-.085-.1246c-.036-.0352-.08-.0628-.127-.081s-.097-.0266-.148-.0247l-2.281.082c-.799 0-1.351-.3333-1.351-1.9148v-5.8007c0-.1004.039-.1966.109-.2676.071-.071.166-.1108.266-.1108h3.274c.049 0 .098-.0098.144-.0288.045-.019.087-.0469.122-.0821.034-.0351.062-.0768.081-.1227s.029-.09514.029-.14485v-2.46826c0-.05041-.01-.10032-.03-.1468-.019-.04648-.048-.08857-.083-.12384-.036-.03524-.078-.06294-.125-.08145-.047-.01852-.096-.02748-.146-.02636z"
      />
      <path
        d="m100.478 7.10284h-3.2715c-.0996 0-.1952-.03987-.2656-.11085-.0705-.07097-.1101-.16722-.1101-.26757v-3.22229c.0012-.05041-.0077-.10053-.0261-.14743s-.0458-.08961-.0808-.12566c-.035-.03604-.0768-.06467-.123-.08421-.0461-.01955-.0956-.02961-.1456-.0296h-2.5536c-.0997 0-.1952.03987-.2656.11084s-.1101.16722-.1101.2676v3.2251c0 .10037-.0395.19663-.1099.2676s-.166.11084-.2656.11084h-1.6819c-.0985.00149-.1926.04201-.2619.11281-.0692.07082-.1081.16623-.1081.26563v2.48519c0 .10036.0396.19666.1101.26756.0704.071.1659.1109.2656.1109h1.6818c.0996 0 .1951.0398.2655.1108.0706.071.1101.1672.1101.2676v6.5774c0 3.1939 1.3258 4.4677 4.5492 4.4677.5608 0 1.7492-.1046 2.4441-.178.093-.0092.178-.0526.241-.1221.062-.0695.096-.1598.096-.2535v-2.4908c0-.0509-.011-.1013-.03-.1482-.02-.0469-.049-.0892-.085-.1245-.037-.0353-.08-.0629-.127-.081-.047-.0183-.098-.0267-.148-.0248l-2.2817.082c-.7989 0-1.3482-.3333-1.3482-1.9148v-5.8007c0-.0998.0391-.1957.109-.2666.0698-.0709.1646-.1111.2638-.1118h3.2741c.049 0 .098-.0098.144-.0288.045-.019.087-.0469.121-.082.035-.0352.063-.0769.082-.1228s.028-.09512.028-.14483v-2.46826c0-.05065-.01-.1008-.029-.14747-.02-.04667-.049-.08889-.084-.1242-.037-.03529-.079-.06292-.126-.08126-.047-.01833-.097-.02705-.147-.02554z"
      />
    </g>
  </svg>
</template>

<script>
/* TsTesLogo SVG */

export default {
  name: 'TsTesLogo',
};
</script>
