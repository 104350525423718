import { Ref, useStore, computed, ref } from '@nuxtjs/composition-api';
import { State } from '~/store';
import { useTwoRowHeader } from '~/composables/useTwoRowHeader';

interface UseRibbonMenu {
  toggleMegaMenu: () => void;
  megaMenuOpen: Ref<boolean>;
  scroll: () => void;
  shouldMenuBeVisible: () => boolean;
  hasReachedEndOfScroll: Ref<boolean>;
}

const OPTIONS = {
  NUMBER_OF_ELEMENTS_TO_SCROLL: 3,
  SCROLL_DURATION_MS: 500,
  SCROLL_AMPLITUDE: 2,
};

export const useRibbonMenu = (
  scrollableElement: Ref<HTMLElement | null>,
  ribbonItemsElement: Ref<HTMLElement | null>
): UseRibbonMenu => {
  const store = useStore<State>();
  const megaMenuOpen = computed(() => store.getters.megaMenuOpen);
  const { navigationUserClickedEvent } = useTwoRowHeader();
  const toggleMegaMenu = (): void => {
    navigationUserClickedEvent('Mega Menu', megaMenuOpen.value ? 'Close' : 'Open');
    store.dispatch('toggleMegaMenu', !megaMenuOpen.value);
  };

  const hasReachedEndOfScroll = ref(false);

  const ease = (deltaTime: number): number => deltaTime * (OPTIONS.SCROLL_AMPLITUDE - deltaTime);

  const getJumpWidth = (): number => {
    if (!ribbonItemsElement.value) {
      return 0;
    }
    const items = ribbonItemsElement.value.querySelectorAll('li');
    let width = 0;
    for (let i = 0; i < OPTIONS.NUMBER_OF_ELEMENTS_TO_SCROLL; i++) {
      width += items[i].clientWidth;
    }
    return width;
  };

  const scrollRibbonMenuRight = (): void => {
    if (!scrollableElement.value) {
      return;
    }
    const jumpWidth = getJumpWidth();
    const width = scrollableElement.value.clientWidth;
    const scrollWidth = scrollableElement.value.scrollWidth;
    const scrollableElementScrollLeft = scrollableElement.value.scrollLeft;
    const scrollTarget = Math.min(scrollableElementScrollLeft + jumpWidth, scrollWidth - width);
    const startTime = Date.now();
    const scroll = (): void => {
      if (!scrollableElement.value) {
        return;
      }
      const deltaTime = Date.now() - startTime;
      const scrollLeft = Math.round(
        scrollableElementScrollLeft +
          (scrollTarget - scrollableElementScrollLeft) *
            ease(deltaTime / OPTIONS.SCROLL_DURATION_MS)
      );
      scrollableElement.value.scrollLeft = scrollLeft;
      if (Math.ceil(scrollableElement.value.scrollLeft) >= scrollWidth - width - 1) {
        hasReachedEndOfScroll.value = true;
      } else {
        hasReachedEndOfScroll.value = false;
      }
      if (scrollLeft < scrollTarget) {
        requestAnimationFrame(scroll);
      }
    };
    requestAnimationFrame(scroll);
  };

  const returnRibbonMenuToStart = (): void => {
    if (!scrollableElement.value) {
      return;
    }
    const startTime = Date.now();
    const scrollableElementScrollLeft = scrollableElement.value.scrollLeft;
    const scroll = (): void => {
      if (!scrollableElement.value) {
        return;
      }
      const deltaTime = Date.now() - startTime;
      const scrollLeft = Math.round(
        scrollableElementScrollLeft -
          scrollableElementScrollLeft * ease(deltaTime / OPTIONS.SCROLL_DURATION_MS)
      );
      scrollableElement.value.scrollLeft = scrollLeft;
      if (Math.floor(scrollableElement.value.scrollLeft) <= 0) {
        hasReachedEndOfScroll.value = false;
      } else {
        hasReachedEndOfScroll.value = true;
      }
      if (scrollLeft > 0) {
        requestAnimationFrame(scroll);
      } else {
        scrollableElement.value.scrollLeft = 0;
      }
    };
    requestAnimationFrame(scroll);
  };

  const scroll = (): void => {
    if (hasReachedEndOfScroll.value) {
      returnRibbonMenuToStart();
    }
    scrollRibbonMenuRight();
  };

  const shouldMenuBeVisible = (): boolean => {
    if (!scrollableElement.value) {
      return false;
    }
    return scrollableElement.value.scrollWidth > scrollableElement.value.clientWidth;
  };

  return {
    toggleMegaMenu,
    megaMenuOpen,
    scroll,
    shouldMenuBeVisible,
    hasReachedEndOfScroll,
  };
};
