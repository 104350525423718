<template>
  <nuxt />
</template>

<script>
/* Blank layout
 *
 * @parent:
 * @requests:
 * @events:
 * @props:
 * @components:
 * @methods:
 */

import { getLocaleString } from '~/utils/localeString';

export default {
  name: 'BlankLayout',
  head() {
    return {
      htmlAttrs: {
        lang: getLocaleString(this.$i18n?.locale),
      },
    };
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/_reset.scss';
body {
  max-width: 70rem;
  margin: 1.5rem;
}
</style>
