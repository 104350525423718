import { Plugin } from '@nuxt/types';

declare global {
  interface Window {
    dataLayer: {
      push: (...args: any[]) => void;
    };
    gtag?: (...args: any[]) => void;
  }
}

interface GTMPlugin {
  $gtm: any;
}

declare module '@nuxt/types' {
  interface Context extends GTMPlugin {}
}

const gtmPlugin: Plugin = ({ $gtm, $config }) => {
  $gtm.init($config.googleTagManagerId);
};

export default gtmPlugin;
