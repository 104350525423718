import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dd2b52f8 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9c1aba96 = () => interopDefault(import('../pages/australian-curriculum-v9/index.vue' /* webpackChunkName: "pages/australian-curriculum-v9/index" */))
const _57ab2ebe = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _146e32ac = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _8404e188 = () => interopDefault(import('../pages/classroom-management/index.vue' /* webpackChunkName: "pages/classroom-management/index" */))
const _6a7e47cf = () => interopDefault(import('../pages/curriculums/index.vue' /* webpackChunkName: "pages/curriculums/index" */))
const _be7c76f2 = () => interopDefault(import('../pages/file-formats.vue' /* webpackChunkName: "pages/file-formats" */))
const _9b8855ce = () => interopDefault(import('../pages/grads.vue' /* webpackChunkName: "pages/grads" */))
const _3a357cec = () => interopDefault(import('../pages/hub/index.vue' /* webpackChunkName: "pages/hub/index" */))
const _133d28da = () => interopDefault(import('../pages/learning-areas.vue' /* webpackChunkName: "pages/learning-areas" */))
const _6f53c5de = () => interopDefault(import('../pages/lesson-plans.vue' /* webpackChunkName: "pages/lesson-plans" */))
const _b4d4bac6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ada36c5e = () => interopDefault(import('../pages/new-brand/index.vue' /* webpackChunkName: "pages/new-brand/index" */))
const _e6850566 = () => interopDefault(import('../pages/one-million-trees-project.vue' /* webpackChunkName: "pages/one-million-trees-project" */))
const _eb25b5c4 = () => interopDefault(import('../pages/plans.vue' /* webpackChunkName: "pages/plans" */))
const _0087be6b = () => interopDefault(import('../pages/podcasts.vue' /* webpackChunkName: "pages/podcasts" */))
const _c601cc50 = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _ec80add6 = () => interopDefault(import('../pages/report-an-error.vue' /* webpackChunkName: "pages/report-an-error" */))
const _d29e9414 = () => interopDefault(import('../pages/resource-types.vue' /* webpackChunkName: "pages/resource-types" */))
const _3d5a261a = () => interopDefault(import('../pages/school/index.vue' /* webpackChunkName: "pages/school/index" */))
const _7ede4286 = () => interopDefault(import('../pages/search_a.vue' /* webpackChunkName: "pages/search_a" */))
const _36b00402 = () => interopDefault(import('../pages/signup-complete.vue' /* webpackChunkName: "pages/signup-complete" */))
const _59789e14 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _0e3dfa2e = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _23b1a841 = () => interopDefault(import('../pages/suggest-a-change.vue' /* webpackChunkName: "pages/suggest-a-change" */))
const _2b745f5f = () => interopDefault(import('../pages/suggest-a-resource.vue' /* webpackChunkName: "pages/suggest-a-resource" */))
const _4249b224 = () => interopDefault(import('../pages/teacher-organisation/index.vue' /* webpackChunkName: "pages/teacher-organisation/index" */))
const _fd463cec = () => interopDefault(import('../pages/teaching-resource-collections.vue' /* webpackChunkName: "pages/teaching-resource-collections" */))
const _57cf8caf = () => interopDefault(import('../pages/teaching-resource-packs.vue' /* webpackChunkName: "pages/teaching-resource-packs" */))
const _01970c21 = () => interopDefault(import('../pages/teaching-resources.vue' /* webpackChunkName: "pages/teaching-resources" */))
const _f64f0804 = () => interopDefault(import('../pages/testimonials-reviews.vue' /* webpackChunkName: "pages/testimonials-reviews" */))
const _c7f43946 = () => interopDefault(import('../pages/unit-plans.vue' /* webpackChunkName: "pages/unit-plans" */))
const _195ae4d0 = () => interopDefault(import('../pages/upgrade.vue' /* webpackChunkName: "pages/upgrade" */))
const _f4b1b978 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _18868854 = () => interopDefault(import('../pages/videos.vue' /* webpackChunkName: "pages/videos" */))
const _fd5d2876 = () => interopDefault(import('../pages/webinars.vue' /* webpackChunkName: "pages/webinars" */))
const _f5ef76f0 = () => interopDefault(import('../pages/whats-buzzing/index.vue' /* webpackChunkName: "pages/whats-buzzing/index" */))
const _5153b7e3 = () => interopDefault(import('../pages/widgets.vue' /* webpackChunkName: "pages/widgets" */))
const _cc1bc79a = () => interopDefault(import('../pages/year-levels.vue' /* webpackChunkName: "pages/year-levels" */))
const _54ab7b55 = () => interopDefault(import('../pages/about/privacy-policy.vue' /* webpackChunkName: "pages/about/privacy-policy" */))
const _28b433ae = () => interopDefault(import('../pages/about/school-fonts.vue' /* webpackChunkName: "pages/about/school-fonts" */))
const _065f1fb9 = () => interopDefault(import('../pages/about/teach-starters-suggestion-guidelines.vue' /* webpackChunkName: "pages/about/teach-starters-suggestion-guidelines" */))
const _be7f429c = () => interopDefault(import('../pages/about/terms-and-conditions.vue' /* webpackChunkName: "pages/about/terms-and-conditions" */))
const _489f11c4 = () => interopDefault(import('../pages/engage/dice-roller.vue' /* webpackChunkName: "pages/engage/dice-roller" */))
const _5d594e38 = () => interopDefault(import('../pages/engage/hundreds-chart.vue' /* webpackChunkName: "pages/engage/hundreds-chart" */))
const _369d889e = () => interopDefault(import('../pages/engage/interactive-clock.vue' /* webpackChunkName: "pages/engage/interactive-clock" */))
const _e432242c = () => interopDefault(import('../pages/engage/math-lab.vue' /* webpackChunkName: "pages/engage/math-lab" */))
const _7f95732f = () => interopDefault(import('../pages/engage/name-selector.vue' /* webpackChunkName: "pages/engage/name-selector" */))
const _76af9b47 = () => interopDefault(import('../pages/engage/sentence-starter.vue' /* webpackChunkName: "pages/engage/sentence-starter" */))
const _4f76a432 = () => interopDefault(import('../pages/engage/spin-wheel.vue' /* webpackChunkName: "pages/engage/spin-wheel" */))
const _53453714 = () => interopDefault(import('../pages/engage/toggle.vue' /* webpackChunkName: "pages/engage/toggle" */))
const _0be149b3 = () => interopDefault(import('../pages/engage/visual-writing-prompts.vue' /* webpackChunkName: "pages/engage/visual-writing-prompts" */))
const _cf5ea88a = () => interopDefault(import('../pages/engage/word-of-the-day.vue' /* webpackChunkName: "pages/engage/word-of-the-day" */))
const _08dbb797 = () => interopDefault(import('../pages/hub/bookmarks.vue' /* webpackChunkName: "pages/hub/bookmarks" */))
const _169850f6 = () => interopDefault(import('../pages/hub/downloads.vue' /* webpackChunkName: "pages/hub/downloads" */))
const _79507bdf = () => interopDefault(import('../pages/hub/folders.vue' /* webpackChunkName: "pages/hub/folders" */))
const _f2b421a4 = () => interopDefault(import('../pages/hub/history.vue' /* webpackChunkName: "pages/hub/history" */))
const _7f774c02 = () => interopDefault(import('../pages/hub/notifications.vue' /* webpackChunkName: "pages/hub/notifications" */))
const _0b6093f2 = () => interopDefault(import('../pages/hub/online-learning.vue' /* webpackChunkName: "pages/hub/online-learning" */))
const _a84911a8 = () => interopDefault(import('../pages/hub/studio.vue' /* webpackChunkName: "pages/hub/studio" */))
const _1e45e130 = () => interopDefault(import('../pages/hub/word-lists.vue' /* webpackChunkName: "pages/hub/word-lists" */))
const _c364a2dc = () => interopDefault(import('../pages/member-settings/email-notifications.vue' /* webpackChunkName: "pages/member-settings/email-notifications" */))
const _1f125d94 = () => interopDefault(import('../pages/member-settings/password-reset-request.vue' /* webpackChunkName: "pages/member-settings/password-reset-request" */))
const _ab5bda5c = () => interopDefault(import('../pages/member-settings/password-reset.vue' /* webpackChunkName: "pages/member-settings/password-reset" */))
const _f3c59f86 = () => interopDefault(import('../pages/member-settings/payment-method.vue' /* webpackChunkName: "pages/member-settings/payment-method" */))
const _52519134 = () => interopDefault(import('../pages/member-settings/personalisation.vue' /* webpackChunkName: "pages/member-settings/personalisation" */))
const _e82ca978 = () => interopDefault(import('../pages/member-settings/profile.vue' /* webpackChunkName: "pages/member-settings/profile" */))
const _871dd25e = () => interopDefault(import('../pages/member-settings/referrals.vue' /* webpackChunkName: "pages/member-settings/referrals" */))
const _49b4e296 = () => interopDefault(import('../pages/member-settings/security.vue' /* webpackChunkName: "pages/member-settings/security" */))
const _373fff84 = () => interopDefault(import('../pages/teaching-resource/desk-plates.vue' /* webpackChunkName: "pages/teaching-resource/desk-plates" */))
const _778f4002 = () => interopDefault(import('../pages/hub/folder/_slug.vue' /* webpackChunkName: "pages/hub/folder/_slug" */))
const _085e86c6 = () => interopDefault(import('../pages/australian-curriculum-categories/_slug.vue' /* webpackChunkName: "pages/australian-curriculum-categories/_slug" */))
const _9f87fd26 = () => interopDefault(import('../pages/australian-curriculum-v9/_slug.vue' /* webpackChunkName: "pages/australian-curriculum-v9/_slug" */))
const _5b18714e = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _87722418 = () => interopDefault(import('../pages/classroom-management/_slug.vue' /* webpackChunkName: "pages/classroom-management/_slug" */))
const _62cb9740 = () => interopDefault(import('../pages/common-core-standards-in-america/_slug.vue' /* webpackChunkName: "pages/common-core-standards-in-america/_slug" */))
const _68c7a687 = () => interopDefault(import('../pages/curriculums/_slug.vue' /* webpackChunkName: "pages/curriculums/_slug" */))
const _d1fb7f52 = () => interopDefault(import('../pages/file-format/_slug.vue' /* webpackChunkName: "pages/file-format/_slug" */))
const _0128954a = () => interopDefault(import('../pages/l/_slug.vue' /* webpackChunkName: "pages/l/_slug" */))
const _21e1ae0d = () => interopDefault(import('../pages/lesson-plan/_slug.vue' /* webpackChunkName: "pages/lesson-plan/_slug" */))
const _f2b5a562 = () => interopDefault(import('../pages/national-curriculum-in-england/_slug.vue' /* webpackChunkName: "pages/national-curriculum-in-england/_slug" */))
const _4b63f6dc = () => interopDefault(import('../pages/new-south-wales-curriculum/_slug.vue' /* webpackChunkName: "pages/new-south-wales-curriculum/_slug" */))
const _93be561a = () => interopDefault(import('../pages/podcast/_slug.vue' /* webpackChunkName: "pages/podcast/_slug" */))
const _2c96c6eb = () => interopDefault(import('../pages/publisher/_slug.vue' /* webpackChunkName: "pages/publisher/_slug" */))
const _f4d1fbae = () => interopDefault(import('../pages/resource-availability/_slug.vue' /* webpackChunkName: "pages/resource-availability/_slug" */))
const _232bdc48 = () => interopDefault(import('../pages/resource-type/_slug.vue' /* webpackChunkName: "pages/resource-type/_slug" */))
const _2d550d36 = () => interopDefault(import('../pages/search/_keyword.vue' /* webpackChunkName: "pages/search/_keyword" */))
const _409310dc = () => interopDefault(import('../pages/teacher-organisation/_slug.vue' /* webpackChunkName: "pages/teacher-organisation/_slug" */))
const _068f4cde = () => interopDefault(import('../pages/teaching-blog/_slug.vue' /* webpackChunkName: "pages/teaching-blog/_slug" */))
const _64d95da2 = () => interopDefault(import('../pages/teaching-resource-pack/_slug.vue' /* webpackChunkName: "pages/teaching-resource-pack/_slug" */))
const _bba8ae06 = () => interopDefault(import('../pages/teaching-resource/_slug.vue' /* webpackChunkName: "pages/teaching-resource/_slug" */))
const _0a689290 = () => interopDefault(import('../pages/teks/_slug.vue' /* webpackChunkName: "pages/teks/_slug" */))
const _b664117e = () => interopDefault(import('../pages/unit-plan/_slug.vue' /* webpackChunkName: "pages/unit-plan/_slug" */))
const _2a935e10 = () => interopDefault(import('../pages/victorian-curriculum/_slug.vue' /* webpackChunkName: "pages/victorian-curriculum/_slug" */))
const _7890d4aa = () => interopDefault(import('../pages/video/_slug.vue' /* webpackChunkName: "pages/video/_slug" */))
const _1aef6fd9 = () => interopDefault(import('../pages/webinar/_slug.vue' /* webpackChunkName: "pages/webinar/_slug" */))
const _793708d5 = () => interopDefault(import('../pages/whats-buzzing/_year/index.vue' /* webpackChunkName: "pages/whats-buzzing/_year/index" */))
const _40a3f30a = () => interopDefault(import('../pages/widget/_slug.vue' /* webpackChunkName: "pages/widget/_slug" */))
const _76f1d42b = () => interopDefault(import('../pages/year-level/_slug.vue' /* webpackChunkName: "pages/year-level/_slug" */))
const _36062867 = () => interopDefault(import('../pages/whats-buzzing/_year/_issue.vue' /* webpackChunkName: "pages/whats-buzzing/_year/_issue" */))
const _645447a0 = () => interopDefault(import('../pages/learning-area/_.vue' /* webpackChunkName: "pages/learning-area/_" */))
const _38c79089 = () => interopDefault(import('../pages/teaching-resource-collection/_.vue' /* webpackChunkName: "pages/teaching-resource-collection/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/about/",
    component: _dd2b52f8,
    pathToRegexpOptions: {"strict":true},
    name: "about___default"
  }, {
    path: "/au/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___au"
  }, {
    path: "/australian-curriculum-v9/",
    component: _9c1aba96,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-v9___default"
  }, {
    path: "/blog/",
    component: _57ab2ebe,
    pathToRegexpOptions: {"strict":true},
    name: "blog___default"
  }, {
    path: "/careers/",
    component: _146e32ac,
    pathToRegexpOptions: {"strict":true},
    name: "careers___default"
  }, {
    path: "/classroom-management/",
    component: _8404e188,
    pathToRegexpOptions: {"strict":true},
    name: "classroom-management___default"
  }, {
    path: "/curriculums/",
    component: _6a7e47cf,
    pathToRegexpOptions: {"strict":true},
    name: "curriculums___default"
  }, {
    path: "/file-formats/",
    component: _be7c76f2,
    pathToRegexpOptions: {"strict":true},
    name: "file-formats___default"
  }, {
    path: "/gb/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___gb"
  }, {
    path: "/grads/",
    component: _9b8855ce,
    pathToRegexpOptions: {"strict":true},
    name: "grads___default"
  }, {
    path: "/hub/",
    component: _3a357cec,
    pathToRegexpOptions: {"strict":true},
    name: "hub___default"
  }, {
    path: "/learning-areas/",
    component: _133d28da,
    pathToRegexpOptions: {"strict":true},
    name: "learning-areas___default"
  }, {
    path: "/lesson-plans/",
    component: _6f53c5de,
    pathToRegexpOptions: {"strict":true},
    name: "lesson-plans___default"
  }, {
    path: "/login/",
    component: _b4d4bac6,
    pathToRegexpOptions: {"strict":true},
    name: "login___default"
  }, {
    path: "/new-brand/",
    component: _ada36c5e,
    pathToRegexpOptions: {"strict":true},
    name: "new-brand___default"
  }, {
    path: "/one-million-trees-project/",
    component: _e6850566,
    pathToRegexpOptions: {"strict":true},
    name: "one-million-trees-project___default"
  }, {
    path: "/plans/",
    component: _eb25b5c4,
    pathToRegexpOptions: {"strict":true},
    name: "plans___default"
  }, {
    path: "/podcasts/",
    component: _0087be6b,
    pathToRegexpOptions: {"strict":true},
    name: "podcasts___default"
  }, {
    path: "/redirect/",
    component: _c601cc50,
    pathToRegexpOptions: {"strict":true},
    name: "redirect___default"
  }, {
    path: "/report-an-error/",
    component: _ec80add6,
    pathToRegexpOptions: {"strict":true},
    name: "report-an-error___default"
  }, {
    path: "/resource-types/",
    component: _d29e9414,
    pathToRegexpOptions: {"strict":true},
    name: "resource-types___default"
  }, {
    path: "/school/",
    component: _3d5a261a,
    pathToRegexpOptions: {"strict":true},
    name: "school___default"
  }, {
    path: "/search_a/",
    component: _7ede4286,
    pathToRegexpOptions: {"strict":true},
    name: "search_a___default"
  }, {
    path: "/signup-complete/",
    component: _36b00402,
    pathToRegexpOptions: {"strict":true},
    name: "signup-complete___default"
  }, {
    path: "/signup/",
    component: _59789e14,
    pathToRegexpOptions: {"strict":true},
    name: "signup___default"
  }, {
    path: "/sitemap/",
    component: _0e3dfa2e,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___default"
  }, {
    path: "/suggest-a-change/",
    component: _23b1a841,
    pathToRegexpOptions: {"strict":true},
    name: "suggest-a-change___default"
  }, {
    path: "/suggest-a-resource/",
    component: _2b745f5f,
    pathToRegexpOptions: {"strict":true},
    name: "suggest-a-resource___default"
  }, {
    path: "/teacher-organisation/",
    component: _4249b224,
    pathToRegexpOptions: {"strict":true},
    name: "teacher-organisation___default"
  }, {
    path: "/teaching-resource-collections/",
    component: _fd463cec,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-collections___default"
  }, {
    path: "/teaching-resource-packs/",
    component: _57cf8caf,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-packs___default"
  }, {
    path: "/teaching-resources/",
    component: _01970c21,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resources___default"
  }, {
    path: "/testimonials-reviews/",
    component: _f64f0804,
    pathToRegexpOptions: {"strict":true},
    name: "testimonials-reviews___default"
  }, {
    path: "/unit-plans/",
    component: _c7f43946,
    pathToRegexpOptions: {"strict":true},
    name: "unit-plans___default"
  }, {
    path: "/upgrade/",
    component: _195ae4d0,
    pathToRegexpOptions: {"strict":true},
    name: "upgrade___default"
  }, {
    path: "/us/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___us"
  }, {
    path: "/verify-email/",
    component: _f4b1b978,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email___default"
  }, {
    path: "/videos/",
    component: _18868854,
    pathToRegexpOptions: {"strict":true},
    name: "videos___default"
  }, {
    path: "/webinars/",
    component: _fd5d2876,
    pathToRegexpOptions: {"strict":true},
    name: "webinars___default"
  }, {
    path: "/whats-buzzing/",
    component: _f5ef76f0,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing___default"
  }, {
    path: "/widgets/",
    component: _5153b7e3,
    pathToRegexpOptions: {"strict":true},
    name: "widgets___default"
  }, {
    path: "/year-levels/",
    component: _cc1bc79a,
    pathToRegexpOptions: {"strict":true},
    name: "year-levels___default"
  }, {
    path: "/about/privacy-policy/",
    component: _54ab7b55,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___default"
  }, {
    path: "/about/school-fonts/",
    component: _28b433ae,
    pathToRegexpOptions: {"strict":true},
    name: "about-school-fonts___default"
  }, {
    path: "/about/teach-starters-suggestion-guidelines/",
    component: _065f1fb9,
    pathToRegexpOptions: {"strict":true},
    name: "about-teach-starters-suggestion-guidelines___default"
  }, {
    path: "/about/terms-and-conditions/",
    component: _be7f429c,
    pathToRegexpOptions: {"strict":true},
    name: "about-terms-and-conditions___default"
  }, {
    path: "/au/about/",
    component: _dd2b52f8,
    pathToRegexpOptions: {"strict":true},
    name: "about___au"
  }, {
    path: "/au/australian-curriculum-v9/",
    component: _9c1aba96,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-v9___au"
  }, {
    path: "/au/blog/",
    component: _57ab2ebe,
    pathToRegexpOptions: {"strict":true},
    name: "blog___au"
  }, {
    path: "/au/careers/",
    component: _146e32ac,
    pathToRegexpOptions: {"strict":true},
    name: "careers___au"
  }, {
    path: "/au/classroom-management/",
    component: _8404e188,
    pathToRegexpOptions: {"strict":true},
    name: "classroom-management___au"
  }, {
    path: "/au/curriculums/",
    component: _6a7e47cf,
    pathToRegexpOptions: {"strict":true},
    name: "curriculums___au"
  }, {
    path: "/au/file-formats/",
    component: _be7c76f2,
    pathToRegexpOptions: {"strict":true},
    name: "file-formats___au"
  }, {
    path: "/au/grads/",
    component: _9b8855ce,
    pathToRegexpOptions: {"strict":true},
    name: "grads___au"
  }, {
    path: "/au/hub/",
    component: _3a357cec,
    pathToRegexpOptions: {"strict":true},
    name: "hub___au"
  }, {
    path: "/au/learning-areas/",
    component: _133d28da,
    pathToRegexpOptions: {"strict":true},
    name: "learning-areas___au"
  }, {
    path: "/au/lesson-plans/",
    component: _6f53c5de,
    pathToRegexpOptions: {"strict":true},
    name: "lesson-plans___au"
  }, {
    path: "/au/login/",
    component: _b4d4bac6,
    pathToRegexpOptions: {"strict":true},
    name: "login___au"
  }, {
    path: "/au/new-brand/",
    component: _ada36c5e,
    pathToRegexpOptions: {"strict":true},
    name: "new-brand___au"
  }, {
    path: "/au/one-million-trees-project/",
    component: _e6850566,
    pathToRegexpOptions: {"strict":true},
    name: "one-million-trees-project___au"
  }, {
    path: "/au/plans/",
    component: _eb25b5c4,
    pathToRegexpOptions: {"strict":true},
    name: "plans___au"
  }, {
    path: "/au/podcasts/",
    component: _0087be6b,
    pathToRegexpOptions: {"strict":true},
    name: "podcasts___au"
  }, {
    path: "/au/redirect/",
    component: _c601cc50,
    pathToRegexpOptions: {"strict":true},
    name: "redirect___au"
  }, {
    path: "/au/report-an-error/",
    component: _ec80add6,
    pathToRegexpOptions: {"strict":true},
    name: "report-an-error___au"
  }, {
    path: "/au/resource-types/",
    component: _d29e9414,
    pathToRegexpOptions: {"strict":true},
    name: "resource-types___au"
  }, {
    path: "/au/school/",
    component: _3d5a261a,
    pathToRegexpOptions: {"strict":true},
    name: "school___au"
  }, {
    path: "/au/search_a/",
    component: _7ede4286,
    pathToRegexpOptions: {"strict":true},
    name: "search_a___au"
  }, {
    path: "/au/signup-complete/",
    component: _36b00402,
    pathToRegexpOptions: {"strict":true},
    name: "signup-complete___au"
  }, {
    path: "/au/signup/",
    component: _59789e14,
    pathToRegexpOptions: {"strict":true},
    name: "signup___au"
  }, {
    path: "/au/sitemap/",
    component: _0e3dfa2e,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___au"
  }, {
    path: "/au/suggest-a-change/",
    component: _23b1a841,
    pathToRegexpOptions: {"strict":true},
    name: "suggest-a-change___au"
  }, {
    path: "/au/suggest-a-resource/",
    component: _2b745f5f,
    pathToRegexpOptions: {"strict":true},
    name: "suggest-a-resource___au"
  }, {
    path: "/au/teacher-organisation/",
    component: _4249b224,
    pathToRegexpOptions: {"strict":true},
    name: "teacher-organisation___au"
  }, {
    path: "/au/teaching-resource-collections/",
    component: _fd463cec,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-collections___au"
  }, {
    path: "/au/teaching-resource-packs/",
    component: _57cf8caf,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-packs___au"
  }, {
    path: "/au/teaching-resources/",
    component: _01970c21,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resources___au"
  }, {
    path: "/au/testimonials-reviews/",
    component: _f64f0804,
    pathToRegexpOptions: {"strict":true},
    name: "testimonials-reviews___au"
  }, {
    path: "/au/unit-plans/",
    component: _c7f43946,
    pathToRegexpOptions: {"strict":true},
    name: "unit-plans___au"
  }, {
    path: "/au/upgrade/",
    component: _195ae4d0,
    pathToRegexpOptions: {"strict":true},
    name: "upgrade___au"
  }, {
    path: "/au/verify-email/",
    component: _f4b1b978,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email___au"
  }, {
    path: "/au/videos/",
    component: _18868854,
    pathToRegexpOptions: {"strict":true},
    name: "videos___au"
  }, {
    path: "/au/webinars/",
    component: _fd5d2876,
    pathToRegexpOptions: {"strict":true},
    name: "webinars___au"
  }, {
    path: "/au/whats-buzzing/",
    component: _f5ef76f0,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing___au"
  }, {
    path: "/au/widgets/",
    component: _5153b7e3,
    pathToRegexpOptions: {"strict":true},
    name: "widgets___au"
  }, {
    path: "/au/year-levels/",
    component: _cc1bc79a,
    pathToRegexpOptions: {"strict":true},
    name: "year-levels___au"
  }, {
    path: "/engage/dice-roller/",
    component: _489f11c4,
    pathToRegexpOptions: {"strict":true},
    name: "engage-dice-roller___default"
  }, {
    path: "/engage/hundreds-chart/",
    component: _5d594e38,
    pathToRegexpOptions: {"strict":true},
    name: "engage-hundreds-chart___default"
  }, {
    path: "/engage/interactive-clock/",
    component: _369d889e,
    pathToRegexpOptions: {"strict":true},
    name: "engage-interactive-clock___default"
  }, {
    path: "/engage/math-lab/",
    component: _e432242c,
    pathToRegexpOptions: {"strict":true},
    name: "engage-math-lab___default"
  }, {
    path: "/engage/name-selector/",
    component: _7f95732f,
    pathToRegexpOptions: {"strict":true},
    name: "engage-name-selector___default"
  }, {
    path: "/engage/sentence-starter/",
    component: _76af9b47,
    pathToRegexpOptions: {"strict":true},
    name: "engage-sentence-starter___default"
  }, {
    path: "/engage/spin-wheel/",
    component: _4f76a432,
    pathToRegexpOptions: {"strict":true},
    name: "engage-spin-wheel___default"
  }, {
    path: "/engage/toggle/",
    component: _53453714,
    pathToRegexpOptions: {"strict":true},
    name: "engage-toggle___default"
  }, {
    path: "/engage/visual-writing-prompts/",
    component: _0be149b3,
    pathToRegexpOptions: {"strict":true},
    name: "engage-visual-writing-prompts___default"
  }, {
    path: "/engage/word-of-the-day/",
    component: _cf5ea88a,
    pathToRegexpOptions: {"strict":true},
    name: "engage-word-of-the-day___default"
  }, {
    path: "/gb/about/",
    component: _dd2b52f8,
    pathToRegexpOptions: {"strict":true},
    name: "about___gb"
  }, {
    path: "/gb/australian-curriculum-v9/",
    component: _9c1aba96,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-v9___gb"
  }, {
    path: "/gb/blog/",
    component: _57ab2ebe,
    pathToRegexpOptions: {"strict":true},
    name: "blog___gb"
  }, {
    path: "/gb/careers/",
    component: _146e32ac,
    pathToRegexpOptions: {"strict":true},
    name: "careers___gb"
  }, {
    path: "/gb/classroom-management/",
    component: _8404e188,
    pathToRegexpOptions: {"strict":true},
    name: "classroom-management___gb"
  }, {
    path: "/gb/curriculums/",
    component: _6a7e47cf,
    pathToRegexpOptions: {"strict":true},
    name: "curriculums___gb"
  }, {
    path: "/gb/file-formats/",
    component: _be7c76f2,
    pathToRegexpOptions: {"strict":true},
    name: "file-formats___gb"
  }, {
    path: "/gb/grads/",
    component: _9b8855ce,
    pathToRegexpOptions: {"strict":true},
    name: "grads___gb"
  }, {
    path: "/gb/hub/",
    component: _3a357cec,
    pathToRegexpOptions: {"strict":true},
    name: "hub___gb"
  }, {
    path: "/gb/learning-areas/",
    component: _133d28da,
    pathToRegexpOptions: {"strict":true},
    name: "learning-areas___gb"
  }, {
    path: "/gb/lesson-plans/",
    component: _6f53c5de,
    pathToRegexpOptions: {"strict":true},
    name: "lesson-plans___gb"
  }, {
    path: "/gb/login/",
    component: _b4d4bac6,
    pathToRegexpOptions: {"strict":true},
    name: "login___gb"
  }, {
    path: "/gb/new-brand/",
    component: _ada36c5e,
    pathToRegexpOptions: {"strict":true},
    name: "new-brand___gb"
  }, {
    path: "/gb/one-million-trees-project/",
    component: _e6850566,
    pathToRegexpOptions: {"strict":true},
    name: "one-million-trees-project___gb"
  }, {
    path: "/gb/plans/",
    component: _eb25b5c4,
    pathToRegexpOptions: {"strict":true},
    name: "plans___gb"
  }, {
    path: "/gb/podcasts/",
    component: _0087be6b,
    pathToRegexpOptions: {"strict":true},
    name: "podcasts___gb"
  }, {
    path: "/gb/redirect/",
    component: _c601cc50,
    pathToRegexpOptions: {"strict":true},
    name: "redirect___gb"
  }, {
    path: "/gb/report-an-error/",
    component: _ec80add6,
    pathToRegexpOptions: {"strict":true},
    name: "report-an-error___gb"
  }, {
    path: "/gb/resource-types/",
    component: _d29e9414,
    pathToRegexpOptions: {"strict":true},
    name: "resource-types___gb"
  }, {
    path: "/gb/school/",
    component: _3d5a261a,
    pathToRegexpOptions: {"strict":true},
    name: "school___gb"
  }, {
    path: "/gb/search_a/",
    component: _7ede4286,
    pathToRegexpOptions: {"strict":true},
    name: "search_a___gb"
  }, {
    path: "/gb/signup-complete/",
    component: _36b00402,
    pathToRegexpOptions: {"strict":true},
    name: "signup-complete___gb"
  }, {
    path: "/gb/signup/",
    component: _59789e14,
    pathToRegexpOptions: {"strict":true},
    name: "signup___gb"
  }, {
    path: "/gb/sitemap/",
    component: _0e3dfa2e,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___gb"
  }, {
    path: "/gb/suggest-a-change/",
    component: _23b1a841,
    pathToRegexpOptions: {"strict":true},
    name: "suggest-a-change___gb"
  }, {
    path: "/gb/suggest-a-resource/",
    component: _2b745f5f,
    pathToRegexpOptions: {"strict":true},
    name: "suggest-a-resource___gb"
  }, {
    path: "/gb/teacher-organisation/",
    component: _4249b224,
    pathToRegexpOptions: {"strict":true},
    name: "teacher-organisation___gb"
  }, {
    path: "/gb/teaching-resource-collections/",
    component: _fd463cec,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-collections___gb"
  }, {
    path: "/gb/teaching-resource-packs/",
    component: _57cf8caf,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-packs___gb"
  }, {
    path: "/gb/teaching-resources/",
    component: _01970c21,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resources___gb"
  }, {
    path: "/gb/testimonials-reviews/",
    component: _f64f0804,
    pathToRegexpOptions: {"strict":true},
    name: "testimonials-reviews___gb"
  }, {
    path: "/gb/unit-plans/",
    component: _c7f43946,
    pathToRegexpOptions: {"strict":true},
    name: "unit-plans___gb"
  }, {
    path: "/gb/upgrade/",
    component: _195ae4d0,
    pathToRegexpOptions: {"strict":true},
    name: "upgrade___gb"
  }, {
    path: "/gb/verify-email/",
    component: _f4b1b978,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email___gb"
  }, {
    path: "/gb/videos/",
    component: _18868854,
    pathToRegexpOptions: {"strict":true},
    name: "videos___gb"
  }, {
    path: "/gb/webinars/",
    component: _fd5d2876,
    pathToRegexpOptions: {"strict":true},
    name: "webinars___gb"
  }, {
    path: "/gb/whats-buzzing/",
    component: _f5ef76f0,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing___gb"
  }, {
    path: "/gb/widgets/",
    component: _5153b7e3,
    pathToRegexpOptions: {"strict":true},
    name: "widgets___gb"
  }, {
    path: "/gb/year-levels/",
    component: _cc1bc79a,
    pathToRegexpOptions: {"strict":true},
    name: "year-levels___gb"
  }, {
    path: "/hub/bookmarks/",
    component: _08dbb797,
    pathToRegexpOptions: {"strict":true},
    name: "hub-bookmarks___default"
  }, {
    path: "/hub/downloads/",
    component: _169850f6,
    pathToRegexpOptions: {"strict":true},
    name: "hub-downloads___default"
  }, {
    path: "/hub/folders/",
    component: _79507bdf,
    pathToRegexpOptions: {"strict":true},
    name: "hub-folders___default"
  }, {
    path: "/hub/history/",
    component: _f2b421a4,
    pathToRegexpOptions: {"strict":true},
    name: "hub-history___default"
  }, {
    path: "/hub/notifications/",
    component: _7f774c02,
    pathToRegexpOptions: {"strict":true},
    name: "hub-notifications___default"
  }, {
    path: "/hub/online-learning/",
    component: _0b6093f2,
    pathToRegexpOptions: {"strict":true},
    name: "hub-online-learning___default"
  }, {
    path: "/hub/studio/",
    component: _a84911a8,
    pathToRegexpOptions: {"strict":true},
    name: "hub-studio___default"
  }, {
    path: "/hub/word-lists/",
    component: _1e45e130,
    pathToRegexpOptions: {"strict":true},
    name: "hub-word-lists___default"
  }, {
    path: "/member-settings/email-notifications/",
    component: _c364a2dc,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-email-notifications___default"
  }, {
    path: "/member-settings/password-reset-request/",
    component: _1f125d94,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-password-reset-request___default"
  }, {
    path: "/member-settings/password-reset/",
    component: _ab5bda5c,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-password-reset___default"
  }, {
    path: "/member-settings/payment-method/",
    component: _f3c59f86,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-payment-method___default"
  }, {
    path: "/member-settings/personalisation/",
    component: _52519134,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-personalisation___default"
  }, {
    path: "/member-settings/personalization/",
    component: _52519134,
    name: "personalization___default"
  }, {
    path: "/member-settings/profile/",
    component: _e82ca978,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-profile___default"
  }, {
    path: "/member-settings/referrals/",
    component: _871dd25e,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-referrals___default"
  }, {
    path: "/member-settings/security/",
    component: _49b4e296,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-security___default"
  }, {
    path: "/teaching-resource/desk-plates/",
    component: _373fff84,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-desk-plates___default"
  }, {
    path: "/us/about/",
    component: _dd2b52f8,
    pathToRegexpOptions: {"strict":true},
    name: "about___us"
  }, {
    path: "/us/australian-curriculum-v9/",
    component: _9c1aba96,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-v9___us"
  }, {
    path: "/us/blog/",
    component: _57ab2ebe,
    pathToRegexpOptions: {"strict":true},
    name: "blog___us"
  }, {
    path: "/us/careers/",
    component: _146e32ac,
    pathToRegexpOptions: {"strict":true},
    name: "careers___us"
  }, {
    path: "/us/classroom-management/",
    component: _8404e188,
    pathToRegexpOptions: {"strict":true},
    name: "classroom-management___us"
  }, {
    path: "/us/curriculums/",
    component: _6a7e47cf,
    pathToRegexpOptions: {"strict":true},
    name: "curriculums___us"
  }, {
    path: "/us/file-formats/",
    component: _be7c76f2,
    pathToRegexpOptions: {"strict":true},
    name: "file-formats___us"
  }, {
    path: "/us/grade-levels/",
    component: _cc1bc79a,
    pathToRegexpOptions: {"strict":true},
    name: "year-levels___us"
  }, {
    path: "/us/grads/",
    component: _9b8855ce,
    pathToRegexpOptions: {"strict":true},
    name: "grads___us"
  }, {
    path: "/us/hub/",
    component: _3a357cec,
    pathToRegexpOptions: {"strict":true},
    name: "hub___us"
  }, {
    path: "/us/learning-areas/",
    component: _133d28da,
    pathToRegexpOptions: {"strict":true},
    name: "learning-areas___us"
  }, {
    path: "/us/lesson-plans/",
    component: _6f53c5de,
    pathToRegexpOptions: {"strict":true},
    name: "lesson-plans___us"
  }, {
    path: "/us/login/",
    component: _b4d4bac6,
    pathToRegexpOptions: {"strict":true},
    name: "login___us"
  }, {
    path: "/us/new-brand/",
    component: _ada36c5e,
    pathToRegexpOptions: {"strict":true},
    name: "new-brand___us"
  }, {
    path: "/us/one-million-trees-project/",
    component: _e6850566,
    pathToRegexpOptions: {"strict":true},
    name: "one-million-trees-project___us"
  }, {
    path: "/us/plans/",
    component: _eb25b5c4,
    pathToRegexpOptions: {"strict":true},
    name: "plans___us"
  }, {
    path: "/us/podcasts/",
    component: _0087be6b,
    pathToRegexpOptions: {"strict":true},
    name: "podcasts___us"
  }, {
    path: "/us/redirect/",
    component: _c601cc50,
    pathToRegexpOptions: {"strict":true},
    name: "redirect___us"
  }, {
    path: "/us/report-an-error/",
    component: _ec80add6,
    pathToRegexpOptions: {"strict":true},
    name: "report-an-error___us"
  }, {
    path: "/us/resource-types/",
    component: _d29e9414,
    pathToRegexpOptions: {"strict":true},
    name: "resource-types___us"
  }, {
    path: "/us/school/",
    component: _3d5a261a,
    pathToRegexpOptions: {"strict":true},
    name: "school___us"
  }, {
    path: "/us/search_a/",
    component: _7ede4286,
    pathToRegexpOptions: {"strict":true},
    name: "search_a___us"
  }, {
    path: "/us/signup-complete/",
    component: _36b00402,
    pathToRegexpOptions: {"strict":true},
    name: "signup-complete___us"
  }, {
    path: "/us/signup/",
    component: _59789e14,
    pathToRegexpOptions: {"strict":true},
    name: "signup___us"
  }, {
    path: "/us/sitemap/",
    component: _0e3dfa2e,
    pathToRegexpOptions: {"strict":true},
    name: "sitemap___us"
  }, {
    path: "/us/suggest-a-change/",
    component: _23b1a841,
    pathToRegexpOptions: {"strict":true},
    name: "suggest-a-change___us"
  }, {
    path: "/us/suggest-a-resource/",
    component: _2b745f5f,
    pathToRegexpOptions: {"strict":true},
    name: "suggest-a-resource___us"
  }, {
    path: "/us/teacher-organisation/",
    component: _4249b224,
    pathToRegexpOptions: {"strict":true},
    name: "teacher-organisation___us"
  }, {
    path: "/us/teaching-resource-collections/",
    component: _fd463cec,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-collections___us"
  }, {
    path: "/us/teaching-resource-packs/",
    component: _57cf8caf,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-packs___us"
  }, {
    path: "/us/teaching-resources/",
    component: _01970c21,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resources___us"
  }, {
    path: "/us/testimonials-reviews/",
    component: _f64f0804,
    pathToRegexpOptions: {"strict":true},
    name: "testimonials-reviews___us"
  }, {
    path: "/us/unit-plans/",
    component: _c7f43946,
    pathToRegexpOptions: {"strict":true},
    name: "unit-plans___us"
  }, {
    path: "/us/upgrade/",
    component: _195ae4d0,
    pathToRegexpOptions: {"strict":true},
    name: "upgrade___us"
  }, {
    path: "/us/verify-email/",
    component: _f4b1b978,
    pathToRegexpOptions: {"strict":true},
    name: "verify-email___us"
  }, {
    path: "/us/videos/",
    component: _18868854,
    pathToRegexpOptions: {"strict":true},
    name: "videos___us"
  }, {
    path: "/us/webinars/",
    component: _fd5d2876,
    pathToRegexpOptions: {"strict":true},
    name: "webinars___us"
  }, {
    path: "/us/whats-buzzing/",
    component: _f5ef76f0,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing___us"
  }, {
    path: "/us/widgets/",
    component: _5153b7e3,
    pathToRegexpOptions: {"strict":true},
    name: "widgets___us"
  }, {
    path: "/au/about/privacy-policy/",
    component: _54ab7b55,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___au"
  }, {
    path: "/au/about/school-fonts/",
    component: _28b433ae,
    pathToRegexpOptions: {"strict":true},
    name: "about-school-fonts___au"
  }, {
    path: "/au/about/teach-starters-suggestion-guidelines/",
    component: _065f1fb9,
    pathToRegexpOptions: {"strict":true},
    name: "about-teach-starters-suggestion-guidelines___au"
  }, {
    path: "/au/about/terms-and-conditions/",
    component: _be7f429c,
    pathToRegexpOptions: {"strict":true},
    name: "about-terms-and-conditions___au"
  }, {
    path: "/au/engage/dice-roller/",
    component: _489f11c4,
    pathToRegexpOptions: {"strict":true},
    name: "engage-dice-roller___au"
  }, {
    path: "/au/engage/hundreds-chart/",
    component: _5d594e38,
    pathToRegexpOptions: {"strict":true},
    name: "engage-hundreds-chart___au"
  }, {
    path: "/au/engage/interactive-clock/",
    component: _369d889e,
    pathToRegexpOptions: {"strict":true},
    name: "engage-interactive-clock___au"
  }, {
    path: "/au/engage/math-lab/",
    component: _e432242c,
    pathToRegexpOptions: {"strict":true},
    name: "engage-math-lab___au"
  }, {
    path: "/au/engage/name-selector/",
    component: _7f95732f,
    pathToRegexpOptions: {"strict":true},
    name: "engage-name-selector___au"
  }, {
    path: "/au/engage/sentence-starter/",
    component: _76af9b47,
    pathToRegexpOptions: {"strict":true},
    name: "engage-sentence-starter___au"
  }, {
    path: "/au/engage/spin-wheel/",
    component: _4f76a432,
    pathToRegexpOptions: {"strict":true},
    name: "engage-spin-wheel___au"
  }, {
    path: "/au/engage/toggle/",
    component: _53453714,
    pathToRegexpOptions: {"strict":true},
    name: "engage-toggle___au"
  }, {
    path: "/au/engage/visual-writing-prompts/",
    component: _0be149b3,
    pathToRegexpOptions: {"strict":true},
    name: "engage-visual-writing-prompts___au"
  }, {
    path: "/au/engage/word-of-the-day/",
    component: _cf5ea88a,
    pathToRegexpOptions: {"strict":true},
    name: "engage-word-of-the-day___au"
  }, {
    path: "/au/hub/bookmarks/",
    component: _08dbb797,
    pathToRegexpOptions: {"strict":true},
    name: "hub-bookmarks___au"
  }, {
    path: "/au/hub/downloads/",
    component: _169850f6,
    pathToRegexpOptions: {"strict":true},
    name: "hub-downloads___au"
  }, {
    path: "/au/hub/folders/",
    component: _79507bdf,
    pathToRegexpOptions: {"strict":true},
    name: "hub-folders___au"
  }, {
    path: "/au/hub/history/",
    component: _f2b421a4,
    pathToRegexpOptions: {"strict":true},
    name: "hub-history___au"
  }, {
    path: "/au/hub/notifications/",
    component: _7f774c02,
    pathToRegexpOptions: {"strict":true},
    name: "hub-notifications___au"
  }, {
    path: "/au/hub/online-learning/",
    component: _0b6093f2,
    pathToRegexpOptions: {"strict":true},
    name: "hub-online-learning___au"
  }, {
    path: "/au/hub/studio/",
    component: _a84911a8,
    pathToRegexpOptions: {"strict":true},
    name: "hub-studio___au"
  }, {
    path: "/au/hub/word-lists/",
    component: _1e45e130,
    pathToRegexpOptions: {"strict":true},
    name: "hub-word-lists___au"
  }, {
    path: "/au/member-settings/email-notifications/",
    component: _c364a2dc,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-email-notifications___au"
  }, {
    path: "/au/member-settings/password-reset-request/",
    component: _1f125d94,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-password-reset-request___au"
  }, {
    path: "/au/member-settings/password-reset/",
    component: _ab5bda5c,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-password-reset___au"
  }, {
    path: "/au/member-settings/payment-method/",
    component: _f3c59f86,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-payment-method___au"
  }, {
    path: "/au/member-settings/personalisation/",
    component: _52519134,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-personalisation___au"
  }, {
    path: "/au/member-settings/personalization/",
    component: _52519134,
    name: "personalization___au"
  }, {
    path: "/au/member-settings/profile/",
    component: _e82ca978,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-profile___au"
  }, {
    path: "/au/member-settings/referrals/",
    component: _871dd25e,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-referrals___au"
  }, {
    path: "/au/member-settings/security/",
    component: _49b4e296,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-security___au"
  }, {
    path: "/au/teaching-resource/desk-plates/",
    component: _373fff84,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-desk-plates___au"
  }, {
    path: "/gb/about/privacy-policy/",
    component: _54ab7b55,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___gb"
  }, {
    path: "/gb/about/school-fonts/",
    component: _28b433ae,
    pathToRegexpOptions: {"strict":true},
    name: "about-school-fonts___gb"
  }, {
    path: "/gb/about/teach-starters-suggestion-guidelines/",
    component: _065f1fb9,
    pathToRegexpOptions: {"strict":true},
    name: "about-teach-starters-suggestion-guidelines___gb"
  }, {
    path: "/gb/about/terms-and-conditions/",
    component: _be7f429c,
    pathToRegexpOptions: {"strict":true},
    name: "about-terms-and-conditions___gb"
  }, {
    path: "/gb/engage/dice-roller/",
    component: _489f11c4,
    pathToRegexpOptions: {"strict":true},
    name: "engage-dice-roller___gb"
  }, {
    path: "/gb/engage/hundreds-chart/",
    component: _5d594e38,
    pathToRegexpOptions: {"strict":true},
    name: "engage-hundreds-chart___gb"
  }, {
    path: "/gb/engage/interactive-clock/",
    component: _369d889e,
    pathToRegexpOptions: {"strict":true},
    name: "engage-interactive-clock___gb"
  }, {
    path: "/gb/engage/math-lab/",
    component: _e432242c,
    pathToRegexpOptions: {"strict":true},
    name: "engage-math-lab___gb"
  }, {
    path: "/gb/engage/name-selector/",
    component: _7f95732f,
    pathToRegexpOptions: {"strict":true},
    name: "engage-name-selector___gb"
  }, {
    path: "/gb/engage/sentence-starter/",
    component: _76af9b47,
    pathToRegexpOptions: {"strict":true},
    name: "engage-sentence-starter___gb"
  }, {
    path: "/gb/engage/spin-wheel/",
    component: _4f76a432,
    pathToRegexpOptions: {"strict":true},
    name: "engage-spin-wheel___gb"
  }, {
    path: "/gb/engage/toggle/",
    component: _53453714,
    pathToRegexpOptions: {"strict":true},
    name: "engage-toggle___gb"
  }, {
    path: "/gb/engage/visual-writing-prompts/",
    component: _0be149b3,
    pathToRegexpOptions: {"strict":true},
    name: "engage-visual-writing-prompts___gb"
  }, {
    path: "/gb/engage/word-of-the-day/",
    component: _cf5ea88a,
    pathToRegexpOptions: {"strict":true},
    name: "engage-word-of-the-day___gb"
  }, {
    path: "/gb/hub/bookmarks/",
    component: _08dbb797,
    pathToRegexpOptions: {"strict":true},
    name: "hub-bookmarks___gb"
  }, {
    path: "/gb/hub/downloads/",
    component: _169850f6,
    pathToRegexpOptions: {"strict":true},
    name: "hub-downloads___gb"
  }, {
    path: "/gb/hub/folders/",
    component: _79507bdf,
    pathToRegexpOptions: {"strict":true},
    name: "hub-folders___gb"
  }, {
    path: "/gb/hub/history/",
    component: _f2b421a4,
    pathToRegexpOptions: {"strict":true},
    name: "hub-history___gb"
  }, {
    path: "/gb/hub/notifications/",
    component: _7f774c02,
    pathToRegexpOptions: {"strict":true},
    name: "hub-notifications___gb"
  }, {
    path: "/gb/hub/online-learning/",
    component: _0b6093f2,
    pathToRegexpOptions: {"strict":true},
    name: "hub-online-learning___gb"
  }, {
    path: "/gb/hub/studio/",
    component: _a84911a8,
    pathToRegexpOptions: {"strict":true},
    name: "hub-studio___gb"
  }, {
    path: "/gb/hub/word-lists/",
    component: _1e45e130,
    pathToRegexpOptions: {"strict":true},
    name: "hub-word-lists___gb"
  }, {
    path: "/gb/member-settings/email-notifications/",
    component: _c364a2dc,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-email-notifications___gb"
  }, {
    path: "/gb/member-settings/password-reset-request/",
    component: _1f125d94,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-password-reset-request___gb"
  }, {
    path: "/gb/member-settings/password-reset/",
    component: _ab5bda5c,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-password-reset___gb"
  }, {
    path: "/gb/member-settings/payment-method/",
    component: _f3c59f86,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-payment-method___gb"
  }, {
    path: "/gb/member-settings/personalisation/",
    component: _52519134,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-personalisation___gb"
  }, {
    path: "/gb/member-settings/personalization/",
    component: _52519134,
    name: "personalization___gb"
  }, {
    path: "/gb/member-settings/profile/",
    component: _e82ca978,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-profile___gb"
  }, {
    path: "/gb/member-settings/referrals/",
    component: _871dd25e,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-referrals___gb"
  }, {
    path: "/gb/member-settings/security/",
    component: _49b4e296,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-security___gb"
  }, {
    path: "/gb/teaching-resource/desk-plates/",
    component: _373fff84,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-desk-plates___gb"
  }, {
    path: "/us/about/privacy-policy/",
    component: _54ab7b55,
    pathToRegexpOptions: {"strict":true},
    name: "about-privacy-policy___us"
  }, {
    path: "/us/about/school-fonts/",
    component: _28b433ae,
    pathToRegexpOptions: {"strict":true},
    name: "about-school-fonts___us"
  }, {
    path: "/us/about/teach-starters-suggestion-guidelines/",
    component: _065f1fb9,
    pathToRegexpOptions: {"strict":true},
    name: "about-teach-starters-suggestion-guidelines___us"
  }, {
    path: "/us/about/terms-and-conditions/",
    component: _be7f429c,
    pathToRegexpOptions: {"strict":true},
    name: "about-terms-and-conditions___us"
  }, {
    path: "/us/engage/dice-roller/",
    component: _489f11c4,
    pathToRegexpOptions: {"strict":true},
    name: "engage-dice-roller___us"
  }, {
    path: "/us/engage/hundreds-chart/",
    component: _5d594e38,
    pathToRegexpOptions: {"strict":true},
    name: "engage-hundreds-chart___us"
  }, {
    path: "/us/engage/interactive-clock/",
    component: _369d889e,
    pathToRegexpOptions: {"strict":true},
    name: "engage-interactive-clock___us"
  }, {
    path: "/us/engage/math-lab/",
    component: _e432242c,
    pathToRegexpOptions: {"strict":true},
    name: "engage-math-lab___us"
  }, {
    path: "/us/engage/name-selector/",
    component: _7f95732f,
    pathToRegexpOptions: {"strict":true},
    name: "engage-name-selector___us"
  }, {
    path: "/us/engage/sentence-starter/",
    component: _76af9b47,
    pathToRegexpOptions: {"strict":true},
    name: "engage-sentence-starter___us"
  }, {
    path: "/us/engage/spin-wheel/",
    component: _4f76a432,
    pathToRegexpOptions: {"strict":true},
    name: "engage-spin-wheel___us"
  }, {
    path: "/us/engage/toggle/",
    component: _53453714,
    pathToRegexpOptions: {"strict":true},
    name: "engage-toggle___us"
  }, {
    path: "/us/engage/visual-writing-prompts/",
    component: _0be149b3,
    pathToRegexpOptions: {"strict":true},
    name: "engage-visual-writing-prompts___us"
  }, {
    path: "/us/engage/word-of-the-day/",
    component: _cf5ea88a,
    pathToRegexpOptions: {"strict":true},
    name: "engage-word-of-the-day___us"
  }, {
    path: "/us/hub/bookmarks/",
    component: _08dbb797,
    pathToRegexpOptions: {"strict":true},
    name: "hub-bookmarks___us"
  }, {
    path: "/us/hub/downloads/",
    component: _169850f6,
    pathToRegexpOptions: {"strict":true},
    name: "hub-downloads___us"
  }, {
    path: "/us/hub/folders/",
    component: _79507bdf,
    pathToRegexpOptions: {"strict":true},
    name: "hub-folders___us"
  }, {
    path: "/us/hub/history/",
    component: _f2b421a4,
    pathToRegexpOptions: {"strict":true},
    name: "hub-history___us"
  }, {
    path: "/us/hub/notifications/",
    component: _7f774c02,
    pathToRegexpOptions: {"strict":true},
    name: "hub-notifications___us"
  }, {
    path: "/us/hub/online-learning/",
    component: _0b6093f2,
    pathToRegexpOptions: {"strict":true},
    name: "hub-online-learning___us"
  }, {
    path: "/us/hub/studio/",
    component: _a84911a8,
    pathToRegexpOptions: {"strict":true},
    name: "hub-studio___us"
  }, {
    path: "/us/hub/word-lists/",
    component: _1e45e130,
    pathToRegexpOptions: {"strict":true},
    name: "hub-word-lists___us"
  }, {
    path: "/us/member-settings/email-notifications/",
    component: _c364a2dc,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-email-notifications___us"
  }, {
    path: "/us/member-settings/password-reset-request/",
    component: _1f125d94,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-password-reset-request___us"
  }, {
    path: "/us/member-settings/password-reset/",
    component: _ab5bda5c,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-password-reset___us"
  }, {
    path: "/us/member-settings/payment-method/",
    component: _f3c59f86,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-payment-method___us"
  }, {
    path: "/us/member-settings/personalisation/",
    component: _52519134,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-personalisation___us"
  }, {
    path: "/us/member-settings/personalization/",
    component: _52519134,
    name: "personalization___us"
  }, {
    path: "/us/member-settings/profile/",
    component: _e82ca978,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-profile___us"
  }, {
    path: "/us/member-settings/referrals/",
    component: _871dd25e,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-referrals___us"
  }, {
    path: "/us/member-settings/security/",
    component: _49b4e296,
    pathToRegexpOptions: {"strict":true},
    name: "member-settings-security___us"
  }, {
    path: "/us/teaching-resource/desk-plates/",
    component: _373fff84,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-desk-plates___us"
  }, {
    path: "/",
    component: _482400f4,
    pathToRegexpOptions: {"strict":true},
    name: "index___default"
  }, {
    path: "/au/hub/folder/:slug?/",
    component: _778f4002,
    pathToRegexpOptions: {"strict":true},
    name: "hub-folder-slug___au"
  }, {
    path: "/gb/hub/folder/:slug?/",
    component: _778f4002,
    pathToRegexpOptions: {"strict":true},
    name: "hub-folder-slug___gb"
  }, {
    path: "/us/hub/folder/:slug?/",
    component: _778f4002,
    pathToRegexpOptions: {"strict":true},
    name: "hub-folder-slug___us"
  }, {
    path: "/au/australian-curriculum-categories/:slug?/",
    component: _085e86c6,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-categories-slug___au"
  }, {
    path: "/au/australian-curriculum-v9/:slug?/",
    component: _9f87fd26,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-v9-slug___au"
  }, {
    path: "/au/blog/:slug/",
    component: _5b18714e,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___au"
  }, {
    path: "/au/classroom-management/:slug?/",
    component: _87722418,
    pathToRegexpOptions: {"strict":true},
    name: "classroom-management-slug___au"
  }, {
    path: "/au/common-core-standards-in-america/:slug?/",
    component: _62cb9740,
    pathToRegexpOptions: {"strict":true},
    name: "common-core-standards-in-america-slug___au"
  }, {
    path: "/au/curriculums/:slug/",
    component: _68c7a687,
    pathToRegexpOptions: {"strict":true},
    name: "curriculums-slug___au"
  }, {
    path: "/au/file-format/:slug?/",
    component: _d1fb7f52,
    pathToRegexpOptions: {"strict":true},
    name: "file-format-slug___au"
  }, {
    path: "/au/l/:slug?/",
    component: _0128954a,
    pathToRegexpOptions: {"strict":true},
    name: "l-slug___au"
  }, {
    path: "/au/lesson-plan/:slug?/",
    component: _21e1ae0d,
    pathToRegexpOptions: {"strict":true},
    name: "lesson-plan-slug___au"
  }, {
    path: "/au/national-curriculum-in-england/:slug?/",
    component: _f2b5a562,
    pathToRegexpOptions: {"strict":true},
    name: "national-curriculum-in-england-slug___au"
  }, {
    path: "/au/new-south-wales-curriculum/:slug?/",
    component: _4b63f6dc,
    pathToRegexpOptions: {"strict":true},
    name: "new-south-wales-curriculum-slug___au"
  }, {
    path: "/au/podcast/:slug?/",
    component: _93be561a,
    pathToRegexpOptions: {"strict":true},
    name: "podcast-slug___au"
  }, {
    path: "/au/publisher/:slug?/",
    component: _2c96c6eb,
    pathToRegexpOptions: {"strict":true},
    name: "publisher-slug___au"
  }, {
    path: "/au/resource-availability/:slug?/",
    component: _f4d1fbae,
    pathToRegexpOptions: {"strict":true},
    name: "resource-availability-slug___au"
  }, {
    path: "/au/resource-type/:slug?/",
    component: _232bdc48,
    pathToRegexpOptions: {"strict":true},
    name: "resource-type-slug___au"
  }, {
    path: "/au/search/:keyword?/",
    component: _2d550d36,
    pathToRegexpOptions: {"strict":true},
    name: "search-keyword___au"
  }, {
    path: "/au/teacher-organisation/:slug?/",
    component: _409310dc,
    pathToRegexpOptions: {"strict":true},
    name: "teacher-organisation-slug___au"
  }, {
    path: "/au/teaching-blog/:slug?/",
    component: _068f4cde,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-blog-slug___au"
  }, {
    path: "/au/teaching-resource-pack/:slug?/",
    component: _64d95da2,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-pack-slug___au"
  }, {
    path: "/au/teaching-resource/:slug?/",
    component: _bba8ae06,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-slug___au"
  }, {
    path: "/au/teks/:slug?/",
    component: _0a689290,
    pathToRegexpOptions: {"strict":true},
    name: "teks-slug___au"
  }, {
    path: "/au/unit-plan/:slug?/",
    component: _b664117e,
    pathToRegexpOptions: {"strict":true},
    name: "unit-plan-slug___au"
  }, {
    path: "/au/victorian-curriculum/:slug?/",
    component: _2a935e10,
    pathToRegexpOptions: {"strict":true},
    name: "victorian-curriculum-slug___au"
  }, {
    path: "/au/video/:slug?/",
    component: _7890d4aa,
    pathToRegexpOptions: {"strict":true},
    name: "video-slug___au"
  }, {
    path: "/au/webinar/:slug?/",
    component: _1aef6fd9,
    pathToRegexpOptions: {"strict":true},
    name: "webinar-slug___au"
  }, {
    path: "/au/whats-buzzing/:year/",
    component: _793708d5,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing-year___au"
  }, {
    path: "/au/widget/:slug?/",
    component: _40a3f30a,
    pathToRegexpOptions: {"strict":true},
    name: "widget-slug___au"
  }, {
    path: "/au/year-level/:slug/",
    component: _76f1d42b,
    pathToRegexpOptions: {"strict":true},
    name: "year-level-slug___au"
  }, {
    path: "/gb/australian-curriculum-categories/:slug?/",
    component: _085e86c6,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-categories-slug___gb"
  }, {
    path: "/gb/australian-curriculum-v9/:slug?/",
    component: _9f87fd26,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-v9-slug___gb"
  }, {
    path: "/gb/blog/:slug/",
    component: _5b18714e,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___gb"
  }, {
    path: "/gb/classroom-management/:slug?/",
    component: _87722418,
    pathToRegexpOptions: {"strict":true},
    name: "classroom-management-slug___gb"
  }, {
    path: "/gb/common-core-standards-in-america/:slug?/",
    component: _62cb9740,
    pathToRegexpOptions: {"strict":true},
    name: "common-core-standards-in-america-slug___gb"
  }, {
    path: "/gb/curriculums/:slug/",
    component: _68c7a687,
    pathToRegexpOptions: {"strict":true},
    name: "curriculums-slug___gb"
  }, {
    path: "/gb/file-format/:slug?/",
    component: _d1fb7f52,
    pathToRegexpOptions: {"strict":true},
    name: "file-format-slug___gb"
  }, {
    path: "/gb/l/:slug?/",
    component: _0128954a,
    pathToRegexpOptions: {"strict":true},
    name: "l-slug___gb"
  }, {
    path: "/gb/lesson-plan/:slug?/",
    component: _21e1ae0d,
    pathToRegexpOptions: {"strict":true},
    name: "lesson-plan-slug___gb"
  }, {
    path: "/gb/national-curriculum-in-england/:slug?/",
    component: _f2b5a562,
    pathToRegexpOptions: {"strict":true},
    name: "national-curriculum-in-england-slug___gb"
  }, {
    path: "/gb/new-south-wales-curriculum/:slug?/",
    component: _4b63f6dc,
    pathToRegexpOptions: {"strict":true},
    name: "new-south-wales-curriculum-slug___gb"
  }, {
    path: "/gb/podcast/:slug?/",
    component: _93be561a,
    pathToRegexpOptions: {"strict":true},
    name: "podcast-slug___gb"
  }, {
    path: "/gb/publisher/:slug?/",
    component: _2c96c6eb,
    pathToRegexpOptions: {"strict":true},
    name: "publisher-slug___gb"
  }, {
    path: "/gb/resource-availability/:slug?/",
    component: _f4d1fbae,
    pathToRegexpOptions: {"strict":true},
    name: "resource-availability-slug___gb"
  }, {
    path: "/gb/resource-type/:slug?/",
    component: _232bdc48,
    pathToRegexpOptions: {"strict":true},
    name: "resource-type-slug___gb"
  }, {
    path: "/gb/search/:keyword?/",
    component: _2d550d36,
    pathToRegexpOptions: {"strict":true},
    name: "search-keyword___gb"
  }, {
    path: "/gb/teacher-organisation/:slug?/",
    component: _409310dc,
    pathToRegexpOptions: {"strict":true},
    name: "teacher-organisation-slug___gb"
  }, {
    path: "/gb/teaching-blog/:slug?/",
    component: _068f4cde,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-blog-slug___gb"
  }, {
    path: "/gb/teaching-resource-pack/:slug?/",
    component: _64d95da2,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-pack-slug___gb"
  }, {
    path: "/gb/teaching-resource/:slug?/",
    component: _bba8ae06,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-slug___gb"
  }, {
    path: "/gb/teks/:slug?/",
    component: _0a689290,
    pathToRegexpOptions: {"strict":true},
    name: "teks-slug___gb"
  }, {
    path: "/gb/unit-plan/:slug?/",
    component: _b664117e,
    pathToRegexpOptions: {"strict":true},
    name: "unit-plan-slug___gb"
  }, {
    path: "/gb/victorian-curriculum/:slug?/",
    component: _2a935e10,
    pathToRegexpOptions: {"strict":true},
    name: "victorian-curriculum-slug___gb"
  }, {
    path: "/gb/video/:slug?/",
    component: _7890d4aa,
    pathToRegexpOptions: {"strict":true},
    name: "video-slug___gb"
  }, {
    path: "/gb/webinar/:slug?/",
    component: _1aef6fd9,
    pathToRegexpOptions: {"strict":true},
    name: "webinar-slug___gb"
  }, {
    path: "/gb/whats-buzzing/:year/",
    component: _793708d5,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing-year___gb"
  }, {
    path: "/gb/widget/:slug?/",
    component: _40a3f30a,
    pathToRegexpOptions: {"strict":true},
    name: "widget-slug___gb"
  }, {
    path: "/gb/year-level/:slug/",
    component: _76f1d42b,
    pathToRegexpOptions: {"strict":true},
    name: "year-level-slug___gb"
  }, {
    path: "/hub/folder/:slug?/",
    component: _778f4002,
    pathToRegexpOptions: {"strict":true},
    name: "hub-folder-slug___default"
  }, {
    path: "/us/australian-curriculum-categories/:slug?/",
    component: _085e86c6,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-categories-slug___us"
  }, {
    path: "/us/australian-curriculum-v9/:slug?/",
    component: _9f87fd26,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-v9-slug___us"
  }, {
    path: "/us/blog/:slug/",
    component: _5b18714e,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___us"
  }, {
    path: "/us/classroom-management/:slug?/",
    component: _87722418,
    pathToRegexpOptions: {"strict":true},
    name: "classroom-management-slug___us"
  }, {
    path: "/us/common-core-standards-in-america/:slug?/",
    component: _62cb9740,
    pathToRegexpOptions: {"strict":true},
    name: "common-core-standards-in-america-slug___us"
  }, {
    path: "/us/curriculums/:slug/",
    component: _68c7a687,
    pathToRegexpOptions: {"strict":true},
    name: "curriculums-slug___us"
  }, {
    path: "/us/file-format/:slug?/",
    component: _d1fb7f52,
    pathToRegexpOptions: {"strict":true},
    name: "file-format-slug___us"
  }, {
    path: "/us/grade-level/:slug/",
    component: _76f1d42b,
    pathToRegexpOptions: {"strict":true},
    name: "year-level-slug___us"
  }, {
    path: "/us/l/:slug?/",
    component: _0128954a,
    pathToRegexpOptions: {"strict":true},
    name: "l-slug___us"
  }, {
    path: "/us/lesson-plan/:slug?/",
    component: _21e1ae0d,
    pathToRegexpOptions: {"strict":true},
    name: "lesson-plan-slug___us"
  }, {
    path: "/us/national-curriculum-in-england/:slug?/",
    component: _f2b5a562,
    pathToRegexpOptions: {"strict":true},
    name: "national-curriculum-in-england-slug___us"
  }, {
    path: "/us/new-south-wales-curriculum/:slug?/",
    component: _4b63f6dc,
    pathToRegexpOptions: {"strict":true},
    name: "new-south-wales-curriculum-slug___us"
  }, {
    path: "/us/podcast/:slug?/",
    component: _93be561a,
    pathToRegexpOptions: {"strict":true},
    name: "podcast-slug___us"
  }, {
    path: "/us/publisher/:slug?/",
    component: _2c96c6eb,
    pathToRegexpOptions: {"strict":true},
    name: "publisher-slug___us"
  }, {
    path: "/us/resource-availability/:slug?/",
    component: _f4d1fbae,
    pathToRegexpOptions: {"strict":true},
    name: "resource-availability-slug___us"
  }, {
    path: "/us/resource-type/:slug?/",
    component: _232bdc48,
    pathToRegexpOptions: {"strict":true},
    name: "resource-type-slug___us"
  }, {
    path: "/us/search/:keyword?/",
    component: _2d550d36,
    pathToRegexpOptions: {"strict":true},
    name: "search-keyword___us"
  }, {
    path: "/us/teacher-organisation/:slug?/",
    component: _409310dc,
    pathToRegexpOptions: {"strict":true},
    name: "teacher-organisation-slug___us"
  }, {
    path: "/us/teaching-blog/:slug?/",
    component: _068f4cde,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-blog-slug___us"
  }, {
    path: "/us/teaching-resource-pack/:slug?/",
    component: _64d95da2,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-pack-slug___us"
  }, {
    path: "/us/teaching-resource/:slug?/",
    component: _bba8ae06,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-slug___us"
  }, {
    path: "/us/teks/:slug?/",
    component: _0a689290,
    pathToRegexpOptions: {"strict":true},
    name: "teks-slug___us"
  }, {
    path: "/us/unit-plan/:slug?/",
    component: _b664117e,
    pathToRegexpOptions: {"strict":true},
    name: "unit-plan-slug___us"
  }, {
    path: "/us/victorian-curriculum/:slug?/",
    component: _2a935e10,
    pathToRegexpOptions: {"strict":true},
    name: "victorian-curriculum-slug___us"
  }, {
    path: "/us/video/:slug?/",
    component: _7890d4aa,
    pathToRegexpOptions: {"strict":true},
    name: "video-slug___us"
  }, {
    path: "/us/webinar/:slug?/",
    component: _1aef6fd9,
    pathToRegexpOptions: {"strict":true},
    name: "webinar-slug___us"
  }, {
    path: "/us/whats-buzzing/:year/",
    component: _793708d5,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing-year___us"
  }, {
    path: "/us/widget/:slug?/",
    component: _40a3f30a,
    pathToRegexpOptions: {"strict":true},
    name: "widget-slug___us"
  }, {
    path: "/au/whats-buzzing/:year?/:issue/",
    component: _36062867,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing-year-issue___au"
  }, {
    path: "/gb/whats-buzzing/:year?/:issue/",
    component: _36062867,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing-year-issue___gb"
  }, {
    path: "/us/whats-buzzing/:year?/:issue/",
    component: _36062867,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing-year-issue___us"
  }, {
    path: "/au/learning-area/*/",
    component: _645447a0,
    pathToRegexpOptions: {"strict":true},
    name: "learning-area-all___au"
  }, {
    path: "/au/teaching-resource-collection/*/",
    component: _38c79089,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-collection-all___au"
  }, {
    path: "/gb/learning-area/*/",
    component: _645447a0,
    pathToRegexpOptions: {"strict":true},
    name: "learning-area-all___gb"
  }, {
    path: "/gb/teaching-resource-collection/*/",
    component: _38c79089,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-collection-all___gb"
  }, {
    path: "/us/learning-area/*/",
    component: _645447a0,
    pathToRegexpOptions: {"strict":true},
    name: "learning-area-all___us"
  }, {
    path: "/us/teaching-resource-collection/*/",
    component: _38c79089,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-collection-all___us"
  }, {
    path: "/australian-curriculum-categories/:slug?/",
    component: _085e86c6,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-categories-slug___default"
  }, {
    path: "/australian-curriculum-v9/:slug?/",
    component: _9f87fd26,
    pathToRegexpOptions: {"strict":true},
    name: "australian-curriculum-v9-slug___default"
  }, {
    path: "/blog/:slug/",
    component: _5b18714e,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug___default"
  }, {
    path: "/classroom-management/:slug?/",
    component: _87722418,
    pathToRegexpOptions: {"strict":true},
    name: "classroom-management-slug___default"
  }, {
    path: "/common-core-standards-in-america/:slug?/",
    component: _62cb9740,
    pathToRegexpOptions: {"strict":true},
    name: "common-core-standards-in-america-slug___default"
  }, {
    path: "/curriculums/:slug/",
    component: _68c7a687,
    pathToRegexpOptions: {"strict":true},
    name: "curriculums-slug___default"
  }, {
    path: "/file-format/:slug?/",
    component: _d1fb7f52,
    pathToRegexpOptions: {"strict":true},
    name: "file-format-slug___default"
  }, {
    path: "/l/:slug?/",
    component: _0128954a,
    pathToRegexpOptions: {"strict":true},
    name: "l-slug___default"
  }, {
    path: "/lesson-plan/:slug?/",
    component: _21e1ae0d,
    pathToRegexpOptions: {"strict":true},
    name: "lesson-plan-slug___default"
  }, {
    path: "/national-curriculum-in-england/:slug?/",
    component: _f2b5a562,
    pathToRegexpOptions: {"strict":true},
    name: "national-curriculum-in-england-slug___default"
  }, {
    path: "/new-south-wales-curriculum/:slug?/",
    component: _4b63f6dc,
    pathToRegexpOptions: {"strict":true},
    name: "new-south-wales-curriculum-slug___default"
  }, {
    path: "/podcast/:slug?/",
    component: _93be561a,
    pathToRegexpOptions: {"strict":true},
    name: "podcast-slug___default"
  }, {
    path: "/publisher/:slug?/",
    component: _2c96c6eb,
    pathToRegexpOptions: {"strict":true},
    name: "publisher-slug___default"
  }, {
    path: "/resource-availability/:slug?/",
    component: _f4d1fbae,
    pathToRegexpOptions: {"strict":true},
    name: "resource-availability-slug___default"
  }, {
    path: "/resource-type/:slug?/",
    component: _232bdc48,
    pathToRegexpOptions: {"strict":true},
    name: "resource-type-slug___default"
  }, {
    path: "/search/:keyword?/",
    component: _2d550d36,
    pathToRegexpOptions: {"strict":true},
    name: "search-keyword___default"
  }, {
    path: "/teacher-organisation/:slug?/",
    component: _409310dc,
    pathToRegexpOptions: {"strict":true},
    name: "teacher-organisation-slug___default"
  }, {
    path: "/teaching-blog/:slug?/",
    component: _068f4cde,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-blog-slug___default"
  }, {
    path: "/teaching-resource-pack/:slug?/",
    component: _64d95da2,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-pack-slug___default"
  }, {
    path: "/teaching-resource/:slug?/",
    component: _bba8ae06,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-slug___default"
  }, {
    path: "/teks/:slug?/",
    component: _0a689290,
    pathToRegexpOptions: {"strict":true},
    name: "teks-slug___default"
  }, {
    path: "/unit-plan/:slug?/",
    component: _b664117e,
    pathToRegexpOptions: {"strict":true},
    name: "unit-plan-slug___default"
  }, {
    path: "/victorian-curriculum/:slug?/",
    component: _2a935e10,
    pathToRegexpOptions: {"strict":true},
    name: "victorian-curriculum-slug___default"
  }, {
    path: "/video/:slug?/",
    component: _7890d4aa,
    pathToRegexpOptions: {"strict":true},
    name: "video-slug___default"
  }, {
    path: "/webinar/:slug?/",
    component: _1aef6fd9,
    pathToRegexpOptions: {"strict":true},
    name: "webinar-slug___default"
  }, {
    path: "/whats-buzzing/:year/",
    component: _793708d5,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing-year___default"
  }, {
    path: "/widget/:slug?/",
    component: _40a3f30a,
    pathToRegexpOptions: {"strict":true},
    name: "widget-slug___default"
  }, {
    path: "/year-level/:slug?/",
    component: _76f1d42b,
    pathToRegexpOptions: {"strict":true},
    name: "year-level-slug___default"
  }, {
    path: "/whats-buzzing/:year?/:issue/",
    component: _36062867,
    pathToRegexpOptions: {"strict":true},
    name: "whats-buzzing-year-issue___default"
  }, {
    path: "/learning-area/*/",
    component: _645447a0,
    pathToRegexpOptions: {"strict":true},
    name: "learning-area-all___default"
  }, {
    path: "/teaching-resource-collection/*/",
    component: _38c79089,
    pathToRegexpOptions: {"strict":true},
    name: "teaching-resource-collection-all___default"
  }],

  parseQuery: function(q) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require('qs').parse(q);
    },
  stringifyQuery: function(q) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const stringifiedQuery = require('qs').stringify(q);
      return stringifiedQuery ? '?' + stringifiedQuery : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
