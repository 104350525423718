import { ComputedRef, computed } from '@nuxtjs/composition-api';
import { Size, Variant } from '~/components/design-system';
interface TButtonProps {
  size?: Size;
  variant?: Variant;
  icon?: string;
  isSubmitting?: boolean;
  disabled?: boolean;
}

const fixedClasses: string[] = [
  'tw-font-sans tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-border tw-font-medium tw-shadow-sm focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-link focus:tw-ring-offset-2 focus:tw-no-underline hover:tw-no-underline tw-cursor',
];

function sizeClasses(size: Size): string[] {
  switch (size) {
    case 'default':
      return ['tw-px-4 tw-py-2 !tw-text-base tw-gap-2.5'];
    case 'sm':
      return ['tw-px-4 tw-py-1 !tw-text-sm tw-gap-2.5'];
    case 'md':
      return ['tw-py-1.5 tw-px-3 !tw-text-base tw-gap-2.5'];
    case 'lg':
      return ['tw-px-8 tw-py-3 !tw-text-base tw-gap-2.5'];
    default:
      throw new Error('Not implemented');
  }
}

function variantClasses(variant: Variant): string[] {
  switch (variant) {
    case 'primary':
      return [
        'tw-border-transparent tw-bg-highlighter tw-text-gray-900 hover:!tw-text-gray-900 focus:!tw-text-gray-900 hover:tw-bg-highlighter-dark',
      ];
    case 'secondary':
      return [
        'tw-bg-gray-100 tw-text-gray-700 hover:!tw-text-gray-700 focus:!tw-text-gray-700 hover:tw-bg-gray-200 focus:tw-bg-gray-200',
      ];
    case 'pink':
      return [
        'tw-border-transparent tw-bg-pink tw-text-gray-900 hover:!tw-text-gray-900 focus:!tw-text-gray-900 hover:tw-bg-pink-dark',
      ];
    case 'cream':
      return [
        'tw-border-transparent tw-bg-cream tw-text-gray-900 hover:!tw-text-gray-900 focus:!tw-text-gray-900 hover:tw-bg-cream-dark',
      ];
    case 'blue-border':
      return [
        'tw-text-link tw-border-link tw-border-solid hover:tw-text-link-dark focus:tw-text-link-dark tw-bg-white tw-shadow-none',
      ];
    case 'blue':
      return [
        'tw-text-link hover:tw-text-link-dark focus:tw-text-link-dark tw-bg-white tw-border-transparent tw-shadow-none',
      ];
    case 'default':
      return [
        'tw-border-solid tw-border-gray-300 tw-text-gray-900 hover:!tw-text-gray-900 focus:!tw-text-gray-900 tw-bg-white hover:tw-bg-gray-50',
      ];
    case 'danger':
      return [
        'tw-border-transparent tw-bg-red-600 tw-text-white hover:!tw-text-white focus:!tw-text-white hover:tw-bg-red-700',
      ];
    default:
      throw new Error('unreachable');
  }
}

function iconSizeClasses(size: Size): string[] {
  switch (size) {
    case 'default':
    case 'sm':
      return ['tw-w-4 tw-h-4'];
    case 'lg':
      return ['tw-w-8 tw-h-5'];
    default:
      throw new Error('Not implemented');
  }
}

export interface UseButtonProps {
  size?: Size;
  variant?: Variant;
  disabled?: boolean;
  isSubmitting?: boolean;
  icon?: string;
  type: 'button' | 'link';
}

export interface UseButton {
  isDisabled: ComputedRef<boolean | undefined>;
  classes: string[];
  iconClasses: string[];
}
export function useButton<T extends TButtonProps>(props: T, type: 'button' | 'link'): UseButton {
  const isDisabled = computed(() => {
    return props.disabled || props.isSubmitting;
  });

  const disabledClasses = computed(() =>
    type === 'link'
      ? isDisabled.value
        ? ['tw-opacity-50', 'tw-cursor-not-allowed']
        : []
      : ['disabled:tw-opacity-50', 'disabled:tw-cursor-not-allowed']
  );

  const classes = [
    ...fixedClasses,
    ...disabledClasses.value,
    ...sizeClasses(props.size || 'default'),
    ...variantClasses(props.variant || 'default'),
  ];

  const iconClasses = props.icon ? [...iconSizeClasses(props.size || 'default')] : [];

  return {
    isDisabled,
    classes,
    iconClasses,
  };
}
