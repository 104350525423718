export const downloadFileBlob = (data: Blob, filename: string): void => {
  const element = document.createElement('a');
  element.setAttribute('href', URL.createObjectURL(data));
  element.setAttribute('download', filename || 'resource.pdf');

  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();

  document.body.removeChild(element);
  setTimeout(() => URL.revokeObjectURL(element?.href), 10000);
};

export function formatFileFormats(fileFormats: { name: string }[]): string {
  if (!fileFormats) {
    return '';
  }

  return fileFormats.map(format => format?.name).join(', ');
}
