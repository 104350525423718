import { Plugin } from '@nuxt/types';

interface RedirectPlugin {
  $handleRedirect: (path: string) => Promise<void>;
}

declare module '@nuxt/types' {
  interface Context extends RedirectPlugin {}
}

const redirectPlugin: Plugin = ({ $axios, redirect }, inject) => {
  inject('handleRedirect', async (path: string) => {
    const response = await $axios.get('/public/v1/redirect', {
      params: { link: path },
    });
    redirect(response.data.type, response.data.link);
  });
};

export default redirectPlugin;
