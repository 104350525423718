type Currency = 'USD' | 'EUR' | 'GBP' | 'AUD' | 'NZD' | 'CAD' | 'ZAR';
type Secondary = 'us' | 'gb' | 'au';
interface Country {
  id: number;
  name: string;
  code: string;
  secondary: Secondary;
  currency: Currency;
}

const countries: Country[] = [
  { id: 5526648, name: 'Afghanistan', code: 'af', secondary: 'us', currency: 'USD' },
  { id: 5526649, name: 'Albania', code: 'al', secondary: 'gb', currency: 'EUR' },
  { id: 5526650, name: 'Algeria', code: 'dz', secondary: 'gb', currency: 'EUR' },
  { id: 5526651, name: 'American Samoa', code: 'as', secondary: 'us', currency: 'USD' },
  { id: 5526652, name: 'Andorra', code: 'ad', secondary: 'gb', currency: 'EUR' },
  { id: 5526653, name: 'Angola', code: 'ao', secondary: 'us', currency: 'USD' },
  { id: 5526654, name: 'Anguilla', code: 'ai', secondary: 'us', currency: 'USD' },
  {
    id: 5526655,
    name: 'Antigua and Barbuda',
    code: 'ag',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526656, name: 'Argentina', code: 'ar', secondary: 'us', currency: 'USD' },
  { id: 5526657, name: 'Armenia', code: 'am', secondary: 'us', currency: 'USD' },
  { id: 5526658, name: 'Aruba', code: 'aw', secondary: 'us', currency: 'USD' },
  { id: 5526659, name: 'Australia', code: 'au', secondary: 'au', currency: 'AUD' },
  { id: 5526660, name: 'Austria', code: 'at', secondary: 'gb', currency: 'EUR' },
  { id: 5526661, name: 'Azerbaijan', code: 'az', secondary: 'us', currency: 'USD' },
  { id: 5526663, name: 'Bahamas', code: 'bs', secondary: 'us', currency: 'USD' },
  { id: 5526664, name: 'Bahrain', code: 'bh', secondary: 'us', currency: 'USD' },
  { id: 5526665, name: 'Bangladesh', code: 'bd', secondary: 'us', currency: 'USD' },
  { id: 5526666, name: 'Barbados', code: 'bb', secondary: 'us', currency: 'USD' },
  { id: 5526667, name: 'Belarus', code: 'by', secondary: 'gb', currency: 'EUR' },
  { id: 5526668, name: 'Belgium', code: 'be', secondary: 'us', currency: 'USD' },
  { id: 5526669, name: 'Belize', code: 'bz', secondary: 'us', currency: 'USD' },
  { id: 5526670, name: 'Benin', code: 'bj', secondary: 'us', currency: 'USD' },
  { id: 5526671, name: 'Bermuda', code: 'bm', secondary: 'us', currency: 'USD' },
  { id: 5526672, name: 'Bhutan', code: 'bt', secondary: 'us', currency: 'USD' },
  { id: 5526673, name: 'Bolivia', code: 'bo', secondary: 'us', currency: 'USD' },
  {
    id: 5526675,
    name: 'Bosnia and Herzegovina',
    code: 'ba',
    secondary: 'gb',
    currency: 'EUR',
  },
  { id: 5526676, name: 'Botswana', code: 'bw', secondary: 'us', currency: 'USD' },
  { id: 5526677, name: 'Brazil', code: 'br', secondary: 'us', currency: 'USD' },
  {
    id: 5526678,
    name: 'British Indian Ocean Territory',
    code: 'io',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526679, name: 'Brunei', code: 'bn', secondary: 'us', currency: 'USD' },
  { id: 5526680, name: 'Bulgaria', code: 'bg', secondary: 'gb', currency: 'EUR' },
  { id: 5526681, name: 'Burkina Faso', code: 'bf', secondary: 'us', currency: 'USD' },
  { id: 5526682, name: 'Burundi', code: 'bi', secondary: 'us', currency: 'USD' },
  { id: 5526683, name: 'Cambodia', code: 'kh', secondary: 'us', currency: 'USD' },
  { id: 5526684, name: 'Cameroon', code: 'cm', secondary: 'us', currency: 'USD' },
  { id: 5526685, name: 'Canada', code: 'ca', secondary: 'gb', currency: 'CAD' },
  { id: 5526687, name: 'Cape Verde', code: 'cv', secondary: 'au', currency: 'AUD' },
  { id: 5526688, name: 'Cayman Islands', code: 'ky', secondary: 'us', currency: 'USD' },
  {
    id: 5526689,
    name: 'Central African Republic',
    code: 'cf',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526690, name: 'Chad', code: 'td', secondary: 'us', currency: 'USD' },
  { id: 5526692, name: 'Chile', code: 'cl', secondary: 'us', currency: 'USD' },
  { id: 5526693, name: 'China', code: 'cn', secondary: 'us', currency: 'USD' },
  { id: 5526694, name: 'Christmas Island', code: 'cx', secondary: 'au', currency: 'AUD' },
  {
    id: 5526695,
    name: 'Cocos (Keeling) Islands',
    code: 'cc',
    secondary: 'au',
    currency: 'AUD',
  },
  { id: 5526696, name: 'Colombia', code: 'co', secondary: 'us', currency: 'USD' },
  { id: 5526697, name: 'Comoros', code: 'km', secondary: 'us', currency: 'USD' },
  { id: 5526698, name: 'Congo', code: 'cg', secondary: 'us', currency: 'USD' },
  {
    id: 5526699,
    name: 'Congo, The Democratic Republic of the',
    code: 'cd',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526700, name: 'Cook Islands', code: 'ck', secondary: 'au', currency: 'AUD' },
  { id: 5526701, name: 'Costa Rica', code: 'cr', secondary: 'us', currency: 'USD' },
  { id: 5526702, name: "Côte d'Ivoire", code: 'ci', secondary: 'us', currency: 'USD' },
  { id: 5526703, name: 'Croatia', code: 'hr', secondary: 'gb', currency: 'EUR' },
  { id: 5526704, name: 'Cuba', code: 'cu', secondary: 'us', currency: 'USD' },
  { id: 5526706, name: 'Cyprus', code: 'cy', secondary: 'us', currency: 'USD' },
  { id: 5526707, name: 'Czech Republic', code: 'cz', secondary: 'gb', currency: 'EUR' },
  { id: 5526708, name: 'Denmark', code: 'dk', secondary: 'gb', currency: 'EUR' },
  { id: 5526709, name: 'Djibouti', code: 'dj', secondary: 'us', currency: 'USD' },
  { id: 5526710, name: 'Dominica', code: 'dm', secondary: 'us', currency: 'USD' },
  {
    id: 5526711,
    name: 'Dominican Republic',
    code: 'do',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526713, name: 'Ecuador', code: 'ec', secondary: 'us', currency: 'USD' },
  { id: 5526714, name: 'Egypt', code: 'eg', secondary: 'us', currency: 'USD' },
  { id: 5526715, name: 'El Salvador', code: 'sv', secondary: 'us', currency: 'USD' },
  {
    id: 5526716,
    name: 'Equatorial Guinea',
    code: 'gq',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526717, name: 'Eritrea', code: 'er', secondary: 'us', currency: 'USD' },
  { id: 5526718, name: 'Estonia', code: 'ee', secondary: 'gb', currency: 'EUR' },
  { id: 5526719, name: 'Ethiopia', code: 'et', secondary: 'us', currency: 'USD' },
  {
    id: 5526720,
    name: 'Falkland Islands (Malvinas)',
    code: 'fk',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526721, name: 'Faroe Islands', code: 'fo', secondary: 'gb', currency: 'EUR' },
  { id: 5526722, name: 'Fiji', code: 'fj', secondary: 'au', currency: 'AUD' },
  { id: 5526723, name: 'Finland', code: 'fi', secondary: 'gb', currency: 'EUR' },
  { id: 5526724, name: 'France', code: 'fr', secondary: 'gb', currency: 'EUR' },
  { id: 5526725, name: 'French Guiana', code: 'gf', secondary: 'us', currency: 'USD' },
  { id: 5526726, name: 'French Polynesia', code: 'pf', secondary: 'au', currency: 'AUD' },
  {
    id: 5526727,
    name: 'French Southern Territories',
    code: 'tf',
    secondary: 'au',
    currency: 'AUD',
  },
  { id: 5526728, name: 'Gabon', code: 'ga', secondary: 'us', currency: 'USD' },
  { id: 5526729, name: 'Gambia', code: 'gm', secondary: 'us', currency: 'USD' },
  { id: 5526730, name: 'Georgia', code: 'ge', secondary: 'us', currency: 'USD' },
  { id: 5526731, name: 'Germany', code: 'de', secondary: 'gb', currency: 'EUR' },
  { id: 5526732, name: 'Ghana', code: 'gh', secondary: 'us', currency: 'USD' },
  { id: 5526733, name: 'Gibraltar', code: 'gi', secondary: 'gb', currency: 'EUR' },
  { id: 5526735, name: 'Greece', code: 'gr', secondary: 'gb', currency: 'EUR' },
  { id: 5526736, name: 'Greenland', code: 'gl', secondary: 'us', currency: 'USD' },
  { id: 5526737, name: 'Grenada', code: 'gd', secondary: 'us', currency: 'USD' },
  { id: 5526738, name: 'Guadeloupe', code: 'gp', secondary: 'us', currency: 'USD' },
  { id: 5526739, name: 'Guam', code: 'gu', secondary: 'au', currency: 'AUD' },
  { id: 5526740, name: 'Guatemala', code: 'gt', secondary: 'us', currency: 'USD' },
  { id: 5526741, name: 'Guernsey', code: 'gg', secondary: 'gb', currency: 'EUR' },
  { id: 5526742, name: 'Guinea', code: 'gn', secondary: 'us', currency: 'USD' },
  { id: 5526743, name: 'Guinea-Bissau', code: 'gw', secondary: 'us', currency: 'USD' },
  { id: 5526744, name: 'Guyana', code: 'gy', secondary: 'us', currency: 'USD' },
  { id: 5526745, name: 'Haiti', code: 'ht', secondary: 'us', currency: 'USD' },
  { id: 5526746, name: 'Honduras', code: 'hn', secondary: 'us', currency: 'USD' },
  { id: 5526747, name: 'Hong Kong', code: 'hk', secondary: 'us', currency: 'USD' },
  { id: 5526748, name: 'Hungary', code: 'hu', secondary: 'gb', currency: 'EUR' },
  { id: 5526749, name: 'Iceland', code: 'is', secondary: 'gb', currency: 'EUR' },
  { id: 5526750, name: 'India', code: 'in', secondary: 'us', currency: 'USD' },
  { id: 5526751, name: 'Indonesia', code: 'id', secondary: 'au', currency: 'AUD' },
  {
    id: 5526752,
    name: 'Iran, Islamic Republic Of',
    code: 'ir',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526753, name: 'Iraq', code: 'iq', secondary: 'us', currency: 'USD' },
  { id: 5526754, name: 'Ireland', code: 'ie', secondary: 'gb', currency: 'EUR' },
  { id: 5526755, name: 'Isle of Man', code: 'im', secondary: 'gb', currency: 'GBP' },
  { id: 5526756, name: 'Israel', code: 'il', secondary: 'us', currency: 'USD' },
  { id: 5526757, name: 'Italy', code: 'it', secondary: 'gb', currency: 'EUR' },
  { id: 5526758, name: 'Jamaica', code: 'jm', secondary: 'us', currency: 'USD' },
  { id: 5526759, name: 'Japan', code: 'jp', secondary: 'us', currency: 'USD' },
  { id: 5526760, name: 'Jersey', code: 'je', secondary: 'gb', currency: 'GBP' },
  { id: 5526761, name: 'Jordan', code: 'jo', secondary: 'us', currency: 'USD' },
  { id: 5526762, name: 'Kazakhstan', code: 'kz', secondary: 'us', currency: 'USD' },
  { id: 5526763, name: 'Kenya', code: 'ke', secondary: 'us', currency: 'USD' },
  { id: 5526764, name: 'Kiribati', code: 'ki', secondary: 'au', currency: 'AUD' },
  { id: 5526765, name: 'Korea, North', code: 'kp', secondary: 'us', currency: 'USD' },
  { id: 5526766, name: 'Korea, South', code: 'kr', secondary: 'us', currency: 'USD' },
  { id: 5526767, name: 'Kuwait', code: 'kw', secondary: 'us', currency: 'USD' },
  { id: 5526768, name: 'Kyrgyzstan', code: 'kg', secondary: 'us', currency: 'USD' },
  { id: 5526769, name: 'Laos', code: 'la', secondary: 'us', currency: 'USD' },
  { id: 5526770, name: 'Latvia', code: 'lv', secondary: 'gb', currency: 'EUR' },
  { id: 5526771, name: 'Lebanon', code: 'lb', secondary: 'us', currency: 'USD' },
  { id: 5526772, name: 'Lesotho', code: 'ls', secondary: 'us', currency: 'USD' },
  { id: 5526773, name: 'Liberia', code: 'lr', secondary: 'us', currency: 'USD' },
  { id: 5526774, name: 'Libya', code: 'ly', secondary: 'us', currency: 'USD' },
  { id: 5526775, name: 'Liechtenstein', code: 'li', secondary: 'gb', currency: 'EUR' },
  { id: 5526776, name: 'Lithuania', code: 'lt', secondary: 'gb', currency: 'EUR' },
  { id: 5526777, name: 'Luxembourg', code: 'lu', secondary: 'gb', currency: 'EUR' },
  { id: 5526778, name: 'Macao', code: 'mo', secondary: 'us', currency: 'USD' },
  {
    id: 5526779,
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'mk',
    secondary: 'gb',
    currency: 'EUR',
  },
  { id: 5526780, name: 'Madagascar', code: 'mg', secondary: 'us', currency: 'USD' },
  { id: 5526781, name: 'Malawi', code: 'mw', secondary: 'us', currency: 'USD' },
  { id: 5526782, name: 'Malaysia', code: 'my', secondary: 'us', currency: 'USD' },
  { id: 5526783, name: 'Maldives', code: 'mv', secondary: 'us', currency: 'USD' },
  { id: 5526784, name: 'Mali', code: 'ml', secondary: 'us', currency: 'USD' },
  { id: 5526785, name: 'Malta', code: 'mt', secondary: 'gb', currency: 'EUR' },
  { id: 5526786, name: 'Marshall Islands', code: 'mh', secondary: 'au', currency: 'AUD' },
  { id: 5526787, name: 'Martinique', code: 'mq', secondary: 'us', currency: 'USD' },
  { id: 5526788, name: 'Mauritania', code: 'mr', secondary: 'us', currency: 'USD' },
  { id: 5526789, name: 'Mauritius', code: 'mu', secondary: 'us', currency: 'USD' },
  { id: 5526790, name: 'Mayotte', code: 'yt', secondary: 'us', currency: 'USD' },
  { id: 5526791, name: 'Mexico', code: 'mx', secondary: 'us', currency: 'USD' },
  {
    id: 5526793,
    name: 'Moldova, Republic of',
    code: 'md',
    secondary: 'gb',
    currency: 'EUR',
  },
  { id: 5526794, name: 'Monaco', code: 'mc', secondary: 'gb', currency: 'EUR' },
  { id: 5526795, name: 'Mongolia', code: 'mn', secondary: 'us', currency: 'USD' },
  { id: 5526797, name: 'Montserrat', code: 'ms', secondary: 'us', currency: 'USD' },
  { id: 5526798, name: 'Morocco', code: 'ma', secondary: 'us', currency: 'USD' },
  { id: 5526799, name: 'Mozambique', code: 'mz', secondary: 'us', currency: 'USD' },
  { id: 5526800, name: 'Myanmar', code: 'mm', secondary: 'us', currency: 'USD' },
  { id: 5526801, name: 'Namibia', code: 'na', secondary: 'us', currency: 'USD' },
  { id: 5526802, name: 'Nauru', code: 'nr', secondary: 'au', currency: 'AUD' },
  { id: 5526803, name: 'Nepal', code: 'np', secondary: 'us', currency: 'USD' },
  { id: 5526805, name: 'Netherlands', code: 'nl', secondary: 'gb', currency: 'EUR' },
  {
    id: 5526804,
    name: 'Netherlands Antilles',
    code: 'an',
    secondary: 'gb',
    currency: 'EUR',
  },
  { id: 5526807, name: 'New Caledonia', code: 'nc', secondary: 'au', currency: 'AUD' },
  { id: 5526808, name: 'New Zealand', code: 'nz', secondary: 'au', currency: 'NZD' },
  { id: 5526809, name: 'Nicaragua', code: 'ni', secondary: 'us', currency: 'USD' },
  { id: 5526810, name: 'Niger', code: 'ne', secondary: 'us', currency: 'USD' },
  { id: 5526811, name: 'Nigeria', code: 'ng', secondary: 'us', currency: 'USD' },
  { id: 5526812, name: 'Niue', code: 'nu', secondary: 'au', currency: 'AUD' },
  { id: 5526813, name: 'Norfolk Island', code: 'nf', secondary: 'au', currency: 'AUD' },
  { id: 5526814, name: 'Norway', code: 'no', secondary: 'gb', currency: 'EUR' },
  { id: 5526815, name: 'Oman', code: 'om', secondary: 'us', currency: 'USD' },
  { id: 5526816, name: 'Pakistan', code: 'pk', secondary: 'us', currency: 'USD' },
  { id: 5526817, name: 'Palau', code: 'pw', secondary: 'au', currency: 'AUD' },
  { id: 5526818, name: 'Palestine', code: 'ps', secondary: 'us', currency: 'USD' },
  { id: 5526819, name: 'Panama', code: 'pa', secondary: 'us', currency: 'USD' },
  { id: 5526820, name: 'Papua New Guinea', code: 'pg', secondary: 'au', currency: 'AUD' },
  { id: 5526821, name: 'Paraguay', code: 'py', secondary: 'us', currency: 'USD' },
  { id: 5526822, name: 'Peru', code: 'pe', secondary: 'us', currency: 'USD' },
  { id: 5526823, name: 'Philippines', code: 'ph', secondary: 'us', currency: 'USD' },
  { id: 5526824, name: 'Pitcairn Island', code: 'pn', secondary: 'us', currency: 'USD' },
  { id: 5526825, name: 'Poland', code: 'pl', secondary: 'gb', currency: 'EUR' },
  { id: 5526826, name: 'Portugal', code: 'pt', secondary: 'gb', currency: 'EUR' },
  { id: 5526827, name: 'Puerto Rico', code: 'pr', secondary: 'us', currency: 'USD' },
  { id: 5526828, name: 'Qatar', code: 'qa', secondary: 'us', currency: 'USD' },
  { id: 5526829, name: 'Reunion', code: 're', secondary: 'us', currency: 'USD' },
  { id: 5526830, name: 'Romania', code: 'ro', secondary: 'gb', currency: 'EUR' },
  { id: 5526831, name: 'Russia', code: 'ru', secondary: 'au', currency: 'AUD' },
  { id: 5526832, name: 'Rwanda', code: 'rw', secondary: 'us', currency: 'USD' },
  { id: 5526855, name: 'Saint Helena', code: 'sh', secondary: 'us', currency: 'GBP' },
  {
    id: 5526856,
    name: 'Saint Kitts and Nevis',
    code: 'kn',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526857, name: 'Saint Lucia', code: 'lc', secondary: 'us', currency: 'USD' },
  {
    id: 5526859,
    name: 'Saint Pierre and Miquelon',
    code: 'pm',
    secondary: 'us',
    currency: 'USD',
  },
  {
    id: 5526860,
    name: 'Saint Vincent and the Grenadines',
    code: 'vc',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526834, name: 'Samoa', code: 'ws', secondary: 'us', currency: 'USD' },
  { id: 5526836, name: 'San Marino', code: 'sm', secondary: 'gb', currency: 'EUR' },
  {
    id: 5526837,
    name: 'Sao Tome and Principe',
    code: 'st',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526838, name: 'Saudi Arabia', code: 'sa', secondary: 'us', currency: 'USD' },
  { id: 5526839, name: 'Senegal', code: 'sn', secondary: 'us', currency: 'USD' },
  {
    id: 5526841,
    name: 'Serbia and Montenegro',
    code: 'cs',
    secondary: 'gb',
    currency: 'EUR',
  },
  { id: 5526842, name: 'Seychelles', code: 'sc', secondary: 'us', currency: 'USD' },
  { id: 5526843, name: 'Sierra Leone', code: 'sl', secondary: 'us', currency: 'USD' },
  { id: 5526844, name: 'Singapore', code: 'sg', secondary: 'au', currency: 'AUD' },
  { id: 5526845, name: 'Slovakia', code: 'sk', secondary: 'us', currency: 'USD' },
  { id: 5526846, name: 'Slovenia', code: 'si', secondary: 'gb', currency: 'EUR' },
  { id: 5526847, name: 'Solomon Islands', code: 'sb', secondary: 'au', currency: 'AUD' },
  { id: 5526848, name: 'Somalia', code: 'so', secondary: 'us', currency: 'USD' },
  { id: 5526849, name: 'South Africa', code: 'za', secondary: 'gb', currency: 'ZAR' },
  { id: 5526851, name: 'Spain', code: 'es', secondary: 'gb', currency: 'EUR' },
  { id: 5526852, name: 'Sri Lanka', code: 'lk', secondary: 'us', currency: 'USD' },
  { id: 5526862, name: 'Sudan', code: 'sd', secondary: 'us', currency: 'USD' },
  { id: 5526862, name: 'Suriname', code: 'sr', secondary: 'us', currency: 'USD' },
  { id: 5526863, name: 'Swaziland', code: 'sz', secondary: 'us', currency: 'USD' },
  { id: 5526864, name: 'Sweden', code: 'se', secondary: 'gb', currency: 'EUR' },
  { id: 5526865, name: 'Switzerland', code: 'ch', secondary: 'gb', currency: 'EUR' },
  { id: 5526866, name: 'Syria', code: 'sy', secondary: 'us', currency: 'USD' },
  { id: 5526868, name: 'Taiwan', code: 'tw', secondary: 'us', currency: 'USD' },
  { id: 5526869, name: 'Tajikistan', code: 'tj', secondary: 'us', currency: 'USD' },
  {
    id: 5526870,
    name: 'Tanzania, United Republic of',
    code: 'tz',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526871, name: 'Thailand', code: 'th', secondary: 'us', currency: 'USD' },
  { id: 5526712, name: 'Timor-Leste', code: 'tl', secondary: 'us', currency: 'USD' },
  { id: 5526872, name: 'Togo', code: 'tg', secondary: 'us', currency: 'USD' },
  { id: 5526873, name: 'Tokelau', code: 'tk', secondary: 'au', currency: 'AUD' },
  { id: 5526874, name: 'Tonga', code: 'to', secondary: 'au', currency: 'AUD' },
  {
    id: 5526875,
    name: 'Trinidad and Tobago',
    code: 'tt',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526876, name: 'Tunisia', code: 'tn', secondary: 'us', currency: 'USD' },
  { id: 5526877, name: 'Turkey', code: 'tr', secondary: 'us', currency: 'USD' },
  { id: 5526878, name: 'Turkmenistan', code: 'tm', secondary: 'us', currency: 'USD' },
  {
    id: 5526879,
    name: 'Turks and Caicos Islands',
    code: 'tc',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526880, name: 'Tuvalu', code: 'tv', secondary: 'us', currency: 'AUD' },
  { id: 5526881, name: 'Uganda', code: 'ug', secondary: 'us', currency: 'USD' },
  { id: 5526882, name: 'Ukraine', code: 'ua', secondary: 'gb', currency: 'EUR' },
  {
    id: 5526883,
    name: 'United Arab Emirates',
    code: 'ae',
    secondary: 'us',
    currency: 'USD',
  },
  { id: 5526884, name: 'United Kingdom', code: 'gb', secondary: 'gb', currency: 'GBP' },
  { id: 5526885, name: 'United States', code: 'us', secondary: 'us', currency: 'USD' },
  { id: 5526886, name: 'Uruguay', code: 'uy', secondary: 'us', currency: 'USD' },
  { id: 5526887, name: 'Uzbekistan', code: 'uz', secondary: 'us', currency: 'USD' },
  { id: 5526888, name: 'Vanuatu', code: 'vu', secondary: 'us', currency: 'USD' },
  { id: 5526890, name: 'Venezuela', code: 've', secondary: 'us', currency: 'USD' },
  { id: 5526891, name: 'Vietnam', code: 'vn', secondary: 'au', currency: 'AUD' },
  {
    id: 5526892,
    name: 'Virgin Islands, British',
    code: 'vg',
    secondary: 'us',
    currency: 'USD',
  },
  {
    id: 5526893,
    name: 'Virgin Islands, U.S.',
    code: 'vi',
    secondary: 'us',
    currency: 'USD',
  },
  {
    id: 5526895,
    name: 'Wallis and Futuna',
    code: 'wf',
    secondary: 'au',
    currency: 'AUD',
  },
  { id: 5526899, name: 'Western Sahara', code: 'eh', secondary: 'us', currency: 'USD' },
  { id: 5526896, name: 'Yemen', code: 'ye', secondary: 'us', currency: 'USD' },
  { id: 5526897, name: 'Zambia', code: 'zm', secondary: 'us', currency: 'USD' },
  { id: 5526898, name: 'Zimbabwe', code: 'zw', secondary: 'us', currency: 'USD' },
];

export default countries;
