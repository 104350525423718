import { Locale } from '../types/locale';
import { GradeValue } from './grades';

export type LandingPageLink = `/l/${string}/`;
export type LocaleLink<T extends Locale> = `/${T}/${string}/`;
export type GradeLink<T extends Locale> = T extends 'us'
  ? `/${T}/grade-level/${GradeValue<T>}/`
  : `/${T}/year-level/${GradeValue<T>}/`;

type AnyLocale = 'us' | 'au' | 'gb';

export interface ResourceNavigationInfo<T extends Locale> {
  name: string;
  link: LocaleLink<T> | string;
  type: string;
  subdomain?: boolean;
  icon: string;
  description: string;
}

export type LearningAreaLink<T extends Locale> = `/${T}/learning-area/${string}/`;

export interface LearningAreaNavigationInfo<T extends Locale> {
  name: string;
  link: LearningAreaLink<T>;
}

export interface CategoryItemNavigationInfo<T extends Locale> {
  name: string;
  link: LocaleLink<T>;
}

export interface CategoryNavigationInfo<T extends Locale> {
  name: string;
  link: LocaleLink<T>;
  items: CategoryItemNavigationInfo<T>[];
}

export interface TopicNavigationInfo<T extends Locale> {
  categories: CategoryNavigationInfo<T>[];
}

export interface GradeNavigationInfo<T extends Locale> {
  name: string;
  link: GradeLink<T>;
}

export interface ContentNavigationInfo<T extends Locale> {
  name: string;
  link: LocaleLink<T> | string;
  type: string;
  subdomain?: boolean;
  icon: string;
  description: string;
}

export interface NavigationInfo<T extends Locale> {
  landing_pages: { name: string; link: LandingPageLink }[];
  resources: ResourceNavigationInfo<T>[];
  learning_areas: LearningAreaNavigationInfo<T>[];
  topics: TopicNavigationInfo<T>[];
  year_levels: GradeNavigationInfo<T>[];
  content_pages: ContentNavigationInfo<T>[];
}

export interface NavigationLinks {
  au: NavigationInfo<'au'>;
  us: NavigationInfo<'us'>;
  gb: NavigationInfo<'gb'>;
}

export type NavigationLinkAnyLocale = NavigationInfo<AnyLocale>;
type NavigationInfoNoTopics = Omit<NavigationLinkAnyLocale, 'topics'>;

// This exists to allow us to iterate over all the navigation links except topics as topics has a different structure
export type AnyNavigationInfoExceptTopics = NavigationLinkAnyLocale[keyof NavigationInfoNoTopics];

// This exists to allow us to iterate over all the navigation links that also have an icon
export type AnyNavigationInfoWithIcon =
  | ResourceNavigationInfo<AnyLocale>[]
  | ContentNavigationInfo<AnyLocale>[];

export const navigationLinks: NavigationLinks = {
  au: {
    landing_pages: [
      { name: 'How We Help', link: '/l/how-we-help/' },
      { name: 'For Teachers', link: '/l/teach-starter-for-primary-school-teachers/' },
      { name: 'For Schools', link: '/l/teach-starter-for-schools/' },
      { name: 'For Parents', link: '/l/teach-starter-for-parents/' },
      { name: 'For Home Educators', link: '/l/teach-starter-for-homeschooling/' },
      { name: 'Back To School', link: '/l/back-to-school/' },
      { name: 'Online Lesson Builder', link: '/l/online-lesson-builder/' },
    ],
    resources: [
      {
        name: 'Teaching Resources',
        link: '/au/teaching-resources/',
        type: 'teaching-resource',
        icon: 'file',
        description: 'Curriculum-aligned resources to  engage and inspire your class.',
      },
      {
        name: 'Units & Lesson Plans',
        link: '/au/unit-plans/',
        type: 'unit-plan',
        icon: 'list',
        description: 'Take your class on an educational adventure over multiple lessons.',
      },
      {
        name: 'Widgets',
        link: '/au/widgets/',
        type: 'widget',
        icon: 'cog',
        description: 'Digital activities and interactive games built for the big screen. ',
      },
      {
        name: 'Curriculums',
        link: '/au/curriculums/',
        type: 'curriculum',
        icon: 'graduation-cap',
        description: 'Browse by curriculum code or learning area.',
      },
      {
        name: 'Teaching Resource Collections',
        link: '/au/teaching-resource-collections/',
        type: 'resource-collection',
        icon: 'folder',
        description: 'Explore resources by theme, topic, strategies, or events.',
      },
      {
        name: 'Teaching Resource Packs',
        link: '/au/teaching-resource-packs/',
        type: 'resource-pack',
        icon: 'folders',
        description: 'Bundles that you can download with one click.',
      },
      {
        name: 'Studio',
        link: '/studio/',
        type: 'studio',
        subdomain: true,
        icon: 'magic',
        description: 'Whip up custom labels, decorations, and worksheets in an instant.',
      },
      {
        name: 'Free Resource Library',
        link: '/au/resource-availability/free/',
        type: 'free',
        icon: 'gift',
        description: '',
      },
    ],
    learning_areas: [
      { name: 'English', link: '/au/learning-area/english/' },
      { name: 'Maths', link: '/au/learning-area/maths/' },
      { name: 'Science', link: '/au/learning-area/science/' },
      { name: 'HASS', link: '/au/learning-area/hass/' },
      { name: 'Health/P.E.', link: '/au/learning-area/health-and-physical-education/' },
      { name: 'The Arts', link: '/au/learning-area/the-arts/' },
      {
        name: 'Languages (Other Than English)',
        link: '/au/learning-area/languages-other-than-english/',
      },
      { name: 'Technology', link: '/au/learning-area/technology/' },
      {
        name: 'Social Emotional Learning',
        link: '/au/learning-area/social-emotional-learning/',
      },
    ],
    year_levels: [
      { name: 'Preschool/Kindergarten', link: '/au/year-level/preschool-kindergarten/' },
      { name: 'Foundation Year', link: '/au/year-level/foundation-year/' },
      { name: 'Year 1', link: '/au/year-level/year-1/' },
      { name: 'Year 2', link: '/au/year-level/year-2/' },
      { name: 'Year 3', link: '/au/year-level/year-3/' },
      { name: 'Year 4', link: '/au/year-level/year-4/' },
      { name: 'Year 5', link: '/au/year-level/year-5/' },
      { name: 'Year 6', link: '/au/year-level/year-6/' },
    ],
    topics: [
      {
        categories: [
          {
            name: 'Labels, Signs & Decorations',
            link: '/au/resource-type/classroom-organisation/',
            items: [
              { name: 'Classroom Displays', link: '/au/resource-type/classroom-displays/' },
              { name: 'Book Covers', link: '/au/resource-type/book-covers-au/' },
              { name: 'Name Tags', link: '/au/resource-type/name-tags-au/' },
              { name: 'Letter Sets', link: '/au/resource-type/letter-sets-au/' },
              {
                name: 'Classroom Theme Packs',
                link: '/au/resource-type/classroom-theme-packs-au/',
              },
              { name: 'Border Trimmers', link: '/au/resource-type/border-trimmers-au/' },
              { name: 'Desk Plates', link: '/au/resource-type/desk-plates-au/' },
              { name: 'Welcome Signs', link: '/au/resource-type/welcome-signs-au/' },
            ],
          },
          {
            name: 'File Formats',
            link: '/au/file-formats/',
            items: [
              { name: 'PDF', link: '/au/file-format/pdf/' },
              { name: 'Word Document', link: '/au/file-format/docx/' },
              { name: 'PowerPoint', link: '/au/file-format/pptx/' },
              { name: 'Google Slides', link: '/au/file-format/google-slides/' },
              { name: 'Google Sheets', link: '/au/file-format/google-sheets/' },
            ],
          },
        ],
      },
      {
        categories: [
          {
            name: 'Class Management',
            link: '/au/classroom-management/',
            items: [
              { name: 'Behaviour', link: '/au/classroom-management/behaviour/' },
              { name: 'Routines', link: '/au/classroom-management/routines/' },
              {
                name: 'Goals and Feedback',
                link: '/au/classroom-management/goals-and-feedback/',
              },
              {
                name: 'Group Organisation',
                link: '/au/classroom-management/group-organisation/',
              },
            ],
          },
          {
            name: 'Teacher Tools',
            link: '/au/teacher-organisation/',
            items: [
              { name: 'Planning', link: '/au/teacher-organisation/planning/' },
              {
                name: 'Report Card Writing',
                link: '/au/teacher-organisation/report-card-writing/',
              },
              {
                name: 'Monitoring Student Progress',
                link: '/au/teacher-organisation/monitoring-student-progress/',
              },
              { name: 'Relief Teaching', link: '/au/teacher-organisation/relief-teaching/' },
              {
                name: 'Parent Communication',
                link: '/au/teacher-organisation/parent-communication/',
              },
            ],
          },
        ],
      },
      {
        categories: [
          {
            name: 'Resource Types',
            link: '/au/resource-types/',
            items: [
              { name: 'Free Resources', link: '/au/resource-availability/free/' },
              { name: 'Games', link: '/au/resource-type/games/' },
              { name: 'Worksheets', link: '/au/resource-type/worksheets/' },
              { name: 'Craft Activities', link: '/au/resource-type/craft-activities/' },
              { name: 'Templates', link: '/au/resource-type/templates/' },
              { name: 'Posters', link: '/au/resource-type/posters/' },
              {
                name: 'Teaching Presentations',
                link: '/au/resource-type/teaching-presentations/',
              },
              { name: 'Word Walls', link: '/au/resource-type/word-walls/' },
              { name: 'Task Cards', link: '/au/resource-type/task-cards/' },
            ],
          },
        ],
      },
    ],
    content_pages: [
      {
        name: 'Blog',
        link: '/au/blog/',
        type: 'blog',
        icon: 'lightbulb',
        description: 'Unlock your teaching potential.',
      },
      {
        name: 'Podcast',
        link: '/au/podcasts/',
        type: 'podcast',
        icon: 'microphone',
        description: 'Where aspiration meets inspiration. ',
      },
      {
        name: 'Webinars',
        link: '/au/webinars/',
        type: 'webinars',
        icon: 'chalkboard-students',
        description: "Expand what's possible for every student.",
      },
    ],
  },
  us: {
    landing_pages: [
      { name: 'How We Help', link: '/l/how-we-help/' },
      { name: 'For Teachers', link: '/l/teach-starter-for-primary-school-teachers/' },
      { name: 'For Schools', link: '/l/teach-starter-for-schools/' },
      { name: 'For Parents', link: '/l/teach-starter-for-parents/' },
      { name: 'For Home Educators', link: '/l/teach-starter-for-homeschooling/' },
      { name: 'For Remote Learning', link: '/l/remote-learning/' },
    ],
    resources: [
      {
        name: 'Teaching Resources',
        link: '/us/teaching-resources/',
        type: 'teaching-resource',
        icon: 'file',
        description: 'Curriculum-aligned resources to  engage and inspire your class.',
      },
      {
        name: 'Units & Lesson Plans',
        link: '/us/unit-plans/',
        type: 'unit-plan',
        icon: 'list',
        description: 'Take your class on an educational adventure over multiple lessons.',
      },
      {
        name: 'Widgets',
        link: '/us/widgets/',
        type: 'widget',
        icon: 'cog',
        description: 'Use simple apps that help you do all kinds of useful things.',
      },
      {
        name: 'Curriculums',
        link: '/us/curriculums/',
        type: 'curriculum',
        icon: 'graduation-cap',
        description: 'Browse by curriculum code or learning area.',
      },
      {
        name: 'Teaching Resource Collections',
        link: '/us/teaching-resource-collections/',
        type: 'resource-collection',
        icon: 'folder',
        description: 'Explore resources by theme, topic, strategies, or events.',
      },
      {
        name: 'Teaching Resource Packs',
        link: '/us/teaching-resource-packs/',
        type: 'resource-pack',
        icon: 'folders',
        description: 'Bundles that you can download with one click.',
      },
      {
        name: 'Studio',
        link: '/studio/',
        type: 'studio',
        subdomain: true,
        icon: 'magic',
        description: 'Customize and create your own teaching resources and display materials.',
      },
      {
        name: 'Free Resource Library',
        link: '/au/resource-availability/free/',
        type: 'free',
        icon: 'gift',
        description: '',
      },
    ],
    learning_areas: [
      { name: 'English Language Arts and Reading', link: '/us/learning-area/elar-us/' },
      { name: 'Mathematics', link: '/us/learning-area/mathematics-us/' },
      { name: 'Science', link: '/us/learning-area/science-us/' },
      { name: 'Social Studies', link: '/us/learning-area/social-studies-us/' },
      { name: 'Health Education', link: '/us/learning-area/health-us/' },
      { name: 'Fine Arts', link: '/us/learning-area/fine-arts-us/' },
      {
        name: 'Languages (Other Than English)',
        link: '/us/learning-area/languages-other-than-american-english/',
      },
      {
        name: 'Social Emotional Learning',
        link: '/us/learning-area/social-emotional-learning-us/',
      },
    ],
    year_levels: [
      { name: 'Pre-K', link: '/us/grade-level/pre-kindergarten/' },
      { name: 'Kindergarten', link: '/us/grade-level/kindergarten/' },
      { name: '1st Grade', link: '/us/grade-level/grade-1/' },
      { name: '2nd Grade', link: '/us/grade-level/grade-2/' },
      { name: '3rd Grade', link: '/us/grade-level/grade-3/' },
      { name: '4th Grade', link: '/us/grade-level/grade-4/' },
      { name: '5th Grade', link: '/us/grade-level/grade-5/' },
      { name: '6th Grade', link: '/us/grade-level/grade-6/' },
      { name: '7th Grade', link: '/us/grade-level/grade-7/' },
    ],
    topics: [
      {
        categories: [
          {
            name: 'Labels, Signs & Decorations',
            link: '/us/resource-type/labels-signs-decorations/',
            items: [
              { name: 'Bulletin Boards', link: '/us/resource-type/bulletin-board-displays/' },
              {
                name: 'Classroom Theme Packs',
                link: '/us/resource-type/classroom-theme-packs-us/',
              },
              { name: 'Banner Display Letter Sets', link: '/us/resource-type/letter-sets-us/' },
              { name: 'Border Trimmers', link: '/us/resource-type/border-trimmers-us/' },
              {
                name: 'Decorations Theme Pack',
                link: '/us/resource-type/decorations-theme-pack-us/',
              },
              { name: 'Desk Plates', link: '/us/resource-type/desk-plates-us/' },
              { name: 'Name Tags', link: '/us/resource-type/name-tags-us/' },
              { name: 'Page Borders', link: '/us/resource-type/page-borders-us/' },
              { name: 'Book Covers', link: '/us/resource-type/book-covers-us/' },
              { name: 'Labels', link: '/us/resource-type/labels-us/' },
              { name: 'Welcome Signs', link: '/us/resource-type/welcome-signs-us/' },
            ],
          },
          {
            name: 'File Formats',
            link: '/us/file-formats/',
            items: [
              { name: 'PDF', link: '/us/file-format/pdf/' },
              { name: 'Word Document', link: '/us/file-format/docx/' },
              { name: 'PowerPoint', link: '/us/file-format/pptx/' },
              { name: 'Google Slides', link: '/us/file-format/google-slides/' },
              { name: 'Google Sheets', link: '/us/file-format/google-sheets/' },
            ],
          },
        ],
      },
      {
        categories: [
          {
            name: 'Classroom Management',
            link: '/us/classroom-management/',
            items: [
              { name: 'Behavior', link: '/us/classroom-management/behavior/' },
              { name: 'Routines', link: '/us/classroom-management/routines-us/' },
              {
                name: 'Goals and Feedback',
                link: '/us/classroom-management/goals-and-feedback-us/',
              },
              {
                name: 'Group Organization',
                link: '/us/classroom-management/group-organization/',
              },
            ],
          },
          {
            name: 'Teacher Tools',
            link: '/us/teacher-organisation/',
            items: [
              { name: 'Planning', link: '/us/teacher-organisation/planning-us/' },
              {
                name: 'Report Card Writing',
                link: '/us/teacher-organisation/report-card-writing-us/',
              },
              {
                name: 'Student Progress Monitoring',
                link: '/us/teacher-organisation/student-progress-monitoring/',
              },
              {
                name: 'Substitute Teaching',
                link: '/us/teacher-organization/substitute-teaching/',
              },
              {
                name: 'Parent Communication',
                link: '/us/teacher-organisation/parent-communication-us/',
              },
            ],
          },
        ],
      },
      {
        categories: [
          {
            name: 'Resource Types',
            link: '/us/resource-types/',
            items: [
              { name: 'Free Resources', link: '/us/resource-availability/free/' },
              { name: 'Games', link: '/us/resource-type/games-us/' },
              { name: 'Worksheets', link: '/us/resource-type/worksheets-us/' },
              { name: 'Craft Activities', link: '/us/resource-type/craft-activities-us/' },
              { name: 'Templates', link: '/us/resource-type/templates-us/' },
              { name: 'Posters', link: '/us/resource-type/posters-us/' },
              {
                name: 'Teaching Presentations',
                link: '/us/resource-type/teaching-presentations-us/',
              },
              { name: 'Word Walls', link: '/us/resource-type/word-walls-us/' },
              { name: 'Task Cards', link: '/us/resource-type/task-cards-us/' },
            ],
          },
        ],
      },
    ],
    content_pages: [
      {
        name: 'Blog',
        link: '/us/blog/',
        type: 'blog',
        icon: 'lightbulb',
        description: 'Unlock your teaching potential.',
      },
      {
        name: 'Podcast',
        link: '/us/podcasts/',
        type: 'podcast',
        icon: 'microphone',
        description: 'Where aspiration meets inspiration. ',
      },
      {
        name: 'Webinars',
        link: '/us/webinars/',
        type: 'webinars',
        icon: 'chalkboard-students',
        description: "Expand what's possible for every student.",
      },
    ],
  },
  gb: {
    landing_pages: [
      { name: 'How We Help', link: '/l/how-we-help/' },
      { name: 'For Teachers', link: '/l/teach-starter-for-primary-school-teachers/' },
      { name: 'For Schools', link: '/l/teach-starter-for-schools/' },
      { name: 'For Parents', link: '/l/teach-starter-for-parents/' },
      { name: 'For Home Educators', link: '/l/teach-starter-for-homeschooling/' },
      { name: 'For Remote Learning', link: '/l/remote-learning/' },
    ],
    resources: [
      {
        name: 'Teaching Resources',
        link: '/gb/teaching-resources/',
        type: 'teaching-resource',
        icon: 'file',
        description: 'Curriculum-aligned resources to  engage and inspire your class.',
      },
      {
        name: 'Units & Lesson Plans',
        link: '/gb/unit-plans/',
        type: 'unit-plan',
        icon: 'list',
        description: 'Take your class on an educational adventure over multiple lessons.',
      },
      {
        name: 'Widgets',
        link: '/gb/widgets/',
        type: 'widget',
        icon: 'cog',
        description: 'Use simple apps that help you do all kinds of useful things.',
      },
      {
        name: 'Curriculum',
        link: '/gb/curriculums/national-curriculum-in-england/',
        type: 'curriculum',
        icon: 'graduation-cap',
        description: 'Browse by curriculum code or learning area.',
      },
      {
        name: 'Teaching Resource Collections',
        link: '/gb/teaching-resource-collections/',
        type: 'resource-collection',
        icon: 'folder',
        description: 'Explore resources by theme, topic, strategies, or events.',
      },
      {
        name: 'Teaching Resource Packs',
        link: '/gb/teaching-resource-packs/',
        type: 'resource-pack',
        icon: 'folders',
        description: 'Bundles that you can download with one click.',
      },
      {
        name: 'Studio',
        link: '/studio/',
        type: 'studio',
        subdomain: true,
        icon: 'magic',
        description: 'Whip up custom labels, decorations and worksheets in an instant.',
      },
      {
        name: 'Free Resource Library',
        link: '/au/resource-availability/free/',
        type: 'free',
        icon: 'gift',
        description: '',
      },
    ],
    learning_areas: [
      { name: 'English', link: '/gb/learning-area/english-gb/' },
      { name: 'Numeracy', link: '/gb/learning-area/numeracy-gb/' },
      { name: 'Science', link: '/gb/learning-area/science-gb/' },
      { name: 'Health/P.E.', link: '/gb/learning-area/health-pe-gb/' },
      { name: 'Geography', link: '/gb/learning-area/geography-gb/' },
      { name: 'Technology', link: '/gb/learning-area/technology-gb/' },
      { name: 'The Arts', link: '/gb/learning-area/the-arts-gb/' },
      { name: 'Social Studies', link: '/gb/learning-area/social-studies-gb/' },
      { name: 'History', link: '/gb/learning-area/history-gb/' },
      {
        name: 'Languages (Other Than English)',
        link: '/gb/learning-area/languages-other-than-british-english/',
      },
      {
        name: 'Special Educational Needs',
        link: '/gb/learning-area/special-educational-needs-gb/',
      },
    ],
    year_levels: [
      { name: 'Foundation Stage', link: '/gb/year-level/foundation-stage/' },
      { name: 'Key Stage 1', link: '/gb/year-level/key-stage-1/' },
      { name: 'Key Stage 2 Lower', link: '/gb/year-level/lower-key-stage-2/' },
      { name: 'Key Stage 2 Upper', link: '/gb/year-level/upper-key-stage-2/' },
      { name: 'Key Stage 3', link: '/gb/year-level/key-stage-3/' },
    ],
    topics: [
      {
        categories: [
          {
            name: 'Labels, Signs & Decorations',
            link: '/gb/resource-type/labels-signs-decorations-gb/',
            items: [
              { name: 'Display Banners', link: '/gb/resource-type/display-banners-gb/' },
              {
                name: 'Classroom Theme Packs',
                link: '/gb/resource-type/classroom-theme-packs-gb/',
              },
              { name: 'Banner Display Letter Sets', link: '/gb/resource-type/letter-sets-gb/' },
              { name: 'Border Trimmers', link: '/gb/resource-type/border-trimmers-gb/' },
              {
                name: 'Decorations Theme Pack',
                link: '/gb/resource-type/decorations-theme-pack-gb/',
              },
              { name: 'Desk Plates', link: '/gb/resource-type/desk-plates-gb/' },
              { name: 'Name Tags', link: '/gb/resource-type/name-tags-gb/' },
              { name: 'Page Borders', link: '/gb/resource-type/page-borders-gb/' },
              { name: 'Book Covers', link: '/gb/resource-type/book-covers-gb/' },
              { name: 'Labels', link: '/gb/resource-type/labels-gb/' },
              { name: 'Welcome Signs', link: '/gb/resource-type/welcome-signs-gb/' },
            ],
          },
          {
            name: 'File Formats',
            link: '/gb/file-formats/',
            items: [
              { name: 'PDF', link: '/gb/file-format/pdf/' },
              { name: 'Word Document', link: '/gb/file-format/docx/' },
              { name: 'PowerPoint', link: '/gb/file-format/pptx/' },
              { name: 'Google Slides', link: '/gb/file-format/google-slides/' },
              { name: 'Google Sheets', link: '/gb/file-format/google-sheets/' },
            ],
          },
        ],
      },
      {
        categories: [
          {
            name: 'Class Management',
            link: '/gb/classroom-management/',
            items: [
              { name: 'Behaviour', link: '/gb/classroom-management/behaviour-gb/' },
              { name: 'Routines', link: '/gb/classroom-management/routines-gb/' },
              {
                name: 'Goals and Feedback',
                link: '/gb/classroom-management/goals-and-feedback-gb/',
              },
              {
                name: 'Group Organisation',
                link: '/gb/classroom-management/group-organisation-gb/',
              },
            ],
          },
          {
            name: 'Teacher Tools',
            link: '/gb/teacher-organisation/',
            items: [
              { name: 'Planning', link: '/gb/teacher-organisation/planning-gb/' },
              {
                name: 'Report Card Writing',
                link: '/gb/teacher-organisation/report-card-writing-gb/',
              },
              {
                name: 'Monitoring Student Progress',
                link: '/gb/teacher-organisation/monitoring-student-progress-gb/',
              },
              { name: 'Supply Teaching', link: '/gb/teacher-organisation/supply-teaching/' },
              {
                name: 'Parent Communication',
                link: '/gb/teacher-organisation/parent-communication-gb/',
              },
            ],
          },
        ],
      },
      {
        categories: [
          {
            name: 'Resource Types',
            link: '/gb/resource-types/',
            items: [
              { name: 'Free Resources', link: '/gb/resource-availability/free/' },
              { name: 'Games', link: '/gb/resource-type/games-gb/' },
              { name: 'Worksheets', link: '/gb/resource-type/worksheets-gb/' },
              { name: 'Craft Activities', link: '/gb/resource-type/craft-activities-gb/' },
              { name: 'Templates', link: '/gb/resource-type/templates-gb/' },
              { name: 'Posters', link: '/gb/resource-type/posters-gb/' },
              {
                name: 'Teaching Presentations',
                link: '/gb/resource-type/teaching-presentations-gb/',
              },
              { name: 'Word Walls', link: '/gb/resource-type/word-walls-gb/' },
              { name: 'Task Cards', link: '/gb/resource-type/task-cards-gb/' },
            ],
          },
        ],
      },
    ],
    content_pages: [
      {
        name: 'Blog',
        link: '/gb/blog/',
        type: 'blog',
        icon: 'lightbulb',
        description: 'Unlock your teaching potential.',
      },
      {
        name: 'Podcast',
        link: '/gb/podcasts/',
        type: 'podcast',
        icon: 'microphone',
        description: 'Where aspiration meets inspiration. ',
      },
      {
        name: 'Webinars',
        link: '/gb/webinars/',
        type: 'webinars',
        icon: 'chalkboard-students',
        description: "Expand what's possible for every student.",
      },
    ],
  },
};

export default navigationLinks;
