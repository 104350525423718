import { render, staticRenderFns } from "./t-accordion.vue?vue&type=template&id=476e5e90&scoped=true"
import script from "./t-accordion.vue?vue&type=script&setup=true&lang=ts"
export * from "./t-accordion.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./t-accordion.vue?vue&type=style&index=0&id=476e5e90&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "476e5e90",
  null
  
)

export default component.exports