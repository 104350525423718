import axios, { AxiosError } from 'axios';
import { computed, ComputedRef, useContext, useStore } from '@nuxtjs/composition-api';
import { Binary, User } from '~/types/user';
import { GradeId } from '~/data/grades';
//Keeping both grade & grades till we are ready to disable one.
interface Meta {
  first_name?: string;
  last_name?: string;
  occupation?: string;
  school_curriculum?: string;
  grades?: GradeId[];
  grade?: GradeId[];
}

interface MailoutRequest {
  newsletters: Binary;
  resources: Binary;
  actions: Binary;
  onboarding: Binary;
  seasonal_promotions: Binary;
}

interface EditableUserFields {
  displayName?: string;
  email?: string;
  meta?: Meta;
  mailout?: MailoutRequest;
  login?: string;
  password?: string;
}

interface EditablePrefFields {
  school?: string;
  school_id?: number;
  classlist?: string;
}

const standardErrorMessage =
  'Oh no, something went wrong. Please try again and if that fails please contact support by clicking on the chat icon in the bottom right of this screen.';

const standardErrorMessageSendGridEmail =
  'Oh no, something went wrong with email for reset password. Please try again and if that fails please contact support by clicking on the chat icon in the bottom right of this screen.';

interface UseUser {
  user: ComputedRef<User | undefined>;
  isAuthenticated: ComputedRef<boolean>;
  updateUser: (id: number, EditableUserFields: EditableUserFields) => Promise<void>;
  updatePrefs: (EditablePrefFields: EditablePrefFields) => Promise<void>;
  verifyEmail: (id: string, verificationCode: string) => Promise<void>;
  resendVerificationEmail: (id: string) => Promise<void>;
  sendResetPasswordEmail: (email: string) => Promise<void>;
  resetUserPassword: (id: number, passwordToken: string, password: string) => Promise<any>;
  getUserDownloadLimit: (token: string) => Promise<any>;
}

export const useUser = (): UseUser => {
  const store = useStore();
  const { $axios, $auth, $amplitude } = useContext();

  const user = computed<User | undefined>(() => store.getters.loggedInUser);
  const isAuthenticated = computed<boolean>(() => store.getters.isAuthenticated);
  const updateUser = async (id: number, EditableUserFields: EditableUserFields): Promise<void> => {
    try {
      await $axios.put('/public/v1/user', {
        data: {
          id,
          ...EditableUserFields,
        },
      });
      await $auth.fetchUser();
    } catch (error) {
      console.error(error);
    }
  };

  const updatePrefs = async (EditablePrefFields: EditablePrefFields): Promise<void> => {
    try {
      await $axios.put('public/v1/user/prefs', {
        fields: {
          ...EditablePrefFields,
        },
      });
      await $auth.fetchUser();
    } catch (error) {
      console.error(error);
    }
  };

  const verifyEmail = async (id: string, verificationCode: string): Promise<void> => {
    await $axios
      .post('/public/v1/user/verify-email', { id, verificationCode })
      .catch((reason: AxiosError) => {
        if (reason.response?.status === 400) {
          throw new Error('Invalid verification code');
        }
        if (reason.response?.status === 404) {
          throw new Error('User not found');
        }
        if (reason.response?.status === 429) {
          $amplitude.track('Account |Too many attempts to verify email', { userId: id });
          throw new Error(
            'Too many attempts. Please request a new verification email from the link below.'
          );
        }
        throw new Error(standardErrorMessage);
      });
    await $auth.fetchUser();
  };

  const resendVerificationEmail = async (id: string): Promise<void> => {
    await $axios
      .put('/public/v1/user/request-email-verification-code', { id })
      .catch((reason: AxiosError) => {
        if (reason.response?.status === 404) {
          throw new Error('User not found');
        }
        throw new Error(standardErrorMessage);
      });
    $amplitude.track('Account | Requested a new verification email', { userId: id });
    await $auth.fetchUser();
  };

  const sendResetPasswordEmail = async (email: string): Promise<any> => {
    try {
      return await $axios.post('/public/v1/user/reset-password', { email });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 429) {
          $amplitude.track('Account | Password resets limit exceeded');
          throw new Error(
            'Too many attempts. Please request a new reset password after 5 minutes or contact suppport'
          );
        }
      }
      throw new Error(standardErrorMessageSendGridEmail);
    }
  };

  const resetUserPassword = async (
    id: number,
    passwordToken: string,
    password: string
  ): Promise<any> => {
    try {
      const response = await $axios.put('/public/v1/user/reset-password', {
        data: {
          id,
          passwordToken,
          password,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          throw new Error(
            'Oh no, something went wrong with reset password. Please try again and if that fails please contact support by clicking on the chat icon in the bottom right of this screen.'
          );
        }
      }
      throw new Error(standardErrorMessage);
    }
  };

  const getUserDownloadLimit = async (token: string): Promise<any> => {
    const { data: response } = await axios.get(
      '/public/v1/user/prefs?fields[]=download_quota_counter',
      {
        headers: { Authorization: token },
      }
    );
    return response;
  };

  return {
    user,
    isAuthenticated,
    updateUser,
    updatePrefs,
    verifyEmail,
    resendVerificationEmail,
    sendResetPasswordEmail,
    resetUserPassword,
    getUserDownloadLimit,
  };
};
