export interface NavigationLink {
  name: string;
  title: string;
  icon?: string;
  type?: string;
  link?: string;
  slug?: string;
  external?: boolean;
  hideOnSmall?: boolean;
}

export const resources: NavigationLink[] = [
  {
    name: 'Studio',
    slug: 'studio',
    title: 'Studio',
    link: 'https://v1.teachstarter.com/studio/',
    icon: 'magic-line',
    external: true,
    hideOnSmall: true,
  },
  {
    slug: 'downloads',
    name: 'Download History',
    link: '/hub/downloads/',
    title: 'Recently downloaded resources',
    type: 'text-download',
    icon: 'arrow-clock',
  },
  {
    slug: 'bookmarks',
    name: 'Bookmarks',
    link: '/hub/bookmarks/',
    title: 'Saved resources',
    type: 'text-bookmark',
    icon: 'bookmark-line',
  },
  {
    slug: 'history',
    name: 'Recently Viewed',
    link: '/hub/history/',
    title: 'Recently viewed resources',
    type: 'text-history',
    icon: 'layers',
  },
  {
    slug: 'studio-files',
    name: 'Studio Files',
    link: '/hub/studio/',
    title: 'Files you edited in Studio',
    type: 'vc-500',
    icon: 'file-layers',
    hideOnSmall: true,
  },
  {
    slug: 'word-lists',
    name: 'Word Lists',
    link: '/hub/word-lists/',
    title: 'Your custom word lists',
    type: 'sf-500',
    icon: 'list-bullets',
  },
  {
    name: 'Folders',
    title: 'Your custom word lists',
    icon: 'folder-line',
    slug: 'my-folders',
  },
];
export const extras: NavigationLink[] = [
  {
    name: 'Refer a Friend',
    slug: 'refer-a-friend',
    title: 'Refer a Friend',
    link: '/member-settings/referrals/',
    icon: 'cycle',
    external: true,
  },
  {
    name: 'Suggest a Resource',
    slug: 'suggest-a-resource',
    title: 'Suggest a Resource',
    link: '/suggest-a-resource/',
    icon: 'file-tick',
  },
  {
    slug: 'help-desk',
    name: 'Help Desk',
    link: 'https://support.teachstarter.com/support/home',
    title: 'How to use Teach Starter',
    icon: 'help-desk',
    external: true,
  },
  {
    slug: 'feedback',
    name: 'Feedback',
    link: 'https://support.teachstarter.com/support/tickets/new',
    title: 'Feedback',
    icon: 'feedback',
    external: true,
  },
];
