import { Plugin } from '@nuxt/types';
import { Resource } from '~/types/resource';
// Parse the HTML resource content to replace the WP shortcodes with HTML elements
// Called from actions.js:loadResource

interface FiltersContentPlugin {
  $replaceResourceShortcodes: (content: string, resources: Resource[], country: string) => string;
  $replaceShortcodes: (content: string) => string;
  $addScriptsToDOM: (content: string) => void;
}

declare module '@nuxt/types' {
  interface Context extends FiltersContentPlugin {}
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> extends FiltersContentPlugin {}
}

// Build a resource card
function constructCard(item: Resource, country: string): string {
  let output = `<div class="card card-zoom"><figure class="card-img"><a href="${
    item.link
  }" title="View ${item.name}"><img src="${item.thumbnail}" loading="lazy" alt="Image of ${
    item.name
  }" onerror="this.src='/images/default_thumbnail.png'"></a></figure><div class="card-content"><h3 class="heading-4 text-${
    item.type
  }">${item.type.replace('-', ' ')}</h3><h4><a href="${item.link}" title="View ${item.name}">${
    item.name
  }</a></h4><p class="line-clamp">${item.excerpt}</p></div>`;

  output += '<footer class="flex">';

  if (item.type === 'blog') {
    output += `<div class="card-meta" rel="author">Posted <time pubdate datetime="${item.created}">${item.created}</time> by <strong rel="author">${item.author.display_name}</strong>&nbsp;</div><span class="label">${item.duration}</span>`;
  } else if (item.type !== 'widget') {
    output += constructCardFooter(item, country);
  }

  output += '</footer></div>';

  return output;
}

function constructCardFooter(item: Resource, country: string): string {
  let output = `<img src="${item.publisher?.logo_square}" class="circle" alt="${item.publisher?.name}" height="20" width="20" loading="lazy" onerror="this.src='/images/ts-sticker-logo-highlighter.svg'"/>`;

  if (item.page_count && item.page_count > 0) {
    output += `<span class="label">${item.page_count} <span>page`;
    output += item.page_count !== 1 ? 's</span></span>' : '</span></span>';
  }

  if (item.year_levels_range) {
    let yearLabel = country === 'us' ? 'Grade' : 'Year';
    // Plural
    if (item.year_levels_range.length > 1) {
      yearLabel += 's';
    }
    output += `<span class="label"><span class="text-mute">${yearLabel}:</span> ${item.year_levels_range}</span></span>`;
  }

  if (item.duration) {
    let unitString = 'mins';
    if (item.duration === 1) {
      unitString = 'min';
    }
    output += `<span class="label">${item.duration} ${unitString}</span>`;
  }

  if (item.has_starter_sheet) {
    output +=
      '<span class="label label-primary"><svg height="11" width="11" role="img"><title>Has Starter Sheet</title><use xlink:href="/images/fa-icons.svg#tack"></use></svg></span>';
  }

  if (item.is_editable) {
    output +=
      '<span class="label label-secondary"><svg height="11" width="11" role="img"><title>Customisable</title><use xlink:href="/images/fa-icons.svg#magic"></use></svg></span>';
  }

  if (item.is_trending) {
    output +=
      '<span class="label label-warning"><svg height="11" width="11" role="img"><title>Trending</title><use xlink:href="/images/fa-icons.svg?v=2#chart"></use></svg></span>';
  }

  return output;
}

const filtersContentPlugin: Plugin = (_, inject) => {
  inject(
    'replaceResourceShortcodes',
    (content: string, resources: Resource[], country: string): string => {
      return content.replace(/(\[resource:)(.+?)\]\s*/gi, function (_, _p1, id) {
        const resource = resources.find(r => r.old_id === parseInt(id));
        return resource ? constructCard(resource, country) : '';
      });
    }
  );

  inject('replaceShortcodes', (content: string): string => {
    // Wistia embed eg. [wistia:3yac1jlavb]

    content = content.replace(
      /(\[wistia:)(.+?)\]/gi,
      '<script async src="https://fast.wistia.com/embed/medias/$2.jsonp"></script><script async src="https://fast.wistia.com/assets/external/E-v1.js"></script><div class="clearfix"></div><div class="flex-video"><div class="wistia_embed wistia_async_$2" style="height:349px;width:620px">&nbsp;</div></div>'
    );

    // YouTube embed eg. [embed:https://www.youtube.com/watch?v=50I9z3EQ6B4] or [embed:https://youtu.be.com/50I9z3EQ6B4]
    content = content.replace(
      /(\[embed:https:\/\/(www.youtube.com\/watch\?v=|youtu.be.com\/))(.+?)\]|<iframe.*?src="https:\/\/www\.youtube\.com\/embed\/([^"]+).*?<\/iframe>/gi,
      '<div class="clearfix"></div><div class="flex-video"><iframe width="560" height="315" src="https://www.youtube.com/embed/$3$4" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen webkitallowfullscreen msallowfullscreen></iframe></div>'
    );

    // Instagram embed eg. [instagram:Bf2oVsLjbkL]
    content = content.replace(
      /(\[instagram:)(.+?)\]/gi,
      '<blockquote class="instagram-media" data-instgrm-version="2"><div><p><a href="https://instagram.com/p/$2/"  target="_top"> View on Instagram</a></p></div></blockquote><script async defer src="//platform.instagram.com/en_US/embeds.js"></script>'
    );

    // Replace relative image paths with absolute ones
    // Remove for production, new image paths to CDN?
    content = content.replace(
      /"\/wp-content\/uploads\/|https:\/\/v1.teachstarter.com\/wp-content\/uploads\//gi,
      '"https://cdn.teachstarter.com/fileserver/'
    );

    return content;
  });

  inject('addScriptsToDOM', (content: string): void => {
    // Create HTML element to make content queryable
    const html = document.createElement('html');
    html.innerHTML = content;

    // Find scripts
    const scripts = html.querySelectorAll('script');

    // Add scripts to DOM
    scripts.forEach(s => {
      const script = document.createElement('script');
      script.src = s.src;
      script.async = true;
      document.head.appendChild(script);
    });
  });
};

export default filtersContentPlugin;
