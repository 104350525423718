interface MegaMenuItem {
  title: string;
  url: string;
}

interface Link {
  title: string;
  url?: string;
  new_filter_experiment_url?: string;
  items?: MegaMenuItem[];
  inlines?: Link[];
  all?: MegaMenuItem;
}

interface MegaMenuCountry {
  browse: Link[];
  first_row_links: Link[];
  user_links: Link[];
}

interface MegaMenu {
  au: MegaMenuCountry;
  us: MegaMenuCountry;
  gb: MegaMenuCountry;
}

const megaMenuLinks: MegaMenu = {
  au: {
    first_row_links: [
      {
        title: 'Resources',
        url: '/au/search/',
      },
      //TODO: needs updating
      {
        title: 'Tips',
        url: '/au/blog/',
      },
      {
        title: 'Tools',
        url: '/au/search/?postTypeTitle%5B0%5D=Widget',
        new_filter_experiment_url: '/au/search/?filter-format%5B0%5D=Widget',
      },
      {
        title: 'Pricing',
        url: '/au/plans/',
      },
    ],
    browse: [
      {
        title: 'Resources',
        url: '/au/teaching-resources/',
        items: [
          {
            title: 'Unit & Lesson Plans ',
            url: '/au/unit-plans/',
          },
          {
            title: 'Teaching Slides',
            url: '/au/search/?filter-resource-type.lvl0%5B0%5D=Teaching%20Slides',
          },
          {
            title: 'Assessment Tools',
            url: '/au/search/?filter-resource-type.lvl0%5B0%5D=Assessment%20Tools',
          },
          {
            title: 'Resource Packs',
            url: '/au/teaching-resource-packs/',
          },
          {
            title: 'Widgets',
            url: '/au/widgets/',
          },

          {
            title: 'Studio',
            url: '/au/search/studio/',
          },
          {
            title: 'Worksheets',
            url: '/au/resource-type/worksheets/',
          },
          {
            title: 'Task Cards',
            url: '/au/resource-type/task-cards/',
          },
          {
            title: 'Flashcards',
            url: '/au/resource-type/flashcards/',
          },
          {
            title: 'Puzzles',
            url: '/au/resource-type/puzzles/',
          },
          {
            title: 'Games',
            url: '/au/resource-type/games/',
          },
          {
            title: 'Crafts',
            url: '/au/resource-type/craft-activities/',
          },
          {
            title: 'Classroom Décor ',
            url: '/au/search/?filter-resource-type.lvl0%5B0%5D=Classroom%20Decor',
          },
          {
            title: 'Tray Labels',
            url: '/au/resource-type/traylabels/',
          },
          {
            title: 'Desk Plates',
            url: '/au/resource-type/desk-plates-au/',
          },
          {
            title: 'Name Tags',
            url: '/au/resource-type/name-tags-au/',
          },
          {
            title: 'Posters',
            url: '/au/resource-type/posters/',
          },

          {
            title: 'Word Walls',
            url: '/au/resource-type/word-walls/',
          },
        ],
        all: {
          title: 'All Resources',
          url: '/au/search/',
        },
      },
      {
        title: 'Tips & Tools',
        url: '/au/search/',
        items: [
          {
            title: 'Report Card Writing',
            url: '/au/teacher-organisation/report-card-writing/',
          },
          {
            title: 'Classroom Management',
            url: '/au/classroom-management/',
          },
          {
            title: 'Behaviour Management',
            url: '/au/classroom-management/behaviour/',
          },
          {
            title: 'Student Goals & Feedback',
            url: '/au/learning-area/goal-setting/',
          },
          {
            title: 'Parent Communication',
            url: '/au/teacher-organisation/parent-communication/',
          },
          {
            title: 'Teaching Planning',
            url: '/au/teacher-organisation/planning/',
          },
        ],
        inlines: [
          {
            title: 'Blogs',
            url: '/au/blog/',
          },
          {
            title: 'Podcasts',
            url: '/au/podcasts/',
          },
          {
            title: 'Webinars',
            url: '/au/webinars/',
          },
        ],
        all: {
          title: 'All Tips & Tools',
          url: '/au/search/',
        },
      },
      {
        title: 'Topics',
        url: '/au/teaching-resource-collections/',
        items: [
          {
            title: 'Classroom Events',
            url: '/au/teaching-resource-collections/',
          },
          {
            title: 'Social Emotional Learning',
            url: '/au/learning-area/social-emotional-learning/',
          },
          {
            title: 'Curriculum',
            url: '/au/curriculums/',
          },
          {
            title: 'Differentiated',
            url: '/au/search/?filter-attribute%5B0%5D=Differentiated',
          },
          {
            title: 'Free Resources',
            url: '/au/resource-availability/free/',
          },
        ],
        all: {
          title: 'All Topics',
          url: '/au/teaching-resource-collections/',
        },
      },
    ],
    user_links: [
      {
        title: 'Hub',
        items: [
          { title: 'Downloads', url: '/au/hub/downloads/' },
          { title: 'Bookmarks', url: '/au/hub/bookmarks/' },
          { title: 'History', url: '/au/hub/history/' },
          { title: 'Studio Files', url: '/au/hub/studio/' },
          { title: 'Word Lists', url: '/au/hub/word-lists/' },
          { title: 'Suggestions', url: '/au/hub/word-lists/' },
          { title: 'My Folders', url: '/au/hub/folders/' },
        ],
      },
      {
        title: 'Settings',
        url: '/au/member-settings/profile/',
      },
      { title: 'Home', url: '/au/hub/' },
    ],
  },
  us: {
    first_row_links: [
      {
        title: 'Resources',
        url: '/us/search/',
      },
      //TODO: needs updating
      {
        title: 'Tips',
        url: '/us/blog/',
      },
      {
        title: 'Tools',
        url: '/us/search/?postTypeTitle%5B0%5D=Widget',
        new_filter_experiment_url: '/us/search/?filter-format%5B0%5D=Widget',
      },
      {
        title: 'Pricing',
        url: '/us/plans/',
      },
    ],
    browse: [
      {
        title: 'Resources',
        url: '/us/teaching-resources/',
        items: [
          {
            title: 'Unit & Lesson Plans ',
            url: '/us/unit-plans/',
          },
          {
            title: 'Teaching Slides',
            url: '/us/search/?filter-resource-type.lvl0%5B0%5D=Teaching%20Slides',
          },
          {
            title: 'Formative Assessments',
            url: '/us/classroom-management/formative-assessments/',
          },
          {
            title: 'Classroom Videos',
            url: '/us/search/classroom%20videos/',
          },
          {
            title: 'Resource Packs',
            url: '/us/teaching-resource-packs/',
          },
          {
            title: 'Widgets',
            url: '/us/widgets/',
          },

          {
            title: 'Studio',
            url: '/us/search/studio/',
          },
          {
            title: 'Worksheets',
            url: '/us/resource-type/worksheets-us/',
          },
          {
            title: 'Task Cards',
            url: '/us/resource-type/task-cards-us/',
          },
          {
            title: 'Flashcards',
            url: '/us/resource-type/flashcards-us/',
          },
          {
            title: 'Puzzles',
            url: '/us/resource-type/puzzles-us/',
          },
          {
            title: 'Games',
            url: '/us/resource-type/games-us/',
          },
          {
            title: 'Crafts',
            url: '/us/resource-type/craft-activities-us/',
          },
          {
            title: 'Bulletin Board Bundles ',
            url: '/us/resource-type/bulletin-board-displays/',
          },
          {
            title: 'Tray Labels',
            url: '/us/resource-type/tray-labels/',
          },
          {
            title: 'Desk Plates',
            url: '/us/resource-type/desk-plates-us/',
          },
          {
            title: 'Name Tags',
            url: '/us/search/name%20tags/',
          },
          {
            title: 'Posters',
            url: '/us/resource-type/posters-us/',
          },
          {
            title: 'Word Walls',
            url: '/us/resource-type/word-walls-us/',
          },
        ],
        all: {
          title: 'All Resources',
          url: '/us/search/',
        },
      },
      {
        title: 'Tips & Tools',
        url: '/us/search/',
        items: [
          {
            title: 'Classroom Management',
            url: '/us/classroom-management/',
          },
          {
            title: 'Behavior Management',
            url: '/us/classroom-management/behavior/',
          },
          {
            title: 'Classroom Communication',
            url: '/us/classroom-management/classroom-communication/',
          },
          {
            title: 'Classroom Organization',
            url: '/us/teacher-organisation/classroom-organization/',
          },
          {
            title: 'Forms & Checklists',
            url: '/us/teacher-organisation/forms-and-checklists/',
          },
          {
            title: 'Classroom Tools',
            url: '/us/teacher-organisation/classroom-tools/',
          },
          {
            title: 'Teacher Planning',
            url: '/us/teacher-organisation/planning-us/',
          },
        ],
        inlines: [
          {
            title: 'Blogs',
            url: '/us/blog/',
          },
        ],
        all: {
          title: 'All Tips & Tools',
          url: '/us/search/',
        },
      },
      {
        title: 'Topics',
        url: '/us/teaching-resource-collections/',
        items: [
          {
            title: 'Classroom Events',
            url: '/us/teaching-resource-collections/',
          },
          {
            title: 'Social Emotional Learning',
            url: '/us/learning-area/social-emotional-learning-us/',
          },
          {
            title: 'Differentiated',
            url: '/us/search/?filter-attribute%5B0%5D=Differentiated',
          },
          {
            title: 'Free Resources',
            url: '/us/resource-availability/free/',
          },
        ],
        all: {
          title: 'All Topics',
          url: '/us/teaching-resource-collections/',
        },
      },
    ],
    user_links: [
      {
        title: 'Hub',
        items: [
          { title: 'Downloads', url: '/us/hub/downloads/' },
          { title: 'Bookmarks', url: '/us/hub/bookmarks/' },
          { title: 'History', url: '/us/hub/history/' },
          { title: 'Studio Files', url: '/us/hub/studio/' },
          { title: 'Word Lists', url: '/us/hub/word-lists/' },
          { title: 'Suggestions', url: '/us/hub/word-lists/' },
          { title: 'My Folders', url: '/us/hub/folders/' },
        ],
      },
      {
        title: 'Settings',
        url: '/us/member-settings/profile/',
      },
      { title: 'Home', url: '/us/hub/' },
    ],
  },
  gb: {
    first_row_links: [
      {
        title: 'Resources',
        url: '/gb/search/',
      },
      //TODO: needs updating
      {
        title: 'Tips',
        url: '/gb/blog/',
      },
      {
        title: 'Tools',
        url: '/gb/search/?postTypeTitle%5B0%5D=Widget',
        new_filter_experiment_url: '/gb/search/?filter-format%5B0%5D=Widget',
      },
      {
        title: 'Pricing',
        url: '/gb/plans/',
      },
    ],
    browse: [
      {
        title: 'Resources',
        url: '/gb/teaching-resources/',
        items: [
          {
            title: 'Unit & Lesson Plans ',
            url: '/gb/unit-plans/',
          },
          {
            title: 'Teaching Slides',
            url: '/gb/search/?filter-resource-type.lvl0%5B0%5D=Teaching%20Slides/',
          },
          {
            title: 'Student Certificates',
            url: '/gb/resource-type/student-certificates-gb/',
          },
          {
            title: 'Classroom Videos',
            url: '/gb/search/classroom%20videos/',
          },
          {
            title: 'Resource Packs',
            url: '/gb/teaching-resource-packs/',
          },
          {
            title: 'Widgets',
            url: '/gb/widgets/',
          },

          {
            title: 'Studio',
            url: '/gb/search/studio/',
          },
          {
            title: 'Worksheets',
            url: '/gb/resource-type/worksheets-gb/',
          },
          {
            title: 'Task Cards',
            url: '/gb/resource-type/task-cards-gb/',
          },
          {
            title: 'Flashcards',
            url: '/gb/resource-type/flashcards-gb/',
          },
          {
            title: 'Puzzles',
            url: '/gb/resource-type/puzzles-gb/',
          },
          {
            title: 'Games',
            url: '/gb/resource-type/games-gb/',
          },
          {
            title: 'Crafts',
            url: '/gb/resource-type/craft-activities-gb/',
          },
          {
            title: 'Classroom Décor ',
            url: '/gb/search/?filter-resource-type.lvl0%5B0%5D=Classroom%20Decor',
          },
          {
            title: 'Tray Labels',
            url: '/gb/resource-type/tray-labels-gb/',
          },
          {
            title: 'Desk Plates',
            url: '/gb/resource-type/desk-plates-gb/',
          },
          {
            title: 'Name Tags',
            url: '/gb/resource-type/name-tags-gb/',
          },
          {
            title: 'Posters',
            url: '/gb/resource-type/posters-gb/',
          },

          {
            title: 'Word Walls',
            url: '/gb/resource-type/word-walls-gb/',
          },
        ],
        all: {
          title: 'All Resources',
          url: '/gb/search/',
        },
      },
      {
        title: 'Tips & Tools',
        url: '/gb/search/',
        items: [
          {
            title: 'Classroom Management',
            url: '/gb/classroom-management/',
          },
          {
            title: 'Behaviour Management',
            url: '/gb/classroom-management/behaviour-gb/',
          },
          {
            title: 'Classroom Tools',
            url: '/gb/teacher-organisation/',
          },
          {
            title: 'Teacher Planning',
            url: '/gb/teacher-organisation/planning-gb/',
          },
        ],
        inlines: [
          {
            title: 'Blogs',
            url: '/gb/blog/',
          },
          {
            title: 'Podcasts',
            url: '/gb/podcasts/',
          },
          {
            title: 'Webinars',
            url: '/gb/webinars/',
          },
        ],
        all: {
          title: 'All Tips & Tools',
          url: '/gb/search/',
        },
      },
      {
        title: 'Topics',
        url: '/gb/teaching-resource-collections/',
        items: [
          {
            title: 'Classroom Events',
            url: '/gb/curriculums/national-curriculum-in-england/',
          },
          {
            title: 'National Curriculum',
            url: '/gb/curriculums/national-curriculum-in-england/',
          },
          {
            title: 'Free Resources',
            url: '/gb/resource-availability/free/',
          },
        ],
        all: {
          title: 'All Topics',
          url: '/gb/teaching-resource-collections/',
        },
      },
    ],
    user_links: [
      {
        title: 'Hub',
        items: [
          { title: 'Downloads', url: '/gb/hub/downloads/' },
          { title: 'Bookmarks', url: '/gb/hub/bookmarks/' },
          { title: 'History', url: '/gb/hub/history/' },
          { title: 'Studio Files', url: '/gb/hub/studio/' },
          { title: 'Word Lists', url: '/gb/hub/word-lists/' },
          { title: 'Suggestions', url: '/gb/hub/word-lists/' },
          { title: 'My Folders', url: '/gb/hub/folders/' },
        ],
      },
      {
        title: 'Settings',
        url: '/gb/member-settings/profile/',
      },
      { title: 'Home', url: '/gb/hub/' },
    ],
  },
};

export default megaMenuLinks;
