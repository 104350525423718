import axios, { AxiosInstance } from 'axios';
import { BillingInfo, Invoice, Subscription } from '~/types/payment';
import { downloadFileBlob } from '~/utils/download';

interface CreateSubscriptionRequest {
  currency: string;
  userId: string;
  firstName: string;
  lastName: string;
  tokenId: string;
  planCode: string;
  coupons?: string[];
  giftCard?: string;
  threeDSecureActionResultTokenId?: string;
  referralCode?: string;
}

interface UpgradeSubscriptionRequest {
  userId: string;
  subscriptionId: string;
  planCode: string;
}

interface UpgradeSubscriptionPreviewResponse {
  status: number;
  priceDifference: number;
}

interface UpgradeSubscriptionResponse {
  subscriptionChange: any;
}

interface UpdateBillingRequest {
  userId: string;
  billingInfoId: string;
  firstName: string;
  lastName: string;
  tokenId: string;
}

interface BillingInfoResponse {
  billingInfo: BillingInfo;
}

interface GetSubscriptionResponse {
  subscription: Subscription;
}

interface AddBillingRequest {
  userId: string;
  firstName: string;
  lastName: string;
  tokenId: string;
  primaryPaymentMethod?: boolean;
}

interface SubscriptionApi {
  create(requestData: CreateSubscriptionRequest): Promise<void>;
  upgrade(requestData: UpgradeSubscriptionRequest): Promise<UpgradeSubscriptionResponse>;
  upgradePreview(
    requestData: UpgradeSubscriptionRequest
  ): Promise<UpgradeSubscriptionPreviewResponse>;
  getBillingInfo(userId: string): Promise<BillingInfoResponse>;
  updateBillingInfo(requestData: UpdateBillingRequest): Promise<BillingInfoResponse>;
  getInvoices(userId: string): Promise<Invoice[]>;
  downloadInvoice(userId: string, invoiceId: string): Promise<void>;
  getSubscription(userId: string, subscriptionId: string): Promise<GetSubscriptionResponse>;
  redeemGiftCard(userId: string, cardNumber: string): Promise<void>;
  cancelSubscription(userId: string, subscriptionId: string): Promise<void>;
  addBillingInfo(requestData: AddBillingRequest): Promise<BillingInfoResponse>;
}

export default ($axios: AxiosInstance): SubscriptionApi => ({
  async create(requestData: CreateSubscriptionRequest): Promise<void> {
    await $axios.post('/private/v1/payment/purchase', requestData);
  },

  async upgrade({
    userId,
    subscriptionId,
    planCode,
  }: UpgradeSubscriptionRequest): Promise<UpgradeSubscriptionResponse> {
    const { data } = await $axios.post(`/private/v1/subscriptions/${subscriptionId}/change`, {
      userId,
      planCode,
    });

    return data;
  },

  async upgradePreview({
    userId,
    subscriptionId,
    planCode,
  }: UpgradeSubscriptionRequest): Promise<UpgradeSubscriptionPreviewResponse> {
    const { data } = await $axios.post(
      `/private/v1/subscriptions/${subscriptionId}/change/preview`,
      {
        userId,
        planCode,
      }
    );
    return data;
  },

  async getBillingInfo(userId: string): Promise<BillingInfoResponse> {
    const { data } = await $axios.get(`/private/v1/payment/accounts/${userId}/billing`);
    return data;
  },

  async updateBillingInfo({
    userId,
    firstName,
    lastName,
    tokenId,
    billingInfoId,
  }: UpdateBillingRequest): Promise<BillingInfoResponse> {
    const { data } = await $axios.put(`/private/v1/payment/accounts/${userId}/billing`, {
      firstName,
      lastName,
      tokenId,
      billingInfoId,
    });
    return data;
  },

  async getInvoices(userId: string): Promise<Invoice[]> {
    const { data } = await $axios.get(`/private/v1/payment/accounts/${userId}/invoices`);
    return data.invoices.map((invoice: any): Invoice => {
      return {
        number: invoice.number,
        id: invoice.id,
        date: invoice.closedAt,
        status: invoice.state,
        amount: invoice.total,
        currency: invoice.currency,
      };
    });
  },

  async downloadInvoice(userId: string, invoiceId: string): Promise<void> {
    try {
      const { data } = await $axios.get(
        `/private/v1/payment/accounts/${userId}/invoices/${invoiceId}/download`,
        {
          responseType: 'arraybuffer',
        }
      );
      const blob = new Blob([data], { type: 'application/pdf' });
      const filename = 'invoice.pdf';
      downloadFileBlob(blob, filename);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const data = error.response?.data;
        if (data) {
          // Data will be an instance of ArrayBuffer
          const decoder = new TextDecoder('utf-8');
          const decodedError = decoder.decode(data);

          throw new Error(`There was an error downloading your file: ${decodedError || data}`);
        }
      }

      throw error;
    }
  },

  async getSubscription(userId: string, subscriptionId: string): Promise<GetSubscriptionResponse> {
    const { data } = await $axios.get(
      `/private/v1/payment/accounts/${userId}/subscriptions/${subscriptionId}`
    );
    return data;
  },

  async redeemGiftCard(userId: string, cardNumber: string): Promise<void> {
    await $axios.post(`/private/v1/payment/gift-cards/${cardNumber}/redeem`, {
      userId,
    });
  },

  async cancelSubscription(userId: string, subscriptionId: string): Promise<void> {
    await $axios.put(
      `/private/v1/payment/accounts/${userId}/subscriptions/${subscriptionId}/cancel`
    );
  },

  async addBillingInfo({
    userId,
    firstName,
    lastName,
    tokenId,
    primaryPaymentMethod,
  }: AddBillingRequest): Promise<BillingInfoResponse> {
    const { data } = await $axios.post(`/private/v1/payment/accounts/${userId}/billing/add`, {
      firstName,
      lastName,
      tokenId,
      primaryPaymentMethod,
    });
    return data;
  },
});
