import { Plugin } from '@nuxt/types';
import { User } from '~/types/user';

interface ProfitwellPlugin {
  $addProfitwellScript: (userFromLogin?: User) => void;
  $removeEmailFromProfitwellScript: () => void;
}

declare module '@nuxt/types' {
  interface Context extends ProfitwellPlugin {}
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> extends ProfitwellPlugin {}
}

declare global {
  interface Window {
    profitwell?: string;
  }
}

const profitwellPlugin: Plugin = ({ store }, inject): void => {
  const removeScripts = (): void => {
    const profitWellScriptElement = document.getElementById('profitwell-script');
    const profitWellJsElement = document.getElementById('profitwell-js');
    if (profitWellScriptElement) {
      profitWellScriptElement.remove();
    }
    if (profitWellJsElement) {
      profitWellJsElement.remove();
    }
  };
  const baseProfitwellScript = `
    /* DO NOT DELETE - this code is used to accept our payments or send data to ProfitWell*/
    (function(i,s,o,g,r,t,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
    a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.id=t;a.src=r+'?auth='+
    s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
    })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js', 'profitwell-script');`;

  inject('addProfitwellScript', (userFromLogin = null) => {
    removeScripts();
    delete window.profitwell;
    const publicApiToken = 'fb9130a89930e2b83de4a7953e6f72be';
    const currentUser = store.getters.loggedInUser || userFromLogin;

    const script = document.createElement('script');
    script.setAttribute('async', 'true');
    script.setAttribute('id', 'profitwell-js');
    script.setAttribute('data-pw-auth', publicApiToken);
    script.setAttribute('type', 'text/javascript');
    script.innerHTML = baseProfitwellScript;
    if (currentUser && currentUser.email) {
      script.innerHTML += `profitwell('start', { 'user_email': '${currentUser.email}' });`;
    } else {
      script.innerHTML += "profitwell('start', {});";
    }
    document.head.appendChild(script);
  });
  inject('removeEmailFromProfitwellScript', () => {
    delete window.profitwell;
    const profitWellScriptElement = document.getElementById('profitwell-js');
    if (profitWellScriptElement) {
      profitWellScriptElement.innerHTML = baseProfitwellScript;
      profitWellScriptElement.innerHTML += "profitwell('start', {});";
    }
  });
};

export default profitwellPlugin;
