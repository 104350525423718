// Construct canonical and hreflang links for resources.
// Hreflangs are generated from the supplied resource.linked data.
// @todo: integrate the i18n modules hreflang generation

import { Route } from 'vue-router/types';
import { MetaInfo } from 'vue-meta';
import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import { removeLocale } from '~/utils/formatStrings';
import { appendTrailingSlash } from '~/utils/route';
import { CountryLink } from '~/types/resource';
import countries from '~/data/countries';
type PartialRoute = Pick<Route, 'path' | 'query'>;

type MetaLinks = NonNullable<MetaInfo['link']>;

export const pathWithPagination = (route: PartialRoute): string | undefined => {
  return route?.query?.page ? `${route?.path}?page=${route.query.page}` : route?.path ?? undefined;
};

const hasPagination = (route: PartialRoute): boolean => {
  return !!route?.query?.page;
};

export function hrefLangsResource(
  baseURL: string
): (
  linked: CountryLink[] | [],
  link: string,
  country: string,
  route: PartialRoute,
  resourceTypeString?: string
) => MetaLinks {
  return (
    linked: CountryLink[] | [],
    link: string,
    country: string,
    route: PartialRoute,
    extraPath = ''
  ): MetaLinks => {
    const validLinks = linked
      .filter(item => countries.find(country => country.code === item.country))
      .filter((item, index, self) => index === self.findIndex(t => t.country === item.country));
    const extra = extraPath ? removeLocale(extraPath) : '';
    const path = appendTrailingSlash(pathWithPagination(route) ?? link);
    const pageCanonicalLink = {
      hid: 'canonical',
      rel: 'canonical',
      href: baseURL + path,
    };

    // If the page has a page value, only return the canonical link
    if (hasPagination(route)) {
      return [pageCanonicalLink];
    }

    const hrefLangCurrent = {
      hid: 'hreflang-current',
      rel: 'alternate',
      hreflang: 'en-' + country,
      href: baseURL + appendTrailingSlash(link + extra),
    };

    const otherLinksByCountry: [string, string][] = validLinks.map(item => [
      item.country,
      item.link,
    ]);
    const allLinksByCountry: Map<string, string> = new Map([
      ...otherLinksByCountry,
      [country, link],
    ]);

    const xDefaultLink = (): string => {
      if (country === 'us') {
        return link;
      } else {
        return appendTrailingSlash(
          allLinksByCountry.get('us') ?? allLinksByCountry.get('au') ?? link
        );
      }
    };

    const alternateHrefLangs = validLinks.map(item => ({
      hid: 'hreflang-' + item.country,
      rel: 'alternate',
      hreflang: 'en-' + item.country,
      href: baseURL + appendTrailingSlash(item.link + extra),
    }));

    const xDefault = {
      hid: 'x-default',
      rel: 'alternate',
      hreflang: 'x-default',
      href: baseURL + appendTrailingSlash(xDefaultLink() + extra),
    };

    const regionIndependentLink = {
      ...xDefault,
      hid: 'hreflang-en',
      hreflang: 'en',
    };

    return [
      pageCanonicalLink,
      regionIndependentLink,
      ...alternateHrefLangs,
      hrefLangCurrent,
      xDefault,
    ];
  };
}

declare module '@nuxt/types' {
  interface Context {
    $hrefLangsResource(
      ...params: Parameters<ReturnType<typeof hrefLangsResource>>
    ): ReturnType<ReturnType<typeof hrefLangsResource>>;
    $hrefLangsPage(route: Route, root?: string, countries?: string[]): MetaLinks;
    $canonicalPage(route: Route): MetaLinks;
  }
}

export default ({ $config: { baseURL } }: Context, inject: Inject): void => {
  inject('hrefLangsResource', hrefLangsResource(baseURL));

  /* Add hreflangs to regular pages, 'link' is the current link of page, 'root' is the link without country code */
  inject('hrefLangsPage', (route: Route, root?: string, countries = ['au', 'us', 'gb']) => {
    let i = 0;

    if (!root) {
      root = route.path.replace(/\/au|\/us|\/gb/g, '');
    }

    // Page canonical link
    const hreflangs: MetaLinks = [
      {
        hid: 'canonical',
        rel: 'canonical',
        href: baseURL + appendTrailingSlash(pathWithPagination(route) ?? ''),
      },
    ];

    // If the page has a page value, only return the canonical link
    if (hasPagination(route)) {
      return hreflangs;
    }

    // Include a region independent link
    hreflangs.push({
      hid: 'hreflang-en',
      rel: 'alternate',
      hreflang: 'en',
      href: baseURL + appendTrailingSlash(root),
    });

    for (; i < countries.length; i++) {
      hreflangs.push({
        hid: 'hreflang-' + countries[i],
        rel: 'alternate',
        hreflang: 'en-' + countries[i],
        href: baseURL + '/' + appendTrailingSlash(countries[i] + root),
      });
    }

    // Include the x-default in hreflangs - URL without country prefix. Needed?
    hreflangs.push({
      hid: 'x-default',
      rel: 'alternate',
      hreflang: 'x-default',
      href: baseURL + appendTrailingSlash(root),
    });

    return hreflangs;
  });

  // Page canonical link
  inject('canonicalLink', (route: Route) => {
    return [
      {
        hid: 'canonical',
        rel: 'canonical',
        href: baseURL + appendTrailingSlash(pathWithPagination(route) ?? ''),
      },
    ];
  });
};
