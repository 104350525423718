import Vue from 'vue';
import NuxtJsonld from 'nuxt-jsonld';
import { Context } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';
import { Resource } from '~/types/resource';

Vue.use(NuxtJsonld);

declare module '@nuxt/types' {
  interface Context {
    // TODO: More specific types for resource and category
    $jsonSchemaResource(resource: Resource): Record<string, unknown>;
    $jsonSchemaPage(title: string | null, path: string): Record<string, unknown>;
    $jsonSchemaPagewithFAQs(
      faqs: Record<string, string>[],
      title: string | null,
      path: string
    ): Record<string, unknown>;
  }
}

export default ({ $config: { baseURL } }: Context, inject: Inject): void => {
  inject('jsonSchemaResource', (resource: Resource) => {
    // Return structured data for a Resource page
    if (!resource) {
      return null;
    }

    const type = resource.type.replace('-', ' ');
    const title = resource.name.replace(/"/g, '');
    const description = resource.excerpt.replace(/(<([^>]+)>|")/gi, '');

    let schema: Record<string, unknown> = {};

    if (resource.type === 'blog') {
      schema = {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        headline: title,
        description,
        image: resource.thumbnail,
        thumbnailUrl: resource.thumbnail,
        genre: 'Educational',
        articleSection: resource.categories.length > 0 ? resource.categories[0].name : 'Teaching',
        keywords: resource.focus_keywords ? resource.focus_keywords : null,
        countryOfOrigin: resource.country,
        dateCreated: resource.modified,
        dateModified: resource.created,
        datePublished: resource.created,
        url: baseURL + resource.link,
        wordcount: resource.word_count,
        isAccessibleForFree: true,
        isFamilyFriendly: true,
        inLanguage: '',
        creator: {
          '@type': 'Person',
          name: resource.author.display_name,
        },
        author: {
          '@type': 'Person',
          name: resource.author.display_name,
        },
        publisher: {
          '@type': 'Organization',
          name: resource.publisher.name,
          logo: resource.publisher.logo,
        },
        copyrightHolder: { '@type': 'Organization', name: 'Teach Starter' },
      };
    } else if (resource) {
      const keywords = resource.tags
        .map(c => c.type + ' | ' + c.name)
        .concat(
          resource.file_formats.map(
            c =>
              'File Format | ' + (typeof c === 'string' ? c.toUpperCase() : c.format.toUpperCase())
          )
        );

      schema = {
        '@context': 'https://schema.org',
        '@type': 'CreativeWork',
        headline: title,
        description,
        image: resource.thumbnail,
        thumbnailUrl: resource.thumbnail,
        genre: type,
        articleSection: type,
        learningResourceType: type,
        keywords,
        teaches: resource.tags.map(c => c.name),
        educationalLevel: resource.year_levels,
        countryOfOrigin: resource.country,
        dateCreated: resource.modified,
        dateModified: resource.created,
        datePublished: resource.created,
        creativeWorkStatus: 'published',
        size: resource.page_count ? resource.page_count + ' pages' : 0,
        isAccessibleForFree: resource.is_free,
        url: baseURL + resource.link,
        inLanguage: '',
        creator: {
          '@type': 'Person',
          name: resource.author.display_name,
        },
        author: {
          '@type': 'Person',
          name: resource.author.display_name,
        },
        contributor: {
          '@type': 'Person',
          name: resource.designer ? resource.designer.display_name : 'Unknown',
        },
        publisher: {
          '@type': 'Organization',
          name: resource.publisher.name,
          logo: resource.publisher.logo,
        },
      };

      // Optional designer
      if (resource.designer) {
        schema.contributor = {
          '@type': 'Person',
          name: resource.designer.display_name,
        };
      }
    }

    return schema;
  });
  inject('jsonSchemaPage', (title: string | null, path: string) => {
    // Return structured data for a Resource page
    if (!title) {
      return null;
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'WebPage',
      headline: title,
      url: baseURL + path,
    };
  });
  inject(
    'jsonSchemaPagewithFaqs',
    (
      faqs: {
        question: string;
        answer: string;
      }[],
      title: string | null,
      path: string
    ) => {
      if (faqs.length === 0) {
        return {
          '@context': 'https://schema.org',
          '@graph': [{ '@type': 'WebPage', headline: title, url: baseURL + path }],
        };
      }
      const formattedFaqs = faqs.map(faq => {
        const faqObj = {
          '@type': 'Question',
          name: '',
          acceptedAnswer: { '@type': 'Answer', text: '' },
        };
        faqObj.name = typeof faq.question === 'string' ? faq.question : '';
        faqObj.acceptedAnswer.text = typeof faq.answer === 'string' ? faq.answer : '';
        return faqObj;
      });

      return {
        '@context': 'https://schema.org',
        '@graph': [
          { '@type': 'FAQPage', mainEntity: formattedFaqs },
          { '@type': 'WebPage', headline: title, url: baseURL + path },
        ],
      };
    }
  );
};
