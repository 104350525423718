var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('aside',{class:[
      'sm-nav-height tw-fixed tw-z-[9] tw-w-auto tw-border tw-border-neutral-200 tw-bg-white',
      {
        '!tw-w-[15.5rem] !tw-items-start tw-overflow-y-scroll': !_setup.sideNavClosed,
      },
    ]},[(_setup.sideNavClosed)?_c('button',{staticClass:"tw-flex !tw-h-full tw-flex-col tw-items-center tw-px-4 tw-pt-6 md:tw-pt-8 lg:tw-pt-10 xl:tw-pt-14 2xl:tw-pt-16",on:{"click":_setup.toggleSideNav}},[_c(_setup.UserProfileV2,{attrs:{"side-nav-closed":_setup.sideNavClosed}}),_vm._v(" "),_c('ul',[_vm._l((_setup.resources),function(item){return [(!item?.hideOnSmall)?_c('li',{key:`icon-${item.slug}`,staticClass:"tw-flex tw-py-3"},[_c('svg',{class:['tw-h-6 tw-w-6 tw-text-gray-900', { 'tw-mr-3': !_setup.sideNavClosed }],attrs:{"role":"img","aria-hidden":"true"}},[_c('use',_vm._b({},'use',{
                  'xlink:href': '/images/fa-icons.svg#' + item.icon,
                },false))])]):_vm._e()]})],2)],1):_c('div',{staticClass:"tw-px-4 tw-py-6 md:tw-pt-8 lg:tw-pt-10 xl:tw-pt-14 2xl:tw-pt-16"},[_c(_setup.UserProfileV2,{attrs:{"side-nav-closed":_setup.sideNavClosed}}),_vm._v(" "),(_setup.resources)?_c('nav',{attrs:{"aria-label":"Your bookmarks, history and more"}},[_c('ul',[_vm._l((_setup.resources),function(item){return [(!item?.hideOnSmall)?_c(_setup.IconLink,{key:item.slug,attrs:{"item":item,"side-nav-closed":_setup.sideNavClosed}}):_vm._e()]})],2)]):_vm._e(),_vm._v(" "),_c(_setup.UserFolders,{attrs:{"instance-key":'small'}}),_vm._v(" "),_c('hr',{staticClass:"tw-my-6 tw-border-b tw-border-neutral-200 lg:tw-my-7"}),_vm._v(" "),(_setup.extras)?_c('nav',{attrs:{"aria-label":"Account links"}},[_c('ul',[_vm._l((_setup.extras),function(item){return [(!item?.hideOnSmall)?_c(_setup.IconLink,{key:item.slug,attrs:{"item":item,"side-nav-closed":_setup.sideNavClosed}}):_vm._e()]})],2)]):_vm._e(),_vm._v(" "),_c(_setup.UserTimeSaved)],1)]),_vm._v(" "),(!_setup.sideNavClosed)?_c('button',{staticClass:"overlay show !tw-w-100vw !tw-h-100vh tw-relative !tw-z-[5]",on:{"click":_setup.toggleSideNav}},[_c('svg',{staticClass:"tw-fixed tw-right-6 tw-top-32 tw-h-10 tw-w-10 tw-text-white sm:tw-top-16",attrs:{"role":"img"}},[_c('use',{attrs:{"xlink:href":"/images/fa-icons.svg#cross"}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }