var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex"},[_c('svg',{class:['tw-h-6 tw-w-6', { 'tw-mr-3': !_vm.sideNavClosed }],attrs:{"role":"img","aria-hidden":"true"}},[_c('use',_vm._b({},'use',{
        'xlink:href': '/images/fa-icons.svg#' + _vm.item.icon,
      },false))]),_vm._v(" "),_c('div',{class:[
      'tw-text-base tw-font-normal tw-leading-normal',
      { 'tw-inline-block': !_vm.sideNavClosed },
      { 'tw-hidden md:tw-inline-block': _vm.sideNavClosed },
    ]},[_vm._v("\n    "+_vm._s(_vm.item.name)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }