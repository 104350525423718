/**
 * Gets the root level route path from the full pathname.
 * Examples:
 * /au/teaching-resources      = teaching-resources
 * /au/teaching-resource/:slug = teaching-resource
 * /teaching-resource          = teaching-resource
 * /                           = <empty string>
 * /au                         = <empty string>
 *
 * @param {string} path
 */
export function getRouteRootLevel(path = '/'): string {
  return path.match(/\/(au|gb|us)\/?/) ? path.split('/')[2] || '' : path.split('/')[1] || '';
}

/**
 *
 * if path does not contain a trailing slash and does not include a . indicating a file extension, add one.
 * Handles query params.
 *
 * Exmaples:
 *  /teaching-resources = /teaching-resources/
 *  /teaching-resources/download.png = /teaching-resources/download.png
 *  /teaching-resources?param=1 = /teaching-resources/?param=1
 * / teaching-resources/?param=1 = /teaching-resources/?param=1
 *
 * @param path path to append
 * @returns path with trailing /
 */
export function appendTrailingSlash(path: string): string {
  if (path.endsWith('/') || path.includes('.') || path.includes('/?')) {
    return path;
  } else if (path.includes('?')) {
    return path.replace('?', '/?');
  } else {
    return path + '/';
  }
}

export const routesWithResources = new Set([
  'teaching-resource',
  'teaching-resources',
  'unit-plan',
  'unit-plans',
  'teaching-resource-pack',
  'teaching-resource-packs',
  'teaching-resource-collection',
  'teaching-resource-collections',
  'learning-area',
  'teacher-organisation',
  'classroom-management',
  'file-format',
  'resource-availability',
  'resource-type',
  'curriculums',
  'grade-level',
  'year-level',
]);

export const getFirstItemFromQueryParam = (query: string | (string | null)[]): string =>
  Array.isArray(query) ? query[0] || '' : query;
