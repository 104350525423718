<template>
  <div>
    <two-row-header />
    <main id="main" role="main" :aria-hidden="ariaHiddenMain">
      <nuxt :keep-alive-props="{ max: 10, exclude: ['ResourceList', 'CommentList'] }" />
    </main>
    <main-footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TwoRowHeader from '~/components/header/TwoRowHeader.vue';
import MainFooter from '~/components/MainFooter.vue';
import { getLocaleString } from '~/utils/localeString';
import { website } from '~/utils/schema-org';
import { ExperimentTypes } from '~/types/experiments';

export default {
  name: 'DefaultLayout',
  components: {
    MainFooter,
    TwoRowHeader,
  },
  data() {
    return {
      ExperimentTypes,
    };
  },
  head() {
    return {
      htmlAttrs: {
        lang: getLocaleString(this.$i18n?.locale),
      },
    };
  },
  computed: {
    ...mapGetters(['ariaHiddenMain']),
  },
  jsonld() {
    return website;
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/style.scss';
</style>
